<template>
  <base-card-modal-footer-wrapper class="justify-between">
    <div>
      <followers
        v-if="!showButtonEdit"
        :followers="passedValue.followers"
        :disabled="inputDisabled"
        data-id-prefix="absence_form_modal_footer"
        @addFollower="addFollower($event)"
        @removeFollower="removeFollower($event)"
      />
    </div>
    <absence-form-footer-content
      data-id-prefix="absence_form_modal_footer_actions"
      :button-submit-text="buttonSubmitText"
      :hide-button-back="hideButtonBack"
      :disabled="submitDisabled"
      :more-options="moreOptions"
      :loading="loading"
      :show-button-edit="showButtonEdit"
      @done="$emit('done')"
      @selected="$emit('selected', $event)"
      @back="$emit('back')"
      @edit="$emit('edit')"
    />
  </base-card-modal-footer-wrapper>
</template>

<script>
import BaseCardModalFooterWrapper from '@/app/util-modules/ui/card-modal/BaseCardModalFooterWrapper.vue'
import Followers from '@/app/util-modules/ui/followers/Followers'
import AbsenceFormFooterContent from './AbsenceFormFooterContent.vue'

export default {
  name: 'AbsenceFormModalFooter',
  components: {
    BaseCardModalFooterWrapper,
    Followers,
    AbsenceFormFooterContent,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    buttonSubmitText: {
      type: String,
      required: true,
    },
    hideButtonBack: {
      type: Boolean,
      required: true,
    },
    inputDisabled: {
      type: Boolean,
      required: true,
    },
    submitDisabled: {
      type: Boolean,
      required: true,
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showButtonEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
  },
  methods: {
    addFollower(user) {
      const userExist = this.passedValue.followers.some((f) => f.id === user.id)
      if (!userExist) {
        this.passedValue.followers.push(user)
      }
    },
    removeFollower(followerToDelete) {
      const followers = this.passedValue.followers.filter(
        (follower) => follower.id !== followerToDelete.id
      )
      this.passedValue.followers = followers
    },
  },
}
</script>
