import i18n from '@/utils/vendors/i18n/index'

const getActionOptions = (emit: (event: string) => void) => {
  return [
    {
      id: 'add',
      label: () => i18n.t('absence.index.bottom_sheet.add_absence'),
      icon: 'plus/plus-16',
      clickEvent: () => emit('addAbsence'),
    },
  ]
}

export default function useOptions(emit: (event: string) => void) {
  const actionOptions = getActionOptions(emit)
  return {
    actionOptions,
  }
}
