import { MODULE_NAME } from '../../absence.module'

export const ABSENCE_ACTION_LIST_APPEND_ABSENCES: string = `${MODULE_NAME}/ACTION_LIST_APPEND_ABSENCES`
export const ABSENCE_ACTION_LIST_ABSENCES: string = `${MODULE_NAME}/ACTION_LIST_ABSENCE`
export const ABSENCE_ACTION_LIST_ALL_ABSENCES: string = `${MODULE_NAME}/ACTION_LIST_ALL_ABSENCES`
export const ABSENCE_ACTION_CREATE_ABSENCE: string = `${MODULE_NAME}/ACTION_CREATE_ABSENCE`
export const ABSENCE_ACTION_CREATE_BATCH_ABSENCE: string = `${MODULE_NAME}/ABSENCE_ACTION_CREATE_BATCH_ABSENCE`
export const ABSENCE_ACTION_SELECT_COLUMNS_TO_SHOW: string = `${MODULE_NAME}/ACTION_SELECT_COLUMNS_TO_SHOW`
export const ABSENCE_ACTION_LIST_VACATION_SUMMARY: string = `${MODULE_NAME}/ACTION_LIST_VACATION_SUMMARY`
export const ABSENCE_ACTION_APPROVE_ABSENCE: string = `${MODULE_NAME}/ACTION_APPROVE_ABSENCE`
export const ABSENCE_ACTION_REJECT_ABSENCE: string = `${MODULE_NAME}/ACTION_REJECT_ABSENCE`
export const ABSENCE_ACTION_RESET_ABSENCE: string = `${MODULE_NAME}/ACTION_RESET_ABSENCE`
export const ABSENCE_ACTION_CLEAR_ABSENCES: string = `${MODULE_NAME}/ACTION_CLEAR_ABSENCE`
export const ABSENCE_ACTION_UPDATE_META: string = `${MODULE_NAME}/ACTION_UPDATE_META`
export const ABSENCE_ACTION_UPDATE_META_ACTION_STATUS = `${MODULE_NAME}/ACTION_UPDATE_META_ACTION_STATUS`
export const ABSENCE_ACTION_DELETE_ABSENCE: string = `${MODULE_NAME}/ACTION_DELETE_ABSENCE`
export const ABSENCE_ACTION_UPDATE_ABSENCE = `${MODULE_NAME}/ACTION_UPDATE_ABSENCE`
export const ABSENCE_ACTION_UPDATE_ABSENCE_FOLLOWERS = `${MODULE_NAME}/ACTION_UPDATE_ABSENCE_FOLLOWERS`
export const ABSENCE_ACTION_ADD_ABSENCE_NOTE = `${MODULE_NAME}/ACTION_ADD_ABSENCE_NOTE`
export const ABSENCE_ACTION_UPDATE_ABSENCE_NOTE = `${MODULE_NAME}/ACTION_UPDATE_ABSENCE_NOTE`
export const ABSENCE_ACTION_DELETE_ABSENCE_NOTE = `${MODULE_NAME}/ACTION_DELETE_ABSENCE_NOTE`
export const ABSENCE_ACTION_DELETE_ABSENCE_FOLLOWER = `${MODULE_NAME}/ACTION_DELETE_ABSENCE_FOLLOWER`
export const ABSENCE_NOTE_ACTION_LIST_NOTE = `${MODULE_NAME}/ACTION_LIST_NOTE`
export const ABSENCE_ACTION_GET_ABSENCE = `${MODULE_NAME}/ACTION_GET_ABSENCE`
export const ABSENCE_NOTE_ACTION_LIST_APPEND_NOTE = `${MODULE_NAME}/ACTION_LIST_APPEND_NOTE`
export const ABSENCE_ACTION_ATTACHMENT_LIST = `${MODULE_NAME}/ACTION_ATTACHMENT_LIST`
export const ABSENCE_ACTION_CALCULATE_ABSENCE = `${MODULE_NAME}/ACTION_CALCULATE_ABSENCE`
export const ABSENCE_ACTION_LIST_USERS_ABSENCE_TYPES = `${MODULE_NAME}/ACTION_LIST_USERS_ABSENCE_TYPES`
export const ABSENCE_ACTION_LIST_ABSENCE_TYPES = `${MODULE_NAME}/ACTION_LIST_ABSENCE_TYPES`
export const ABSENCE_ACTION_LIST_CALENDAR_ABSENCES = `${MODULE_NAME}/ACTION_LIST_CALENDAR_ABSENCES`
export const ABSENCE_ACTION_LIST_CREATION_USERS = `${MODULE_NAME}/ACTION_LIST_CREATION_USERS`
export const ABSENCE_ACTION_CHECK_ABSENCE_OVERLAP = `${MODULE_NAME}/ACTION_CHECK_ABSENCE_OVERLAP`
