<template>
  <base-date-range-picker
    :default-value="dateRange"
    position="is-bottom-left"
    :get-shortcuts-method="absenceShortcuts"
    data-id-prefix="absence.index.date_picker"
    class="cursor-pointer"
    @input="updateDateRange($event)"
  />
</template>

<script>
import { getAbsenceIndexShortcuts } from '@/utils/date-time.utils'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { MODULE_NAME } from '../../absence.module'
import { ABSENCE_MUTATION_SET_DATE_RANGE } from '@/app/modules/absence/store/mutations/absence.mutations.names'
import BaseDateRangePicker from '@/app/util-modules/ui/date-range-picker/BaseDateRangePicker'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
export default {
  name: 'AbsenceIndexDateRangePicker',
  components: {
    BaseDateRangePicker,
  },
  computed: {
    ...mapState(MODULE_NAME, {
      dateRange: (state) => state.absenceFilterDateRange,
    }),
    ...mapGetters({
      isAssumedRoleAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
  },
  methods: {
    ...mapMutations({
      updateDateRange: ABSENCE_MUTATION_SET_DATE_RANGE,
    }),
    absenceShortcuts(date) {
      return getAbsenceIndexShortcuts(date, this.isAssumedRoleAdmin)
    },
    getAbsenceIndexShortcuts,
  },
}
</script>
