import { BaseRequestPagination } from '@/utils/api/base-request'
import { BaseResponseMeta } from '@/utils/api/base-response'
import { shortname } from '@/utils/store'
import { AxiosResponse } from 'axios'
import { type ActionContext } from 'vuex'
import {
  ABSENCE_TYPE,
  AbsenceUpdateFormData,
  AbsenceCreateFormData,
  AbsenceRaw,
  AbsenceCalculatorParams,
  ListAbsencesParams,
  AbsenceCalendarDateRange,
  AbsenceCheckOverlapParams,
} from '../../models/absence.model'

import {
  ABSENCE_STATUS,
  ABSENCE_ACTIONS,
  ABSENCE_LIST_PREFERENCE_KEY,
} from '../../constants'
import { AbsenceState } from '../absence.state'
import * as actionNamesObj from './absence.actions.names'
import * as mutationNamesObj from '../mutations/absence.mutations.names'
import * as getterNamesObj from '../getters/absence.getters.names'
import api from '../../network/absence.api'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import i18n from '@/utils/vendors/i18n'
import { extractRelationships, flattenRecord } from '@/utils/jsonapi/utils'
import {
  USER_PUBLIC_ACTION_UPDATE_PREFERENCES,
  USER_PUBLIC_ACTION_LIST_PREFERENCES,
} from '@/app/core/user/store/actions/user.actions.names'
import { USER_PUBLIC_GETTER_PREFERENCE } from '@/app/core/user/store/getters/user.getters.names'
import { getUniqueElements } from '@/app/modules/absence/store/actions/absence.action.utils'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)
const getterNames = shortname(getterNamesObj)

export type ListResponse = AxiosResponse<{
  data: AbsenceRaw[]
  meta: BaseResponseMeta
  included: []
}>

const fetchAbsences = async (
  params: ListAbsencesParams
): Promise<{ included: []; data: AbsenceRaw[]; meta: BaseResponseMeta }> => {
  const { data: response }: ListResponse = await api.listAbsences(params)

  return {
    included: response.included,
    data: response.data,
    meta: response.meta,
  }
}

export default {
  [actionNames.ABSENCE_ACTION_LIST_ABSENCES]: async ({
    rootState,
    state,
    commit,
    getters,
  }: ActionContext<AbsenceState, any>): Promise<void> => {
    const pagination: BaseRequestPagination = {
      ...state.pagination,
      page: 1,
    }

    const { data: response }: ListResponse = await api.listAbsences({
      workspaceId: rootState.workspace.workspace.id,
      sorting: getters[getterNames.ABSENCE_GETTER_ACTIVE_SORTING],
      filter: getters[getterNames.ABSENCE_GETTER_ACTIVE_FILTERS],
      pagination: pagination,
    })

    commit(mutationNames.ABSENCE_MUTATION_SET_META, response.meta)

    const { data, included } = response
    if (data.length) {
      commit(mutationNames.ABSENCE_MUTATION_ADD_INCLUDED, {
        records: included,
      })

      commit(mutationNames.ABSENCE_MUTATION_SET_RECORDS, {
        type: ABSENCE_TYPE,
        records: data,
      })
      commit(mutationNames.ABSENCE_MUTATION_INCREMENT_PAGE)
    }
  },

  [actionNames.ABSENCE_ACTION_LIST_APPEND_ABSENCES]: async ({
    rootState,
    state,
    commit,
    getters,
  }: ActionContext<AbsenceState, any>): Promise<void> => {
    const pagination: BaseRequestPagination = {
      ...state.pagination,
      page: state.pagination.page + 1,
    }

    if (
      state.meta.total_pages > 0 &&
      pagination.page > state.meta.total_pages
    ) {
      return
    }

    const { data: response }: ListResponse = await api.listAbsences({
      workspaceId: rootState.workspace.workspace.id,
      sorting: getters[getterNames.ABSENCE_GETTER_ACTIVE_SORTING],
      filter: getters[getterNames.ABSENCE_GETTER_ACTIVE_FILTERS],
      pagination,
    })

    commit(mutationNames.ABSENCE_MUTATION_SET_META, response.meta)

    const { included, data } = response
    if (data.length) {
      const newData = getUniqueElements(state.records.absence, data)

      commit(mutationNames.ABSENCE_MUTATION_ADD_INCLUDED, {
        records: included,
      })

      commit(mutationNames.ABSENCE_MUTATION_APPEND_RECORDS, {
        type: ABSENCE_TYPE,
        records: newData,
      })
      commit(mutationNames.ABSENCE_MUTATION_INCREMENT_PAGE)
    }
  },

  [actionNames.ABSENCE_ACTION_LIST_ALL_ABSENCES]: async ({
    rootState,
    state,
    commit,
    getters,
  }: ActionContext<AbsenceState, any>): Promise<void> => {
    const pagination: BaseRequestPagination = {
      ...state.pagination,
      page: 1,
    }

    const params: ListAbsencesParams = {
      workspaceId: rootState.workspace.workspace.id,
      sorting: getters[getterNames.ABSENCE_GETTER_ACTIVE_SORTING],
      filter: getters[getterNames.ABSENCE_GETTER_ACTIVE_FILTERS],
      pagination: pagination,
    }

    const { included, data, meta } = await fetchAbsences(params)
    commit(mutationNames.ABSENCE_MUTATION_SET_META, meta)

    if (data.length) {
      const newData = getUniqueElements(state.records.absence, data)

      commit(mutationNames.ABSENCE_MUTATION_ADD_INCLUDED, {
        records: included,
      })

      commit(mutationNames.ABSENCE_MUTATION_APPEND_RECORDS, {
        type: ABSENCE_TYPE,
        records: newData,
      })
      commit(mutationNames.ABSENCE_MUTATION_INCREMENT_PAGE)
    }

    if (meta.total_pages <= 1) return

    for (let page = pagination.page + 1; page <= meta.total_pages; page++) {
      const pageParams: ListAbsencesParams = {
        ...params,
        pagination: { ...pagination, page },
      }

      const { included: pageIncluded, data: pageData } = await fetchAbsences(
        pageParams
      )

      commit(mutationNames.ABSENCE_MUTATION_ADD_INCLUDED, {
        records: pageIncluded,
      })

      commit(mutationNames.ABSENCE_MUTATION_APPEND_RECORDS, {
        type: ABSENCE_TYPE,
        records: pageData,
      })
      commit(mutationNames.ABSENCE_MUTATION_INCREMENT_PAGE)
    }
  },

  [actionNames.ABSENCE_ACTION_CREATE_BATCH_ABSENCE]: async (
    { rootState, dispatch }: ActionContext<AbsenceState, any>,
    absenceParams: AbsenceCreateFormData
  ) => {
    const workspaceId = rootState.workspace.workspace.id
    const { data } = await api.createAbsenceBatch(workspaceId, absenceParams)
    dispatch(actionNames.ABSENCE_ACTION_LIST_ABSENCES)

    return data
  },

  [actionNames.ABSENCE_ACTION_CREATE_ABSENCE]: async (
    { rootState, dispatch }: ActionContext<AbsenceState, any>,
    absenceParams: AbsenceCreateFormData
  ) => {
    const workspaceId = rootState.workspace.workspace.id
    const { data } = await api.createAbsence(workspaceId, absenceParams)
    dispatch(actionNames.ABSENCE_ACTION_LIST_ABSENCES)

    return data
  },

  [actionNames.ABSENCE_ACTION_SELECT_COLUMNS_TO_SHOW]: async (
    { dispatch, rootGetters }: ActionContext<AbsenceState, any>,
    columns: {
      [k: string]: boolean
    }
  ) => {
    const userAbsencePreference = rootGetters[USER_PUBLIC_GETTER_PREFERENCE](
      ABSENCE_LIST_PREFERENCE_KEY
    )

    if (!userAbsencePreference) return

    const newColumns = { ...userAbsencePreference.value, ...columns }

    await dispatch(
      USER_PUBLIC_ACTION_UPDATE_PREFERENCES,
      { preferenceId: userAbsencePreference.id, value: newColumns },
      {
        root: true,
      }
    )

    await dispatch(
      USER_PUBLIC_ACTION_LIST_PREFERENCES,
      {},
      {
        root: true,
      }
    )
  },

  [actionNames.ABSENCE_ACTION_LIST_VACATION_SUMMARY]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    userId: string
  ) => {
    const { data: response } = await api.listVacationSummary({
      workspaceId: rootState.workspace.workspace.id,
      userId: userId,
    })
    commit(mutationNames.ABSENCE_MUTATION_SET_VACATION_SUMMARY, response.data)
  },

  [actionNames.ABSENCE_ACTION_APPROVE_ABSENCE]: async (
    { rootState, commit, state, dispatch }: ActionContext<AbsenceState, any>,
    { absenceId }: { absenceId: RecordId }
  ) => {
    const { data: response } = await api.approveAbsence({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
    })

    dispatch(actionNames.ABSENCE_ACTION_UPDATE_META)

    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement:
        state.currentTab === ABSENCE_STATUS.PENDING
          ? {
              ...response.data,
              attributes: {
                ...response.data.attributes,
                confirmed: true,
                _hideRecordFromTable: true,
              },
            }
          : response.data,
    })

    dispatch(actionNames.ABSENCE_ACTION_UPDATE_META_ACTION_STATUS, {
      statusAction: ABSENCE_ACTIONS.APPROVE,
    })

    const confirmMessage = response.data.attributes.confirm_message

    Snackbar.open({
      type: confirmMessage ? 'is-danger' : 'is-success',
      message:
        confirmMessage ??
        i18n.t('absence.index.actions.approve_success_message'),
      renderHtml: !!confirmMessage,
    })
  },

  [actionNames.ABSENCE_ACTION_REJECT_ABSENCE]: async (
    { rootState, state, commit, dispatch }: ActionContext<AbsenceState, any>,
    { absenceId }: { absenceId: RecordId }
  ) => {
    const { data: response } = await api.rejectAbsence({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
    })

    dispatch(actionNames.ABSENCE_ACTION_UPDATE_META)

    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: {
        ...response.data,
        attributes: {
          ...response.data.attributes,
          confirmed: false,
          confirmation_status: ABSENCE_STATUS.REJECTED, // TODO: remove this line when API returns updated value https://app.asana.com/0/0/1205100871047041/1205133969786072/f
          _hideRecordFromTable:
            state.currentTab === ABSENCE_STATUS.PENDING ? true : false,
        },
      },
    })

    dispatch(actionNames.ABSENCE_ACTION_UPDATE_META_ACTION_STATUS, {
      statusAction: ABSENCE_ACTIONS.REJECT,
    })

    Snackbar.open({
      type: 'is-success',
      message: i18n.t('absence.index.actions.reject_success_message'),
    })
  },

  [actionNames.ABSENCE_ACTION_RESET_ABSENCE]: async (
    { rootState, state, commit, dispatch }: ActionContext<AbsenceState, any>,
    { absenceId }: { absenceId: RecordId }
  ) => {
    const { data: response } = await api.resetAbsence({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
    })

    dispatch(actionNames.ABSENCE_ACTION_UPDATE_META)

    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: {
        ...response.data,
        attributes: {
          ...response.data.attributes,
          confirmed: null,
          _hideRecordFromTable:
            state.currentTab === ABSENCE_STATUS.ALL ? false : true,
        },
      },
    })

    dispatch(actionNames.ABSENCE_ACTION_UPDATE_META_ACTION_STATUS, {
      statusAction: ABSENCE_ACTIONS.RESET,
      confirmationStatus: response.data.attributes.confirmation_status,
    })

    const confirmMessage = response.data.attributes.confirm_message

    if (confirmMessage) {
      Snackbar.open({
        type: 'is-danger',
        message: confirmMessage,
      })
    }
    Snackbar.open({
      type: 'is-success',
      message: i18n.t('absence.index.actions.reset_success_message'),
    })
  },

  [actionNames.ABSENCE_ACTION_CLEAR_ABSENCES]: ({
    commit,
  }: ActionContext<AbsenceState, any>) => {
    commit(mutationNames.ABSENCE_MUTATION_CLEAR_RECORDS, {
      type: ABSENCE_TYPE,
    })
    commit(mutationNames.ABSENCE_MUTATION_RESET_PAGE)
  },

  [actionNames.ABSENCE_ACTION_DELETE_ABSENCE]: async (
    { commit, dispatch, rootState }: ActionContext<AbsenceState, any>,
    absenceId: RecordId
  ) => {
    await api.deleteAbsence({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
    })
    dispatch(actionNames.ABSENCE_ACTION_UPDATE_META)

    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: { id: absenceId },
      replacement: null,
    })
  },

  [actionNames.ABSENCE_ACTION_UPDATE_ABSENCE]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    {
      absenceId,
      absenceParams,
    }: {
      absenceId: RecordId
      absenceParams: AbsenceUpdateFormData
    }
  ) => {
    const { data: response } = await api.updateAbsence({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
      absenceParams,
    })
    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
    return response
  },

  [actionNames.ABSENCE_ACTION_UPDATE_ABSENCE_FOLLOWERS]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    {
      ids,
      absenceId,
    }: {
      ids: RecordId[]
      absenceId: RecordId
    }
  ) => {
    const { data: response } = await api.updateAbsenceFollowers({
      workspaceId: rootState.workspace.workspace.id,
      ids,
      absenceId,
    })
    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
    return response
  },

  [actionNames.ABSENCE_ACTION_DELETE_ABSENCE_FOLLOWER]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    {
      follower_id,
      absenceId,
    }: {
      follower_id: RecordId
      absenceId: RecordId
    }
  ) => {
    const { data: response } = await api.deleteAbsenceFollower({
      workspaceId: rootState.workspace.workspace.id,
      follower_id,
      absenceId,
    })
    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
    return response
  },

  [actionNames.ABSENCE_ACTION_GET_ABSENCE]: async (
    { rootState, commit, dispatch }: ActionContext<AbsenceState, any>,
    { absenceId }: { absenceId: RecordId }
  ) => {
    const { data: response } = await api.showAbsence({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
    })

    const relationship = extractRelationships([response.data], ['user'])
    const [userId] = relationship.get('user') || []
    dispatch(actionNames.ABSENCE_ACTION_LIST_USERS_ABSENCE_TYPES, [userId])

    commit(mutationNames.ABSENCE_MUTATION_ADD_INCLUDED, {
      records: response.included,
    })

    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: absenceId,
      },
      replacement: response.data,
    })
  },

  [actionNames.ABSENCE_ACTION_CALCULATE_ABSENCE]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    calculatorParams: AbsenceCalculatorParams
  ) => {
    const { data: response } = await api.calculateAbsence({
      workspaceId: rootState.workspace.workspace.id,
      calculatorParams,
    })
    commit(mutationNames.ABSENCE_MUTATION_SET_CALCULATION, response)
    return response
  },

  [actionNames.ABSENCE_ACTION_LIST_ABSENCE_TYPES]: async ({
    rootState,
    commit,
  }: ActionContext<AbsenceState, any>) => {
    const { data: response } = await api.listUsersAbsenceTypes({
      workspaceId: rootState.workspace.workspace.id,
      userIds: [],
      pagination: { page: 1, items: 30 },
    })

    commit(mutationNames.ABSENCE_MUTATION_SET_RECORDS, {
      type: 'absence_type',
      records: response.data || [],
    })
  },
  [actionNames.ABSENCE_ACTION_LIST_USERS_ABSENCE_TYPES]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    userIds: RecordId[]
  ) => {
    const { data: response } = await api.listUsersAbsenceTypes({
      workspaceId: rootState.workspace.workspace.id,
      userIds: userIds,
      pagination: { page: 1, items: 30 },
    })

    commit(
      mutationNames.ABSENCE_MUTATION_SET_ABSENCE_TYPES,
      response.data || []
    )

    if (response.data) return response.data.map(flattenRecord)
  },

  [actionNames.ABSENCE_ACTION_LIST_CALENDAR_ABSENCES]: async (
    { rootState, commit, state }: ActionContext<AbsenceState, any>,
    dateRange: AbsenceCalendarDateRange
  ) => {
    const { data: response }: ListResponse = await api.listCalendarAbsences({
      workspaceId: rootState.workspace.workspace.id,
      dateRange,
    })

    const { included, data } = response

    if (included) {
      commit(mutationNames.ABSENCE_MUTATION_ADD_INCLUDED, {
        records: included,
      })
    }
    if (data?.length) {
      const newData = getUniqueElements(state.records.absence, data)

      commit(mutationNames.ABSENCE_MUTATION_APPEND_RECORDS, {
        type: ABSENCE_TYPE,
        records: newData,
      })
    }
    return response
  },

  [actionNames.ABSENCE_ACTION_CHECK_ABSENCE_OVERLAP]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    payload: AbsenceCheckOverlapParams
  ) => {
    const { data: response } = await api.absenceCheckAbsenceOverlap({
      workspaceId: rootState.workspace.workspace.id,
      payload,
    })

    commit(mutationNames.ABSENCE_MUTATION_SET_ABSENCE_OVERLAP, response.data)
    return response.data
  },
}
