import i18n from '@/utils/vendors/i18n'

export const TABLE_COLUMNS = [
  {
    unique_key: 'employee',
    label: () =>
      i18n.t('absence.index.table_columns_preferences.employee_name'),
  },
  {
    unique_key: 'type',
    label: () => i18n.t('absence.index.table_columns_preferences.absence_type'),
  },
  {
    unique_key: 'dates',
    label: () => i18n.t('absence.index.table_columns_preferences.dates'),
  },
  {
    unique_key: 'time',
    label: () => i18n.t('absence.index.table_columns_preferences.time'),
  },
  {
    unique_key: 'days',
    label: () => i18n.t('absence.index.table_columns_preferences.absence_days'),
  },
  {
    unique_key: 'hours',
    label: () =>
      i18n.t('absence.index.table_columns_preferences.absence_hours'),
  },
  // {
  //   unique_key: 'conflicts',
  //   label: () => i18n.t('absence.index.table_columns_preferences.conflicts'),
  // },
  {
    unique_key: 'confirmed_by',
    label: () => i18n.t('absence.index.table_columns_preferences.confirm_by'),
  },
  {
    unique_key: 'info',
    label: () => i18n.t('absence.index.table_columns_preferences.info'),
  },
  {
    unique_key: 'confirmation',
    label: () => i18n.t('absence.index.table_columns_preferences.confirmation'),
  },
]

export const TABLE_COLUMNS_IDS = TABLE_COLUMNS.map(
  (column) => column.unique_key
)

export const ABSENCE_STATUS = {
  ALL: 'all',
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected',
}

export const ABSENCE_ACTIONS = {
  APPROVE: 'approve',
  REJECT: 'reject',
  RESET: 'reset',
  EDIT: 'edit',
}

export const ABSENCE_KIND = {
  VACATION: 'vacation',
}
export const CONFIRMATION_STATUS = 'confirmation_status'

export const MAX_NOTE_LENGTH = 400

export const ABSENCE_LIST_PREFERENCE_KEY = 'absence_list'
