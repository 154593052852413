














import { defineComponent } from '@vue/composition-api'
import AbsenceHeaderMobile from './AbsenceHeaderMobile.vue'
import AbsenceHeaderDesktop from './AbsenceHeaderDesktop.vue'

export default defineComponent({
  name: 'AbsenceHeader',
  components: {
    AbsenceHeaderMobile,
    AbsenceHeaderDesktop,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
})
