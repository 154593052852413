<template>
  <base-table-row-cell-icon-with-tooltip
    icon-name="user/user-16"
    data-id="absence.index.body.recorded_by_icon"
    @hover="$emit('hover')"
  >
    <template v-slot:tooltip-content>
      <span>{{ createdBy }}</span>
    </template>
  </base-table-row-cell-icon-with-tooltip>
</template>

<script>
import BaseTableRowCellIconWithTooltip from '@/app/util-modules/ui/table-row-cell-icon/BaseTableRowCellIconWithTooltip.vue'

export default {
  name: 'AbsenceTableCellRecordedBy',
  components: {
    BaseTableRowCellIconWithTooltip,
  },
  props: {
    createdByUser: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      labelText: '',
    }
  },
  computed: {
    createdBy() {
      return `${this.$t('absence.index.body.created_by.tooltip_label')}: ${
        this.createdByUser ||
        this.$t('absence.index.body.created_by.tooltip_creator_admin')
      }`
    },
  },
}
</script>
