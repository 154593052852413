























import {
  defineComponent,
  computed,
  ComputedRef,
  PropType,
} from '@vue/composition-api'
import { AddAbsencePayload } from '../../models/absence.model'

import AbsenceFormMain from '../absence-form/AbsenceFormMain.vue'
import AbsenceEditFormNotes from '../absence-edit-form-notes/AbsenceEditFormNotes.vue'

export default defineComponent({
  name: 'AbsenceEditForm',
  components: {
    AbsenceFormMain,
    AbsenceEditFormNotes,
  },
  props: {
    value: {
      type: Object as PropType<AddAbsencePayload>,
      required: true,
    },
    canEditAbsence: {
      type: Boolean,
      default: false,
    },
    notes: {
      type: Array,
      default: () => [],
    },
    areNotesFocused: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const passedValue: ComputedRef<AddAbsencePayload> = computed({
      get(): AddAbsencePayload {
        return props.value
      },
      set(passedValue) {
        emit('input', passedValue)
      },
    })

    return {
      passedValue,
    }
  },
})
