















































import {
  defineComponent,
  computed,
  watch,
  ComputedRef,
  PropType,
} from '@vue/composition-api'
import store from '@/store'

import { AddAbsencePayload } from '../../models/absence.model'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'

import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

import BaseToggle from '@/app/util-modules/ui/toggle/BaseToggle.vue'
import AbsenceFormManualNumberInput from './AbsenceFormManualNumberInput.vue'
import AbsenceInfoDisplayMultipleEmployees from '@/app/modules/absence/components/Absence-info-display/AbsenceInfoDisplayMultipleEmployees.vue'

export default defineComponent({
  name: 'AbsenceFormInputManual',
  components: {
    BaseToggle,
    AbsenceFormManualNumberInput,
    AbsenceInfoDisplayMultipleEmployees,
  },
  props: {
    value: {
      type: Object as PropType<AddAbsencePayload>,
      required: true,
    },
  },

  setup(_props, { emit }) {
    const isAssumedRoleAdmin = computed(
      () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )

    const passedValue: ComputedRef<AddAbsencePayload> = computed({
      get(): AddAbsencePayload {
        return _props.value
      },
      set(passedValue) {
        emit('input', passedValue)
      },
    })

    const totalDayHourManualValue = () => {
      const { manualDays, manualHours } = passedValue.value
      if (manualDays || manualHours) {
        return {
          days: +passedValue.value.manualDays,
          hours: +passedValue.value.manualHours,
        }
      }
      return { days: 0, hours: 0 }
    }

    const calculation = computed(
      () => store.state[ABSENCE_MODULE_NAME].calculation
    )

    const totalDayHour = computed(() =>
      passedValue.value.manualValue
        ? totalDayHourManualValue()
        : calculation.value
    )

    const isEmployeesMultipleNotification = computed(
      () =>
        !passedValue.value.manualValue && passedValue.value.employees.length > 1
    )

    const hourHolidays = computed(() =>
      passedValue.value.employees.length
        ? passedValue.value.employees[0].hourHolidays
        : false
    )

    watch(
      () => totalDayHour.value,
      () => {
        passedValue.value.total = totalDayHour.value
        passedValue.value.manualDays = +totalDayHour.value.days
        passedValue.value.manualHours = +totalDayHour.value.hours
      }
    )

    return {
      passedValue,
      totalDayHourManualValue,
      hourHolidays,
      totalDayHour,
      isEmployeesMultipleNotification,
      isAssumedRoleAdmin,
    }
  },
})
