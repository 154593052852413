<template>
  <div class="flex justify-end items-center">
    <base-attachment-item-tooltip
      v-if="absence.attachments_count"
      data-id-prefix="absence.index.body"
      :action-status="$actions.fetchAttachments"
      :attachments="absence.attachments"
      attachment-item-size="is-small"
      @hover="$emit('attachments-hover')"
      @more-attachments-click="$emit('attachments-action-click')"
    />

    <note-item-tooltip
      v-if="absence.user_notes_count"
      :user-notes-count="absence.user_notes_count"
      :action-status="$actions.fetchNotes"
      :notes="userNotes"
      :displayed-notes-count="3"
      data-id-prefix="absences.index.body"
      @hover="$emit('notes-hover')"
      @more-notes-click="$emit('notes-action-click')"
    />

    <absence-table-cell-recorded-by :created-by-user="createdByUser" />
  </div>
</template>

<script>
import {
  ABSENCE_ACTION_ATTACHMENT_LIST,
  ABSENCE_NOTE_ACTION_LIST_NOTE,
} from '../../store/actions/absence.actions.names'
const BaseAttachmentItemTooltip = () =>
  import(
    '@/app/util-modules/ui/attachment-item-tooltip/BaseAttachmentItemTooltip'
  )
const NoteItemTooltip = () =>
  import('@/app/util-modules/ui/note-item-tooltip/NoteItemTooltip.vue')
const AbsenceTableCellRecordedBy = () =>
  import('./absence-table-cell-recorded-by/AbsenceTableCellRecordedBy')
import { NOTE_TYPES } from '@/constants'

export default {
  name: 'AbsenceCellInfo',
  components: {
    BaseAttachmentItemTooltip,
    NoteItemTooltip,
    AbsenceTableCellRecordedBy,
  },
  props: {
    absence: {
      type: Object,
      default: () => ({}),
    },
  },
  trackedActions: {
    fetchAttachments: ABSENCE_ACTION_ATTACHMENT_LIST,
    fetchNotes: ABSENCE_NOTE_ACTION_LIST_NOTE,
  },
  computed: {
    userNotes() {
      if (!this.absence.user_notes_count) return []

      return this.absence.notes.filter((note) => note.type === NOTE_TYPES.USER)
    },
    createdByUser() {
      return (
        (this.absence.created_by_user &&
          this.absence.created_by_user.username) ||
        ''
      )
    },
  },
}
</script>
