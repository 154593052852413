<template>
  <portal to="modal">
    <base-modal has-modal-card>
      <base-card-modal-form
        :title="modalTitle"
        :action-status="actionStatus"
        hide-scrollbar
        @close="handleCloseModal()"
      >
        <component
          :is="activeFormComponent"
          :calculate-absence-action-status="calculateAbsenceActionStatus"
          :value="passedPayload"
          :error-field="validationStates"
          :is-new-form="isNewForm"
          @input="$emit('input', $event)"
          @note-validation="$emit('note-validation', $event)"
        />
        <template
          v-slot:footer="{
            footerActionValidate,
            handleSubmit,
            submitDisabled,
            isValid,
          }"
        >
          <absence-form-modal-footer
            :value="passedPayload"
            :input-disabled="false"
            :submit-disabled="noteSubmitDisabled || submitDisabled"
            :button-submit-text="$t('absence.add_modal.button_submit')"
            :hide-button-back="true"
            :show-button-edit="showButtonEdit"
            :loading="actionStatus.isLoading"
            @input="$emit('input', $event)"
            @done="
              handleFormStep(footerActionValidate, {
                handleSubmit,
                isValid,
              })
            "
            @back="handleBackStep()"
            @edit="handleEditButton()"
          />
        </template>
      </base-card-modal-form>
    </base-modal>
    <absence-vacation-error-dialog
      v-if="isErrorDialogOpen"
      :dialog-message="dialogMessage"
      @close="isErrorDialogOpen = false"
      @done="isErrorDialogOpen = false"
    />
    <base-dialog
      v-if="isDiscardChangeDialogOpen"
      :title="$t('absence.add_modal.unsaved_changes_dialog.title')"
      :button-submit-text="
        $t(
          'absence.add_modal.unsaved_changes_dialog.button_submit_discard_changes'
        )
      "
      button-type="is-filled-red"
      @close="isDiscardChangeDialogOpen = false"
      @done="handleDiscardChanges()"
    >
      <p>{{
        $t('absence.add_modal.unsaved_changes_dialog.first_paragraph')
      }}</p>
      <p>{{
        $t('absence.add_modal.unsaved_changes_dialog.second_paragraph')
      }}</p>
    </base-dialog>
  </portal>
</template>

<script>
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import store from '@/store'
import { mapGetters } from 'vuex'

import AbsenceFormMain from './AbsenceFormMain.vue'
import AbsenceFormPreview from '../absence-form-preview/AbsenceFormPreview.vue'
import AbsenceOverlapDialogue from '../absence-overlap-dialogue/AbsenceOverlapDialogue.vue'
import AbsenceFormModalFooter from '../absence-form-modal-footer/AbsenceFormModalFooter'
import AbsenceVacationErrorDialog from '../absence-vacation-error-dialog/AbsenceVacationErrorDialog'
import {
  MAIN_ABSENCE_FORM,
  // CONFIRM_ABSENCE_FORM,
  OVERLAP_ABSENCE_FORM,
} from './absenceForm.utils'
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import { ABSENCE_ACTION_CALCULATE_ABSENCE } from '@/app/modules/absence/store/actions/absence.actions.names'
import { ABSENCE_MUTATION_SET_ABSENCE_OVERLAP } from '@/app/modules/absence/store/mutations/absence.mutations.names'
import { calculateAbsence } from '@/app/modules/absence/components/absence-form/absenceForm.utils'
import { MAX_NOTE_LENGTH } from '../../constants'

export default {
  name: 'AbsenceFormModal',
  components: {
    BaseDialog,
    BaseModal,
    BaseCardModalForm,
    AbsenceFormMain,
    AbsenceFormPreview,
    AbsenceFormModalFooter,
    AbsenceOverlapDialogue,
    AbsenceVacationErrorDialog,
  },
  trackedActions: {
    calculateAbsence: ABSENCE_ACTION_CALCULATE_ABSENCE,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitButtonText: {
      type: String,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    calculateAbsenceActionStatus: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      required: true,
    },
    validationStates: {
      type: Object,
      default: () => ({}),
    },
    isNewForm: {
      type: Boolean,
      default: false,
    },
    overlap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeForm: MAIN_ABSENCE_FORM,
      isErrorDialogOpen: false,
      isDiscardChangeDialogOpen: false,
      isNoteValid: true,
    }
  },
  computed: {
    ...mapGetters({
      isAssumedRoleAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    passedPayload: {
      get() {
        return this.payload
      },
      set(passedPayload) {
        this.$emit('input', passedPayload)
      },
    },

    showButtonEdit() {
      return this.activeForm === OVERLAP_ABSENCE_FORM
    },

    activeFormComponent() {
      return this.activeForm === OVERLAP_ABSENCE_FORM
        ? 'absence-overlap-dialogue'
        : 'absence-form-main'
    },
    modalTitle() {
      return this.activeForm === OVERLAP_ABSENCE_FORM
        ? this.$t('absence.overlap_absence.title')
        : this.$t('absence.add_modal.title')
    },

    dialogMessage() {
      return this.isAssumedRoleAdmin
        ? this.$t('absence.add_modal.base_dialog.error_message.admin')
        : this.$t('absence.add_modal.base_dialog.error_message.user')
    },
    noteSubmitDisabled() {
      return this.passedPayload.note.length &&
        this.passedPayload.note.length > MAX_NOTE_LENGTH
        ? true
        : false
    },
  },
  watch: {
    overlap: {
      handler(value) {
        if (value && this.payload.employees.length === 1) {
          this.activeForm = OVERLAP_ABSENCE_FORM
        }
      },
      immediate: true,
    },
  },
  created() {
    this.activeForm = MAIN_ABSENCE_FORM
    store.commit(ABSENCE_MUTATION_SET_ABSENCE_OVERLAP, [])
  },
  methods: {
    async handleFormStep(footerActionValidate, { handleSubmit, isValid }) {
      if (this.passedPayload.dateRange.length === 1) {
        this.passedPayload.dateRange.push(this.passedPayload.dateRange[0])
        await calculateAbsence(this.passedPayload)
      }

      this.$emit('custom-validate', (isCustomValidateIsValid) =>
        this.formStep(footerActionValidate, {
          handleSubmit,
          isValid,
          isCustomValidateIsValid,
        })
      )
    },
    async formStep(
      footerActionValidate,
      { isCustomValidateIsValid, isValid, handleSubmit }
    ) {
      this.isErrorDialogOpen = !isCustomValidateIsValid
      await footerActionValidate(handleSubmit)

      if (!(isCustomValidateIsValid && isValid)) {
        return
      }

      this.$emit('submit')
    },
    handleEditButton() {
      store.commit(ABSENCE_MUTATION_SET_ABSENCE_OVERLAP, [])
      this.handleBackStep()
    },
    handleBackStep() {
      this.activeForm = MAIN_ABSENCE_FORM
    },
    handleDiscardChanges() {
      this.$emit('close')
    },
    handleCloseModal() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
::v-deep .dialog {
  max-width: 500px;
}
</style>
