<template>
  <div class="flex flex-col">
    <component :is="absenceFormTopSection" :value="passedValue" />

    <absence-form-date-preview :value="passedValue" />
    <div>
      <absence-form-input-note
        v-model="passedValue"
        :attachments="passedValue.attachments"
        :show-action-attachment="false"
        data-id="absence.add_form.absence_form_preview.input_note"
        disabled
      />
    </div>
  </div>
</template>

<script>
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

import AbsenceFormInputNote from '../absence-form-input-note/AbsenceFormInputNote.vue'
import AbsenceFormTopSectionAdminTemplate from './AbsenceFormTopSectionAdminTemplate'
import AbsenceFormTopSectionEmployeeTemplate from './AbsenceFormTopSectionEmployeeTemplate'
import AbsenceFormDatePreview from './AbsenceFormDatePreview.vue'

export default {
  name: 'AbsenceFormPreview',
  components: {
    AbsenceFormInputNote,
    AbsenceFormTopSectionAdminTemplate,
    AbsenceFormTopSectionEmployeeTemplate,
    AbsenceFormDatePreview,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      attachments: [],
    }
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    absenceFormTopSection() {
      if (this.$store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]) {
        return 'absence-form-top-section-admin-template'
      }
      return 'absence-form-top-section-employee-template'
    },
  },
  mounted() {
    this.attachments = this.passedValue.attachments
  },
}
</script>
