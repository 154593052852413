



























import {
  defineComponent,
  PropType,
  computed,
  ComputedRef,
  onMounted,
  ref,
} from '@vue/composition-api'

import BaseInputTag from '@/app/util-modules/ui/input-tag/BaseInputTag.vue'
import { AddAbsencePayload } from '../../../models/absence.model'
import useAbsenceEmployee from '../absence-form-input-employees/composables/use-absence-employee'
import { AbsenceOptionTypeEmployee } from '@/app/modules/absence/models/absence.model'
import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@/constants'
import debounce from 'lodash/debounce'

type Employee = {
  id: string
  attributes: {
    username: string
    hour_holidays?: boolean
  }
}

export default defineComponent({
  name: 'AbsenceFormInputEmployee',
  components: {
    BaseInputTag,
  },
  props: {
    value: {
      type: Object as PropType<AddAbsencePayload>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const EMPLOYEE_SELECT_LIMIT = 10

    const selectedEmployees = ref([])

    const { searchContext, search, searchNextPage } = useAbsenceEmployee()

    const passedValue: ComputedRef<AddAbsencePayload> = computed({
      get(): AddAbsencePayload {
        return props.value
      },
      set(passedValue) {
        emit('input', passedValue)
      },
    })

    const mapEmployeeAttributes = (
      employees: Employee[],
      selectedEmployees: AbsenceOptionTypeEmployee[]
    ) =>
      employees
        .filter((employee: Employee) => {
          return !selectedEmployees.some((emp: any) => emp.id === employee.id)
        })
        .map((employee: Employee) => ({
          id: employee.id,
          title: employee.attributes.username,
          username: employee.attributes.username,
          hourHolidays: employee.attributes.hour_holidays,
          _type: 'user',
        }))

    const employees = computed(() =>
      mapEmployeeAttributes(searchContext.value.options, props.value.employees)
    )

    const employeeValidationRule = computed(() => ({
      required: !passedValue.value.employees.length,
      employee_validation_rule: {
        isFieldValid: !!passedValue.value.employees.length,
      },
    }))

    const resetSearchList = () =>
      search({
        query: '',
        page: 1,
      })

    const addEmployee = (selectedEmployee: Employee) => {
      if (passedValue.value.employees.length >= EMPLOYEE_SELECT_LIMIT) {
        return
      }
      if (!selectedEmployee) {
        return
      }
      const selectedEmployeeExist = passedValue.value.employees.some(
        (employee) => employee && employee.id === selectedEmployee.id
      )
      if (!selectedEmployeeExist) {
        passedValue.value.employees.push(selectedEmployee)
      }
      resetSearchList()
    }

    const handleTyping = debounce((query) => {
      const filter = passedValue.value.employees.length
        ? {
            id_not_in: passedValue.value.employees.map(
              (el: { id: string }) => el.id
            ),
          }
        : null
      search({
        query,
        page: 1,
        filter,
      })
    }, DEFAULT_SEARCH_DEBOUNCE_TIME)

    onMounted(() => {
      search({
        query: '',
        page: 1,
      })
    })

    return {
      EMPLOYEE_SELECT_LIMIT,
      passedValue,
      employees,
      employeeValidationRule,
      searchContext,
      selectedEmployees,

      addEmployee,
      searchNextPage,
      search,
      resetSearchList,
      handleTyping,
    }
  },
})
