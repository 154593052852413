feat: work in progress 524a7fdd
<template>
  <div class="flex justify-between">
    <div class="flex items-baseline">
      <div class="mr-3.5 pr-px font-medium text-coal-750">{{
        note.content
      }}</div>
      <div class="flex text-coal-650 text-sm 12px font-medium">
        <span>{{ dateTime }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbsenceEditFormNotesItemSystemDisplay',
  props: {
    note: {
      type: Object,
      required: true,
    },
    dateTime: {
      type: String,
      required: true,
    },
  },
}
</script>
