import axios from '@/utils/vendors/axios'
import { AxiosPromise } from 'axios'
import {
  BaseRequestPagination,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import { BaseResponseMeta } from '@/utils/api/base-response'
import {
  AbsenceRaw,
  AbsenceNoteRaw,
  AbsenceListRequest,
  AbsenceCreateRequest,
  AbsenceUpdateFormData,
  AbsenceCreateBatchRequest,
  AbsenceCreateFormData,
  AbsenceVacationSummary,
  AbsenceApproveRequest,
  AbsenceRejectRequest,
  AbsenceResetRequest,
  AbsenceDeleteRequest,
  AbsenceUpdateRequest,
  AbsenceUpdateFollowersRequest,
  AbsenceDeleteFollowersRequest,
  AbsenceListNotesRequest,
  AbsenceShowRequest,
  AbsenceCalculatorRequest,
  AbsenceCalculatorParams,
  AbsenceListUsersAbsenceTypesRequest,
  AbsenceCalendarListAbsenceRequest,
  AbsenceCalendarDateRange,
  AbsenceCheckAbsenceOverlapRequest,
  AbsenceCheckOverlapParams,
  Note,
} from '../models/absence.model'
import {
  AbsenceAddNoteRequest,
  AbsenceUpdateNoteRequest,
  AbsenceDeleteNoteRequest,
} from '../models/absence-note.model'
import {
  AttachmentListRequestParams,
  AbsenceAttachmentListRequest,
} from '../models/absence-attachment.model'

import {
  ABSENCE_ENDPOINT_LIST_CREATIONS_USER,
  ABSENCE_ENDPOINT_SEARCH_CREATIONS_USER,
} from '../network/absence.endpoints'

export default {
  listAbsences({
    workspaceId,
    sorting,
    filter,
    pagination,
  }: {
    workspaceId: RecordId
    sorting: BaseRequestSorting
    filter: Record<string, string>
    pagination: BaseRequestPagination
  }): AxiosPromise<{
    data: AbsenceRaw[]
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceListRequest({ workspaceId, pagination, sorting, filter })
    )
  },
  createAbsenceBatch(
    workspaceId: RecordId,
    formValues: AbsenceCreateFormData
  ): AxiosPromise {
    return axios.request(
      new AbsenceCreateBatchRequest({ workspaceId, formValues })
    )
  },
  createAbsence(
    workspaceId: RecordId,
    formValues: AbsenceCreateFormData
  ): AxiosPromise {
    return axios.request(new AbsenceCreateRequest({ workspaceId, formValues }))
  },
  listVacationSummary({
    workspaceId,
    userId,
  }: {
    workspaceId: RecordId
    userId: RecordId
  }): AxiosPromise<{
    data: AbsenceRaw[]
    meta: BaseResponseMeta
  }> {
    return axios.request(new AbsenceVacationSummary({ workspaceId, userId }))
  },
  approveAbsence({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(new AbsenceApproveRequest({ workspaceId, absenceId }))
  },
  rejectAbsence({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(new AbsenceRejectRequest({ workspaceId, absenceId }))
  },
  resetAbsence({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(new AbsenceResetRequest({ workspaceId, absenceId }))
  },
  deleteAbsence({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(new AbsenceDeleteRequest({ workspaceId, absenceId }))
  },
  addNoteToAbsence({
    workspaceId,
    absenceId,
    currentUserId,
    note,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    currentUserId: RecordId
    note: Note
  }): AxiosPromise<{
    data: AbsenceNoteRaw
    meta: BaseResponseMeta
  }> {
    return axios.request(
      new AbsenceAddNoteRequest({
        workspaceId,
        absenceId,
        currentUserId,
        note,
      })
    )
  },
  updateAbsenceNote({
    workspaceId,
    absenceId,
    currentUserId,
    note,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    currentUserId: RecordId
    note: Note
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceUpdateNoteRequest({
        workspaceId,
        absenceId,
        currentUserId,
        note,
      })
    )
  },
  deleteAbsenceNote({
    workspaceId,
    absenceId,
    noteId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    noteId: RecordId
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceDeleteNoteRequest({
        workspaceId,
        absenceId,
        noteId,
      })
    )
  },
  updateAbsence({
    workspaceId,
    absenceId,
    absenceParams,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    absenceParams: AbsenceUpdateFormData
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceUpdateRequest({ workspaceId, absenceId, absenceParams })
    )
  },
  updateAbsenceFollowers({
    workspaceId,
    absenceId,
    ids,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    ids: RecordId[]
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceUpdateFollowersRequest({ workspaceId, absenceId, ids })
    )
  },
  deleteAbsenceFollower({
    workspaceId,
    absenceId,
    follower_id,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    follower_id: RecordId
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceDeleteFollowersRequest({ workspaceId, absenceId, follower_id })
    )
  },
  listAbsenceNotes({
    workspaceId,
    absenceId,
    filter,
    pagination,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    filter: {
      type?: string
    }
    pagination: BaseRequestPagination
  }): AxiosPromise<{
    data: AbsenceRaw[]
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceListNotesRequest({
        workspaceId,
        absenceId,
        filter,
        pagination,
      })
    )
  },
  showAbsence({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }): AxiosPromise<{
    data: AbsenceRaw
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(new AbsenceShowRequest({ workspaceId, absenceId }))
  },

  listAbsenceAttachments({
    workspaceId,
    absenceId,
  }: AttachmentListRequestParams) {
    return axios.request(
      new AbsenceAttachmentListRequest({ workspaceId, absenceId })
    )
  },

  calculateAbsence({
    workspaceId,
    calculatorParams,
  }: {
    workspaceId: RecordId
    calculatorParams: AbsenceCalculatorParams
  }) {
    return axios.request(
      new AbsenceCalculatorRequest({ workspaceId, calculatorParams })
    )
  },
  listUsersAbsenceTypes({
    workspaceId,
    userIds,
    pagination,
  }: {
    workspaceId: RecordId
    userIds: RecordId[]
    pagination: BaseRequestPagination
  }): AxiosPromise<{
    data: AbsenceRaw[]
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceListUsersAbsenceTypesRequest({
        workspaceId,
        userIds,
        pagination,
      })
    )
  },
  async searchAbsenceCreationUsers(
    workspaceId: number,
    { page, query }: { page: number; query: string | null }
  ) {
    const response = await axios.get(
      query
        ? ABSENCE_ENDPOINT_SEARCH_CREATIONS_USER(workspaceId, page, query)
        : ABSENCE_ENDPOINT_LIST_CREATIONS_USER(workspaceId, page)
    )
    return {
      results: response.data.data,
      totalPages: response.data.meta.total_pages,
    }
  },
  listCalendarAbsences({
    workspaceId,
    dateRange,
  }: {
    workspaceId: RecordId
    dateRange: AbsenceCalendarDateRange
  }): AxiosPromise<{
    data: AbsenceRaw[]
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceCalendarListAbsenceRequest({
        workspaceId,
        dateRange,
      })
    )
  },

  absenceCheckAbsenceOverlap({
    workspaceId,
    payload,
  }: {
    workspaceId: RecordId
    payload: AbsenceCheckOverlapParams
  }): AxiosPromise<{
    data: AbsenceRaw[]
    meta: BaseResponseMeta
    included: []
  }> {
    return axios.request(
      new AbsenceCheckAbsenceOverlapRequest({
        workspaceId,
        payload,
      })
    )
  },
}
