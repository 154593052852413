
































































import capitalize from 'lodash/capitalize'
import { AddAbsencePayload } from '../../../models/absence.model'

import {
  defineComponent,
  PropType,
  computed,
  ComputedRef,
} from '@vue/composition-api'
import useAbsenceType from './composables/use-absence-type'
import BaseDropdown from '@/app/util-modules/ui/dropdown/BaseDropdown.vue'
import BaseInputField from '@/app/util-modules/ui/input/BaseInputField.vue'
import BaseDropdownItem from '@/app/util-modules/ui/dropdown/BaseDropdownItem.vue'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip.vue'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import { ABSENCE_KIND } from '@/app/modules/absence/constants'
import VueI18n from '@/utils/vendors/i18n'

export default defineComponent({
  name: 'AbsenceFormInputType',
  components: {
    BaseDropdown,
    BaseDropdownItem,
    BaseInputField,
    BaseTooltip,
    BaseIcon,
  },
  props: {
    value: {
      type: Object as PropType<AddAbsencePayload>,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const passedValue: ComputedRef<AddAbsencePayload> = computed({
      get(): AddAbsencePayload {
        return props.value
      },
      set(passedValue) {
        emit('input', passedValue)
      },
    })

    const showAbsenceDays = computed(
      () => passedValue.value.employees.length === 1
    )

    const { absenceTypesOptions, vacationDaysRemaining, selectedOption } =
      useAbsenceType(passedValue)

    const showNumberOfDays = (optionKind: string) =>
      showAbsenceDays.value && optionKind === ABSENCE_KIND.VACATION

    const displayOption = computed(() => {
      const titleText = capitalize(passedValue.value.type.attributes.title)
      if (showNumberOfDays(passedValue.value.type.attributes.kind)) {
        return `${VueI18n.t('absence.absence_days_available', {
          title: titleText,
          days: vacationDaysRemaining.value,
        })}`
      }
      return titleText
    })

    return {
      absenceTypesOptions,
      vacationDaysRemaining,
      selectedOption,
      showAbsenceDays,
      ABSENCE_KIND,
      passedValue,
      displayOption,

      capitalize,
      showNumberOfDays,
    }
  },
})
