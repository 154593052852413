import { Ref, watch } from '@vue/composition-api'
import store from '@/store'
import { ABSENCE_MUTATION_SET_CALCULATION } from '@/app/modules/absence/store/mutations/absence.mutations.names'
import { calculateAbsence } from '@/app/modules/absence/components/absence-form/absenceForm.utils'

export default function useCalculateTotal(
  payload: Ref<any> // TODO replace any with an interface that supports both AddAbsencePayload and EditAbsencePayload
) {
  store.commit(ABSENCE_MUTATION_SET_CALCULATION, {
    days: 0,
    hours: 0,
  })

  watch(
    () => [
      payload.value.employees.length,
      payload.value.type.id,
      payload.value.fullDay,
      payload.value.startTime,
      payload.value.endTime,
      payload.value.dateRange.length,
      payload.value.manualValue,
    ],
    () => {
      const { manualValue, employees, type, startTime, endTime, dateRange } =
        payload.value
      if (
        !manualValue &&
        employees.length === 1 &&
        type.id &&
        startTime &&
        endTime &&
        dateRange.length === 2
      ) {
        calculateAbsence(payload.value)
      }
    },
    { immediate: true }
  )
}
