import {
  computed,
  ComputedRef,
  WritableComputedRef,
} from '@vue/composition-api'

import store from '@/store'
import { MODULE_NAME } from '@/app/modules/absence/absence.module'

import {
  AddAbsencePayload,
  AbsenceOptionType,
} from '@/app/modules/absence/models/absence.model'

export default function useAbsenceType(
  passedValue: WritableComputedRef<AddAbsencePayload>
) {
  const absenceTypesOptions = computed(
    () => store.state[MODULE_NAME].absenceTypes
  )

  const vacationSummary = computed(
    () => store.state[MODULE_NAME].vacationSummary
  )

  const selectedOption = computed({
    get(): string {
      return passedValue.value.type.id
    },
    set(value): void {
      passedValue.value.type =
        absenceTypesOptions.value.find(
          (types: AbsenceOptionType) => types.id === value
        ) || {}
    },
  })

  const vacationDaysRemaining: ComputedRef<number> = computed(
    () =>
      vacationSummary.value.attributes?.remaining_vacation_days_this_year || 0
  )

  return {
    absenceTypesOptions,
    vacationDaysRemaining,
    selectedOption,
  }
}
