


















import {
  getDefaultStartDateTime,
  getDefaultEndDateTime,
  combineDateTime,
  defaultDateRange,
  formatDateFilter,
} from '@/utils/date-time.utils'

import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import AbsenceFormModal from '../absence-form/AbsenceFormModal.vue'
import { MODULE_NAME } from '@/app/modules/absence/absence.module'
import {
  AddAbsencePayload,
  AbsenceOptionTypeEmployee,
} from '@/app/modules/absence/models/absence.model'
import {
  ABSENCE_ACTION_LIST_VACATION_SUMMARY,
  ABSENCE_ACTION_CREATE_ABSENCE,
  ABSENCE_ACTION_CREATE_BATCH_ABSENCE,
  ABSENCE_ACTION_CALCULATE_ABSENCE,
  ABSENCE_ACTION_LIST_USERS_ABSENCE_TYPES,
  ABSENCE_ACTION_CHECK_ABSENCE_OVERLAP,
} from '@/app/modules/absence/store/actions/absence.actions.names'
import { ABSENCE_MUTATION_SET_VACATION_SUMMARY } from '@/app/modules/absence/store/mutations/absence.mutations.names'
import { ABSENCE_KIND } from '@/app/modules/absence/constants'
import {
  USER_GETTER_IS_ROLE_ADMIN,
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
  USER_GETTER_USER,
} from '@/app/core/user/store/getters/user.getters.names'

import useVacationValidator from './composables/use-vacation-validator'
import useCalculateTotal from './composables/use-calculate-total'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import { isSameDay } from 'date-fns'

export default defineComponent({
  name: 'AbsenceAdd',
  components: {
    AbsenceFormModal,
  },

  setup(_props, { emit }) {
    const currentUserId = computed(() => store.getters[USER_GETTER_USER].id)

    const formInputNoteValidity = ref(false)

    const $actions = useActionTracker({
      calculateAbsence: ABSENCE_ACTION_CALCULATE_ABSENCE,
      createAbsence: ABSENCE_ACTION_CREATE_ABSENCE,
      createBatchAbsence: ABSENCE_ACTION_CREATE_BATCH_ABSENCE,
    })

    const actionsAbsenceCreate = computed(() =>
      isCurrentUserAdmin.value && isAssumedRoleAdmin.value
        ? $actions.createBatchAbsence
        : $actions.createAbsence
    )

    const handleCreate = async () => {
      const {
        dateRange,
        startTime,
        endTime,
        fullDay,
        manualValue,
        manualHours,
        manualDays,
        type,
        note,
        attachments,
        followers,
      } = payload.value

      let finalStartDateTime = null
      let finalEndDateTime = null

      const [dateRangeStart, dateRangeEnd] = dateRange

      const user_ids = payload.value.employees.map(
        (employee: AbsenceOptionTypeEmployee) => employee.id
      )

      if (!fullDay && isSameDay(dateRangeStart, dateRangeEnd)) {
        finalStartDateTime = combineDateTime(
          dateRangeStart,
          startTime
        ).toISOString()
        finalEndDateTime = combineDateTime(dateRangeEnd, endTime).toISOString()
      } else {
        finalStartDateTime = formatDateFilter(dateRangeStart)
        finalEndDateTime = formatDateFilter(dateRangeEnd)
      }

      const follower_ids = followers.map(
        (follower: AbsenceOptionTypeEmployee) => follower.id
      )

      const formData = {
        user_ids,
        ends_at: `${finalEndDateTime}`,
        starts_at: `${finalStartDateTime}`,
        full_day: fullDay,
        hours: +manualHours,
        days: +manualDays,
        absence_type_id: type.id,
        title: type.attributes.title,
        use_custom_values: manualValue,
        noteContent: note,
        attachments: attachments,
        follower_ids,
        currentUserId: currentUserId.value,
      }

      await store.dispatch(ABSENCE_ACTION_CHECK_ABSENCE_OVERLAP, {
        starts_at: formData.starts_at,
        ends_at: formData.ends_at,
        user_ids: formData.user_ids,
        full_day: fullDay,
      })

      if (overlapAbsences.value.length && user_ids.length === 1) {
        return
      }
      if (overlapAbsences.value.length) {
        Snackbar.open({
          type: 'is-danger',
          message: i18n.t('absence.add_form.day_time_overlap_message'),
        })
      }

      if (isCurrentUserAdmin.value && isAssumedRoleAdmin.value) {
        await store.dispatch(ABSENCE_ACTION_CREATE_BATCH_ABSENCE, formData)
      } else {
        await store.dispatch(ABSENCE_ACTION_CREATE_ABSENCE, formData)
      }

      Snackbar.open(i18n.t('absence.add_form.create_success_message'))

      emit('success')
      emit('close')
    }

    const user = computed(() => store.state.user.user)

    const payload = ref<AddAbsencePayload>({
      employees: [],
      type: {
        id: '',
        type: '',
        attributes: {
          kind: '',
          title: '',
        },
      },
      times: [],
      fullDay: true,
      startTime: getDefaultStartDateTime(),
      endTime: getDefaultEndDateTime(),
      total: {
        days: 0,
        hours: 0,
      },
      manualValue: false,
      manualHours: 0,
      manualDays: 0,
      note: '',
      attachments: [],
      followers: [],
      dateRange: defaultDateRange(),
    })

    const vacationSummary = computed(
      () => store.state[MODULE_NAME].vacationSummary
    )

    const overlapAbsences = computed(
      () => store.state[MODULE_NAME].overlapAbsences
    )

    /* eslint-disable-next-line no-undef */
    const listUsersAbsenceTypes = (userIds: RecordId[]) => {
      return store.dispatch(ABSENCE_ACTION_LIST_USERS_ABSENCE_TYPES, userIds)
    }

    const listVacationSummary = async (userId: string) => {
      await store.dispatch(ABSENCE_ACTION_LIST_VACATION_SUMMARY, userId)
      return (
        vacationSummary.value.attributes.remaining_vacation_days_this_year || 0
      )
    }

    const { areEmployeesVacationValid } =
      useVacationValidator(listVacationSummary)

    const validationStates = {
      employee: false,
    }

    const handleNoteValidation = (validity: boolean) => {
      formInputNoteValidity.value = validity
    }

    const isNoteAttachmentValid = computed(() => {
      const { attachments, note } = payload.value
      if (
        (note.length && !formInputNoteValidity.value) ||
        (attachments.length && !note)
      ) {
        return false
      }
      return true
    })

    const customValidator = async (callback: (isValid: boolean) => void) => {
      if (!isNoteAttachmentValid.value) {
        return isNoteAttachmentValid
      }
      validationStates.employee = payload.value.employees.length ? false : true

      if (payload.value.manualValue) {
        callback(true)
      } else if (
        payload.value.employees.length &&
        payload.value.type.attributes.kind === ABSENCE_KIND.VACATION
      ) {
        const result = await areEmployeesVacationValid({
          employees: payload.value.employees,
          totalDay: payload.value.total.days,
        })
        callback(result)
      } else {
        callback(true)
      }
    }

    const isCurrentUserAdmin = computed(
      () => store.getters[USER_GETTER_IS_ROLE_ADMIN]
    )

    const isAssumedRoleAdmin = computed(
      () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )

    const setDefaultAbsenceType = async (id: string) => {
      await listVacationSummary(id)
      await listUsersAbsenceTypes([id])
    }

    const employeeIds = computed(() =>
      payload.value.employees.map((emp) => emp.id)
    )

    watch(
      () => payload.value.employees.length,
      async () => {
        if (payload.value.employees.length === 1) {
          await setDefaultAbsenceType(payload.value.employees[0].id)
          payload.value.employees[0].hourHolidays =
            vacationSummary.value.attributes.hour_holidays

          return
        }

        if (payload.value.employees.length > 1) {
          listUsersAbsenceTypes(employeeIds.value)
          return
        }

        store.commit(ABSENCE_MUTATION_SET_VACATION_SUMMARY, {
          attributes: { remaining_vacation_days_this_year: 0 },
        })
        listUsersAbsenceTypes([`${user.value.id}`])
      },
      { immediate: true }
    )

    useCalculateTotal(payload)

    return {
      $actions,

      handleCreate,
      customValidator,
      handleNoteValidation,
      setDefaultAbsenceType,
      areEmployeesVacationValid,

      employeeIds,
      payload,
      validationStates,
      actionsAbsenceCreate,
      formInputNoteValidity,
      isNoteAttachmentValid,
      overlapAbsences,
    }
  },
})
