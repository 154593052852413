<template>
  <div class="flex flex-wrap">
    <span>{{ startDate }}</span>
    <template v-if="!isSameDate">
      &nbsp;-&nbsp;
      <span>{{ endDate }}</span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AbsenceCellDate',
  props: {
    startDate: {
      type: [String, Date],
      required: true,
    },
    endDate: {
      type: [String, Date],
      required: true,
    },
  },
  computed: {
    isSameDate() {
      return this.startDate === this.endDate
    },
  },
}
</script>
