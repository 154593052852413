<template>
  <div class="relative">
    <div class="py-1 flex justify-between">
      <div class="label">
        <span v-if="!disabled">
          <span>
            {{ $t('absence.add_form.field_label_add_note') }}
          </span>
          <base-tooltip
            v-if="passedValue.employees.length > 1"
            :label="$t('absence.add_form.field_label_add_note_tooltip')"
            position="is-right"
          >
            <base-icon
              icon="help-information/help-information-16"
              size="1rem"
              type="is-filled"
            />
          </base-tooltip>
        </span>
        <span v-else class="text-coal-550">
          {{ $t('absence.preview_form.field_label_note') }}
        </span>
      </div>
      <div v-if="!disabled" class="text-coal-550 absolute right-0 font-medium">
        {{ $t('absence.add_form.field_label_optional') }}
      </div>
    </div>
    <base-textarea
      v-model="passedValue.note"
      :attachments="attachments"
      :submit-button-label="$t('absence.add_form.field_label_add_note')"
      :disabled="disabled"
      :actions="!disabled"
      :show-action-submit="false"
      :show-action-attachment="showActionAttachment"
      :no-borders="disabled"
      :class="{ 'absence-form-input-note-disabled': disabled }"
      :file-upload-icon-tooltip-label="
        $t('absence.add_form.field_label_add_note_file_upload_icon_tooltip')
      "
      file-upload-icon-tooltip-position="is-right"
      :hide-wrapper-outer-background="disabled"
      @file-input="addAttachment($event)"
      @attachment-delete="removeAttachment($event)"
      @submit-click="onSubmitClick()"
      @validity-change="$emit('note-validation', $event)"
    />
  </div>
</template>

<script>
import BaseTextarea from '@/app/util-modules/ui/textarea/BaseTextarea.vue'
import BaseTooltip from '@/app/util-modules/ui/tooltip/BaseTooltip'
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon.vue'
import { isSameFile } from '@/utils/helpers'

export default {
  name: 'AbsenceFormInputNote',
  components: {
    BaseTextarea,
    BaseTooltip,
    BaseIcon,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    note: {
      type: String,
      default: '',
    },
    showActionAttachment: {
      type: Boolean,
      default: false,
    },
    validationStates: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    onSubmitClick() {},
    removeAttachment(attachmentToDelete) {
      const attachments = this.passedValue.attachments.filter(
        (attachment) => !isSameFile(attachment, attachmentToDelete)
      )
      this.passedValue.attachments = [...attachments]
      this.$emit('attachments-change', this.passedValue.attachments)
    },

    addAttachment(files) {
      const attachments = [...this.passedValue.attachments]
      const filesToAdd = files.filter((f) => {
        const foundAttachment = attachments.findIndex((f2) => isSameFile(f2, f))
        return foundAttachment === -1
      })
      this.passedValue.attachments = [...attachments, ...filesToAdd]
    },
  },
}
</script>

<style scoped>
::v-deep
  .absence-form-input-note-disabled
  > .field
  .base-input
  > .base-textarea-textarea
  > .textarea {
  @apply px-2 py-2 border-none rounded-none rounded-tl rounded-tr;
}

::v-deep .absence-form-input-note-disabled .base-text-area-counter {
  @apply pr-3 pb-3;
}
</style>
