<template>
  <div>
    <portal to="modal">
      <base-attachments-modal
        :attachments="attachments"
        :action-status="actionStatus"
        data-id-prefix="absences.index"
        @close="$emit('close')"
      />
    </portal>
  </div>
</template>

<script>
import BaseAttachmentsModal from '@/app/util-modules/ui/attachments-modal/BaseAttachmentsModal.vue'

export default {
  name: 'AbsenceAttachments',
  components: {
    BaseAttachmentsModal,
  },
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
