



































import { defineComponent, computed } from '@vue/composition-api'
import store from '@/store'
import { ABSENCE_ACTION_SELECT_COLUMNS_TO_SHOW } from '../../store/actions/absence.actions.names'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import AbsenceTabsHeader from '../absence-tabs-header/AbsenceTabsHeader.vue'
import AbsenceFilterbar from '../absence-filterbar/AbsenceFilterbar.vue'
import TableColumnsCustomizeDropdown from '@/app/util-modules/ui/table-columns-customize-dropdown/TableColumnsCustomizeDropdown.vue'
import AbsenceIndexDateRangePicker from '../absence-index-date-range-picker/AbsenceIndexDateRangePicker.vue'
import { MODULE_NAME } from '../../absence.module'
import {
  TABLE_COLUMNS,
  TABLE_COLUMNS_IDS,
  ABSENCE_LIST_PREFERENCE_KEY,
} from '../../constants'
import {
  USER_PUBLIC_GETTER_PREFERENCE,
  USER_GETTER_USER,
} from '@/app/core/user/store/getters/user.getters.names'

export default defineComponent({
  name: 'AbsenceHeaderDesktop',
  components: {
    AbsenceIndexDateRangePicker,
    AbsenceTabsHeader,
    TableColumnsCustomizeDropdown,
    BaseButton,
    AbsenceFilterbar,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const canViewAbsencesOfOthers =
      store.getters[USER_GETTER_USER].attributes.can_view_absences_of_others
    const allSelectedColumns = computed(() => {
      const selectedColumns = store.getters[USER_PUBLIC_GETTER_PREFERENCE](
        ABSENCE_LIST_PREFERENCE_KEY
      )
      if (!selectedColumns) return {}

      if (props.isAdmin || canViewAbsencesOfOthers) return selectedColumns.value

      const { employee, ...restOfColumns } = selectedColumns.value

      return restOfColumns
    })

    const setColumnsToShow = (columns: { [k: string]: boolean }) =>
      store.dispatch(ABSENCE_ACTION_SELECT_COLUMNS_TO_SHOW, {
        ...columns,
      })

    const handleInput = ({
      selected,
      column,
    }: {
      selected: boolean
      column: { unique_key: string }
    }) => {
      setColumnsToShow({
        [column.unique_key]: selected,
      })
    }

    const selectAll = () => {
      const columnsToDisplay = TABLE_COLUMNS_IDS.reduce(
        (acc: { [key: string]: boolean }, obj) => {
          if (props.isAdmin || canViewAbsencesOfOthers || obj !== 'employee') {
            acc[obj] = true
          }
          return acc
        },
        {}
      )
      setColumnsToShow({ ...columnsToDisplay })
    }

    const tableColumns = computed(() => {
      if (props.isAdmin || canViewAbsencesOfOthers) {
        return TABLE_COLUMNS
      }
      return TABLE_COLUMNS.filter((column) => column.unique_key !== 'employee')
    })

    return {
      tableColumns: tableColumns,
      moduleName: MODULE_NAME,
      allSelectedColumns,
      handleInput,
      selectAll,
    }
  },
})
