<template>
  <div ref="noteItem" class="flex items-center mb-4 cursor-pointer">
    <div class="self-start">
      <base-avatar
        :img-url="source.avatar"
        img-class="w-8 h-8"
        :username="avatarUsername"
        size="is-small"
        class="mr-3 text-white"
        :avatar-class="avatarClass"
      />
    </div>
    <absence-edit-form-notes-item-edit
      v-if="showEditNote"
      v-model="editNoteContent"
      :data-id-prefix="dataIdPrefix"
      @attachment-delete="handleAttachmentDelete($event)"
      @attachment-add="handleAttachmentAdd($event)"
      @cancel="onCancel()"
      @submit="onSave()"
    />
    <absence-edit-form-notes-item-display
      v-else
      :tooltip-position="tooltipPosition"
      :note="source"
      :data-id-prefix="dataIdPrefix"
      @edit="editNote()"
      @delete="deleteNote()"
      @edit-absence-more-attachments="
        $emit('edit-absence-more-attachments', $event)
      "
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import BaseAvatar from '@/app/util-modules/ui/avatar/BaseAvatar'
import { NOTE_TYPES } from '@/constants'
import AbsenceEditFormNotesItemDisplay from './absence-edit-form-notes-item-display/AbsenceEditFormNotesItemDisplay.vue'

import AbsenceEditFormNotesItemEdit from './AbsenceEditFormNotesItemEdit.vue'
import { ABSENCE_MUTATION_SINGLE_NOTE_RECORD } from '@/app/modules/absence/store/mutations/absence.mutations.names'
import { isSameFile } from '@/utils/helpers'
import { ABSENCE_NOTE_TYPE } from '@/app/modules/absence/models/absence.model'
import { MODULE_NAME } from '@/app/modules/absence/absence.module'

export default {
  name: 'AbsenceEditFormNotesItem',
  components: {
    BaseAvatar,
    AbsenceEditFormNotesItemDisplay,
    AbsenceEditFormNotesItemEdit,
  },
  props: {
    dataIdPrefix: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    source: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      notePermissions: {
        update: true,
        destroy: true,
      },
      baseNoteOptionsIcon: {
        icon: 'ellipsis/ellipsis-24',
        size: '1.5rem',
      },
      editNoteContent: {
        currentNoteContent: null,
        currentNoteAttachments: [],
      },
      tooltipPosition: 'is-right',
      containerParentTopPosition: null,
      itemChildTopPosition: null,
      filesToAdd: [],
    }
  },
  computed: {
    ...mapState({
      notes: (state) => state[MODULE_NAME].records[ABSENCE_NOTE_TYPE],
    }),
    passedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    avatarUsername() {
      return this.source.author || NOTE_TYPES.SYSTEM
    },
    avatarClass() {
      return this.source.type === NOTE_TYPES.USER
        ? 'bg-blue-300'
        : 'bg-coal-300'
    },
    showEditNote() {
      return (
        this.editNoteContent.currentNoteContent ||
        this.editNoteContent.currentNoteContent === ''
      )
    },
    username() {
      return this.source.type === NOTE_TYPES.SYSTEM ? 'SYS' : 'user'
    },
  },
  watch: {
    notes: {
      handler() {
        this.editNoteContent.currentNoteAttachments =
          this.source.attachments.filter((attachment) => !attachment.isDeleted)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$refs.noteItem) {
      this.setDefaultTop()
    }
  },
  beforeDestroy() {
    this.$parent.$parent.$el.removeEventListener(
      'scroll',
      this.handleParentScroll
    )
  },
  methods: {
    ...mapMutations({
      updateSingleAbsenceNote: ABSENCE_MUTATION_SINGLE_NOTE_RECORD,
    }),

    editNote() {
      this.editNoteContent.currentNoteContent = this.source.content
      this.editNoteContent.currentNoteAttachments =
        this.source.attachments.filter((attachment) => !attachment.isDeleted)
      this.$emit('is-editing-note', true)
    },
    deleteNote() {
      const currentNote = {
        ...this.source,
        isEdited: false,
        isDeleted: true,
      }
      this.updateSingleAbsenceNote(currentNote)
      this.$emit('delete-note')
    },
    onCancel() {
      this.editNoteContent.currentNoteContent = null
      this.editNoteContent.currentNoteAttachments = []
      this.$emit('is-editing-note', false)
    },
    onSave() {
      //flattenRecord in @/utils/jsonapi/utils.ts replace _type with this.note.type for userNote
      //the following is a workaround
      const currentNote = {
        ...this.source,
        content: this.editNoteContent.currentNoteContent,
        isEdited: true,
        isDeleted: false,
      }
      this.updateSingleAbsenceNote(currentNote)
      this.editNoteContent.currentNoteContent = null
      this.$emit('is-editing-note', false)
      this.$emit('save-note')
    },
    handleAttachmentDelete(attachmentToDelete) {
      const attachments = [...this.source.attachments]
      const foundAttachments = attachments.find(
        (a) => a.id === attachmentToDelete.id
      )
      if (foundAttachments) {
        foundAttachments.isDeleted = true
      }
      const currentNote = {
        ...this.source,
        isEdited: true,
        isDeleted: false,
        attachments,
        updatedAttachments: [...attachments, ...this.filesToAdd],
      }
      this.updateSingleAbsenceNote(currentNote)
    },
    handleAttachmentAdd(files) {
      const attachments = [...this.source.attachments]
      const filesToAdd = files.filter((f) => {
        const foundAttachment = attachments.findIndex((f2) => isSameFile(f2, f))
        return foundAttachment === -1
      })
      const updateAttachments = [...attachments, ...filesToAdd]
      this.filesToAdd = filesToAdd
      const currentNote = {
        ...this.source,
        isEdited: true,
        isDeleted: false,
        updatedAttachments: updateAttachments,
      }
      this.updateSingleAbsenceNote(currentNote)
    },
    setDefaultTop() {
      this.containerParentTopPosition =
        this.$parent.$parent.$el.getBoundingClientRect().top
      this.itemChildTopPosition =
        this.$refs.noteItem.getBoundingClientRect().top
      this.updateTooltipPosition(
        this.containerParentTopPosition,
        this.itemChildTopPosition
      )
      this.$parent.$parent.$el.addEventListener(
        'scroll',
        this.handleParentScroll
      )
    },
    handleParentScroll() {
      this.itemChildTopPosition =
        this.$refs.noteItem.getBoundingClientRect().top
      this.updateTooltipPosition(
        this.containerParentTopPosition,
        this.itemChildTopPosition
      )
    },
    updateTooltipPosition(parentTopPosition, childTopPosition) {
      this.tooltipPosition =
        childTopPosition <= parentTopPosition ? 'is-bottom' : 'is-right'
    },
  },
}
</script>
