import { computed, Ref, ComputedRef } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/utils/vendors/i18n/index'
import { Absence } from '../../models/absence.model'
import useDeviceSize from '@/utils/composables/use-ui-size'
import { MODULE_NAME } from '../../absence.module'
import { formatDateFilter, formatTimeCell } from '@/utils/date-time.utils'
import { ABSENCE_ACTIONS, ABSENCE_LIST_PREFERENCE_KEY } from '../../constants'
import { BaseTableColumn } from '@/app/util-modules/ui/table/BaseTable.types'

import TableHeaderCell from '@/app/util-modules/ui/table/BaseTableHeaderCell.vue'
import AbsenceCellEmployee from '../../components/absence-table-cell/AbsenceCellEmployee.vue'
import AbsenceCellActions from '../../components/absence-table-cell/AbsenceCellActions.vue'
import AbsenceCellConfirmedBy from '../../components/absence-table-cell/AbsenceCellConfirmedBy.vue'
import AbsenceCellInfo from '../../components/absence-table-cell/AbsenceCellInfo.vue'
import AbsenceCellStatus from '../../components/absence-table-cell/AbsenceCellConfirmation.vue'
import AbsenceCellDate from '../../components/absence-table-cell/AbsenceCellDate.vue'

import {
  USER_PUBLIC_GETTER_PREFERENCE,
  USER_GETTER_USER,
} from '@/app/core/user/store/getters/user.getters.names'
import { transformColumnObjectToArray } from '@/app/modules/absence/store/actions/absence.action.utils'

import { AbsenceMeta } from '@/app/modules/absence/store/absence.state'
import { DATE_FORMAT } from '@/constants'

const HEADER_CLASS =
  'md:uppercase font-semibold md:font-sm text-coal-550 flex-1 md:mr-4 flex-wrap truncate'
const CELL_CLASS = 'truncate font-medium md:text-base text-lg flex-1 md:mr-4'

interface ActionHandler {
  handler: (actionName: string, params?: any) => void
}

// TODO uncomment when checkbox functionality is needed
// const checkbox = () => ({
//   header: {
//     element: BaseCheckbox,
//     props: {
//       value: () => false,
//       size: () => 'is-small',
//       indeterminate: () => false,
//       stopPropagation: true,
//       dataIdAttr: 'absence_table_select_all',
//     },
//     events: {
//       input: () => {}, // eslint-disable-line no-empty-function
//     },
//   },
//   cell: {
//     element: BaseCheckbox,
//     props: {
//       value: () => false,
//       size: () => 'is-small',
//       stopPropagation: true,
//       dataIdAttr: () => 'absence_table_select_single_' + Math.random(),
//     },
//   },
//   mobile: true,
//   tablet: true,
//   footerClass: 'w-4 mr-4',
// })

const employee = (
  colCellWidth: string,
  selectedColumns: string[],
  absenceTotals: AbsenceMeta,
  isCurrentUserAdmin: boolean
) => ({
  width: colCellWidth,
  smallDevice: isCurrentUserAdmin,
  mediumDevice: true,
  header: {
    element: TableHeaderCell,
    class: `${HEADER_CLASS} truncate md:flex-1.5 flex-1`,
    props: {
      value: () => i18n.t('absence.index.employee_name'),
      sorting: () => store.state[MODULE_NAME].sorting.employee,
    },
  },
  cell: {
    element: AbsenceCellEmployee,
    class: `${CELL_CLASS} md:flex-1.5 flex-1 font-bold`,
    props: {
      user: (row: Absence) => row.user,
    },
  },
  hidden: !selectedColumns.includes('employee'),
  footer: () =>
    i18n.t('absence.index.footer.total_absence', {
      totalAbsence: absenceTotals.total_count,
    }),

  footerTextClass: 'text-base text-lg',
})

const type = (
  colCellWidth: string,
  selectedColumns?: string[],
  isCurrentUserAdmin?: boolean
) => ({
  width: colCellWidth,
  smallDevice: !isCurrentUserAdmin,
  mediumDevice: true,
  header: {
    element: TableHeaderCell,
    class: HEADER_CLASS,
    props: {
      value: () => i18n.t('absence.index.absence_type'),
      sorting: () => store.state[MODULE_NAME].sorting.type,
    },
  },
  cell: {
    class: `${CELL_CLASS} capitalize`,
    props: {
      value: (row: Absence) => row.absence_type?.title,
    },
  },
  hidden: (selectedColumns && !selectedColumns.includes('type')) || false,
})

const getAbsenceStartDate = (row: Absence) =>
  formatDateFilter(new Date(row.starts_at), DATE_FORMAT)

const getAbsenceEndDate = (row: Absence) =>
  formatDateFilter(new Date(row.ends_at), DATE_FORMAT)

const dates = (colCellWidth: string, selectedColumns?: string[]) => ({
  width: colCellWidth,
  smallDevice: true,
  header: {
    element: TableHeaderCell,
    class: `${HEADER_CLASS} md:flex-3 flex-1`,
    props: {
      value: () => i18n.t('absence.index.dates'),
      sorting: () => store.state[MODULE_NAME].sorting.dates,
    },
  },
  cell: {
    class: `${CELL_CLASS} md:flex-3 flex-1`,
    element: AbsenceCellDate,
    props: {
      startDate: (row: Absence) => getAbsenceStartDate(row),
      endDate: (row: Absence) => getAbsenceEndDate(row),
    },
  },
  hidden: selectedColumns && !selectedColumns.includes('dates'),
})

const time = (colCellWidth: string, selectedColumns?: string[]) => ({
  width: colCellWidth,
  header: {
    element: TableHeaderCell,
    class: `${HEADER_CLASS} flex-1.5`,
    props: {
      value: () => i18n.t('absence.index.table_columns_preferences.time'),
    },
  },
  cell: {
    class: `${CELL_CLASS} flex-1.5`,
    props: {
      value: (row: Absence) =>
        !row.full_day
          ? formatTimeCell({
              starts_at: row.starts_at,
              ends_at: row.ends_at,
            })
          : '',
    },
  },
  hidden: selectedColumns && !selectedColumns.includes('time'),
})

const days = (
  absenceTotals: AbsenceMeta,
  colCellWidth: string,
  selectedColumns?: string[]
) => ({
  width: colCellWidth,
  smallDevice: false,
  mediumDevice: true,
  header: {
    element: TableHeaderCell,
    class: `${HEADER_CLASS} justify-center md:justify-end`,
    props: {
      value: () => i18n.t('absence.index.absence_days'),
      sorting: () => store.state[MODULE_NAME].sorting.days,
    },
  },
  cell: {
    class: `${CELL_CLASS} text-center md:text-right`,
    props: {
      value: (row: Absence) => row.days?.toString(),
    },
  },
  hidden: (selectedColumns && !selectedColumns.includes('days')) || false,
  footer: () => absenceTotals.total_days.toFixed(1),
  footerTextClass: 'text-base text-lg md:text-right',
})

const hours = (
  absenceTotals: AbsenceMeta,
  colCellWidth: string,
  selectedColumns?: string[]
) => ({
  width: colCellWidth,
  smallDevice: false,
  mediumDevice: true,
  header: {
    element: TableHeaderCell,
    class: `${HEADER_CLASS} justify-end`,
    props: {
      value: () => i18n.t('absence.index.absence_hours'),
    },
  },
  cell: {
    class: `${CELL_CLASS} text-right`,
    props: {
      value: (row: Absence) => row.hours?.toString(),
    },
  },
  hidden: (selectedColumns && !selectedColumns.includes('hours')) || false,
  footer: () => absenceTotals.total_hours.toFixed(1),
  footerTextClass: 'text-base text-lg',
})

// const conflicts = (
//   colCellWidth: string,
//   selectedColumns: string[]
// ) => ({
//   width: colCellWidth,
//   mediumDevice: true,
//   header: {
//     element: TableHeaderCell,
//     class: HEADER_CLASS,
//     props: {
//       value: () => i18n.t('absence.index.conflicts'),
//     },
//     events: {},
//   },
//   cell: {
//     class: CELL_CLASS,
//     props: {
//       value: (row: Absence) => row.conflicts,
//     },
//   },
// })

const confirmBy = (colCellWidth: string, selectedColumns?: string[]) => ({
  width: colCellWidth,
  mediumDevice: true,
  header: {
    element: TableHeaderCell,
    class: `${HEADER_CLASS} truncate flex-1.5 md:justify-end`,
    props: {
      value: () => i18n.t('absence.index.confirm_by'),
      truncateValue: true,
    },
  },
  cell: {
    class: `${CELL_CLASS} flex-1.5`,
    element: AbsenceCellConfirmedBy,
    props: {
      confirmedBy: (row: Absence) => {
        if (row.confirmed === null) {
          return ''
        }
        if (row.confirmed || !row.confirmed) {
          return row.confirmed_by_user.username
        }
      },
    },
  },
  hidden: selectedColumns && !selectedColumns.includes('confirmed_by'),
})

const infoColumn = (action: ActionHandler, selectedColumns?: string[]) => ({
  mediumDevice: true,
  header: {
    element: TableHeaderCell,
    class: `w-20 justify-end md:mr-4`,
    props: {
      value: () => i18n.t('absence.index.info'),
    },
  },
  cell: {
    class: `w-20 md:mr-4`,
    element: AbsenceCellInfo,
    props: {
      absence: (row: Absence) => row,
    },
    events: {
      'attachments-hover': (row: Absence) => {
        action.handler('attachments-hover', { row })
      },
      'attachments-action-click': (row: Absence) => {
        action.handler('attachments-action-click', { row })
      },
      'notes-hover': (row: Absence) => {
        action.handler('notes-hover', { row })
      },
      'notes-action-click': (row: Absence) => {
        action.handler('notes-action-click', { row })
      },
    },
  },
  hidden: (selectedColumns && !selectedColumns.includes('info')) || false,
})

const status = (colCellWidth: string, selectedColumns?: string[]) => ({
  width: colCellWidth,
  smallDevice: true,
  mediumDevice: true,
  header: {
    element: TableHeaderCell,
    class: `${HEADER_CLASS} md:flex-1.5 flex-1 justify-end md:justify-start truncate`,
    props: {
      value: () => i18n.t('absence.index.confirmation'),
      truncateValue: true,
    },
  },
  cell: {
    class: `${CELL_CLASS} md:flex-1.5 flex-1 text-left`,
    element: AbsenceCellStatus,
    props: {
      confirmed: (row: Absence) => row.confirmed,
    },
  },
  hidden:
    (selectedColumns && !selectedColumns.includes('confirmation')) || false,
})

const actions = (action: ActionHandler, isCurrentUserAdmin: boolean) => ({
  header: {
    element: TableHeaderCell,
    class: `w-28 justify-end`,
    props: {
      value: () => i18n.t('absence.index.actions.title'),
    },
    events: {},
  },
  cell: {
    element: AbsenceCellActions,
    class: `mr-0 md:mr-0 w-28`,
    props: {
      row: (row: Absence) => row,
      isAdmin: isCurrentUserAdmin,
    },
    events: {
      'approve-action-click': (row: Absence) => {
        action.handler(ABSENCE_ACTIONS.APPROVE, { id: row.id })
      },
      'reject-action-click': (row: Absence) => {
        action.handler(ABSENCE_ACTIONS.REJECT, { id: row.id })
      },
      'reset-action-click': (row: Absence) => {
        action.handler(ABSENCE_ACTIONS.RESET, { id: row.id })
      },
      'edit-action-click': (row: Absence) => {
        action.handler(ABSENCE_ACTIONS.EDIT, { id: row.id })
      },
    },
  },
  smallDevice: false,
  mediumDevice: true,
})

const responsiveCellWidth = (
  $isSmallDevice: ComputedRef<boolean>,
  $isMediumDevice: ComputedRef<boolean>,
  isCurrentUserAdmin: boolean
) => {
  if (!isCurrentUserAdmin) {
    return ''
  }

  if ($isSmallDevice.value) {
    return '33%'
  }

  if ($isMediumDevice.value) {
    return ''
  }

  return ''
}

const getAllColumns = (
  columnCellWidth: string,
  action: ActionHandler,
  selectedColumns: string[],
  absenceTotals: AbsenceMeta,
  isCurrentUserAdmin: boolean
) => {
  const commonArgs: [string, string[]] = [columnCellWidth, selectedColumns]

  const commonColumns = [
    time(...commonArgs),
    days(absenceTotals, ...commonArgs),
    hours(absenceTotals, ...commonArgs),
    // conflicts(...commonArgs),
    confirmBy(...commonArgs),
    infoColumn(action, selectedColumns),
    status(...commonArgs),
    actions(action, true),
  ]

  const generalCommonColumns = [
    dates(...commonArgs),
    type(...commonArgs, isCurrentUserAdmin),
    ...commonColumns,
  ]

  let columnsToShow = []

  if (
    isCurrentUserAdmin ||
    store.getters[USER_GETTER_USER].attributes.can_view_absences_of_others
  ) {
    columnsToShow = [
      employee(...commonArgs, absenceTotals, isCurrentUserAdmin),
      ...generalCommonColumns,
    ]
  } else {
    columnsToShow = generalCommonColumns
  }
  return columnsToShow
}

const getFilteredColumns = (
  allColumns: BaseTableColumn<Absence>[],
  $isSmallDevice: ComputedRef<boolean>,
  $isMediumDevice: ComputedRef<boolean>
) => {
  if ($isSmallDevice.value) {
    return allColumns.filter((column) => column.smallDevice && !column.hidden)
  }

  if ($isMediumDevice.value) {
    return allColumns.filter((column) => column.mediumDevice && !column.hidden)
  }

  return allColumns.filter((column) => !column.hidden)
}

const getColumns = (
  $isSmallDevice: ComputedRef<boolean>,
  $isMediumDevice: ComputedRef<boolean>,
  action: ActionHandler,
  selectedColumns: ComputedRef<string[]>,
  isCurrentUserAdmin: boolean,
  absenceTotals: ComputedRef<AbsenceMeta>
): Ref<BaseTableColumn<Absence>[]> =>
  computed(() => {
    const columnCellWidth = responsiveCellWidth(
      $isSmallDevice,
      $isMediumDevice,
      isCurrentUserAdmin
    )

    const allColumns: BaseTableColumn<Absence>[] = getAllColumns(
      columnCellWidth,
      action,
      selectedColumns.value,
      absenceTotals.value,
      isCurrentUserAdmin
    )

    return getFilteredColumns(allColumns, $isSmallDevice, $isMediumDevice)
  })

export default function useTableColumns(isCurrentUserAdmin: boolean) {
  const { $isSmallDevice, $isMediumDevice } = useDeviceSize()

  const action: ActionHandler = {
    handler: () => {},
  }

  const selectedColumns = computed(() => {
    if (
      store.getters[USER_PUBLIC_GETTER_PREFERENCE](ABSENCE_LIST_PREFERENCE_KEY)
        ?.value
    )
      return transformColumnObjectToArray(
        store.getters[USER_PUBLIC_GETTER_PREFERENCE](
          ABSENCE_LIST_PREFERENCE_KEY
        ).value
      )

    return store.state[MODULE_NAME].displayedColumns
  })

  const absenceTotals = computed(() => store.state[MODULE_NAME].currentMeta)

  const computedColumns = getColumns(
    $isSmallDevice,
    $isMediumDevice,
    action,
    selectedColumns,
    isCurrentUserAdmin,
    absenceTotals
  )

  return {
    columns: computedColumns,
    onAction: (callback: ActionHandler['handler']) =>
      (action.handler = callback),
  }
}
