<template>
  <div class="relative w-full">
    <base-textarea
      v-model="passedValue.currentNoteContent"
      :attachments="currentNoteAttachmentsToDisplay"
      :disabled="false"
      :actions="true"
      :show-action-submit="false"
      :show-action-attachment="true"
      :show-attachment="true"
      :no-borders="false"
      class="absence-form-input-note-disabled"
      :file-upload-icon-tooltip-label="
        $t('absence.add_form.field_label_add_note_file_upload_icon_tooltip')
      "
      file-upload-icon-tooltip-position="is-right"
      :show-action-cancel-save="true"
      @cancel="$emit('cancel')"
      @submit="$emit('submit')"
      @file-input="$emit('attachment-add', $event)"
      @attachment-delete="$emit('attachment-delete', $event)"
    />
  </div>
</template>

<script>
import BaseTextarea from '@/app/util-modules/ui/textarea/BaseTextarea.vue'

export default {
  name: 'AbsenceEditFormNotesItemEdit',
  components: {
    BaseTextarea,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    currentNoteAttachmentsToDisplay() {
      if (this.passedValue.currentNoteAttachments.length) {
        let attachments = [...this.passedValue.currentNoteAttachments]
        attachments = attachments.filter((attachment) => !attachment.isDeleted)
        attachments = attachments.map((attachment) => ({
          name: attachment.file_name,
          id: attachment.id,
        }))
        return attachments
      }
      return []
    },
  },
}
</script>
