<template>
  <div>
    <div class="h-full flex items-center justify-end">
      <base-group-selection-toolbar-action-item
        v-if="row.permissions.confirm"
        :tooltip="$t('absence.index.actions.approve_tooltip')"
        tooltip-position="is-left"
        tooltip-size="is-small"
      >
        <base-group-selection-toolbar-action-button
          icon="tick-confirm"
          type="is-filled-grey"
          role="button"
          data-id="absences.index.actions.approve_button_text"
          :aria-label="$t('absence.index.actions.approve_tooltip')"
          @click="$emit('approve-action-click', row)"
        />
      </base-group-selection-toolbar-action-item>

      <base-group-selection-toolbar-action-item
        v-if="row.permissions.reject"
        :tooltip="$t('absence.index.actions.reject_tooltip')"
        tooltip-size="is-small"
      >
        <base-group-selection-toolbar-action-button
          icon="cross-close"
          type="is-filled-grey"
          role="button"
          data-id="absences.index.actions.reject_button_text"
          :aria-label="$t('absence.index.actions.reject_tooltip')"
          @click="$emit('reject-action-click', row)"
        />
      </base-group-selection-toolbar-action-item>

      <base-group-selection-toolbar-action-item
        v-if="canReset"
        :tooltip="$t('absence.index.actions.reset_tooltip')"
        tooltip-size="is-small"
      >
        <base-group-selection-toolbar-action-button
          icon="revert-arrow-reset"
          type="is-filled-grey"
          role="button"
          data-id="absences.index.actions.reset_button_text"
          :aria-label="$t('absence.index.actions.reset_tooltip')"
          @click="$emit('reset-action-click', row)"
        />
      </base-group-selection-toolbar-action-item>

      <base-group-selection-toolbar-action-item
        :tooltip="$t('absence.index.actions.edit_tooltip')"
        tooltip-size="is-small"
      >
        <base-group-selection-toolbar-action-button
          icon="settings"
          type="is-filled-grey"
          role="button"
          data-id="absences.index.actions.edit_button_text"
          :aria-label="$t('absence.index.actions.edit_tooltip')"
          @click="$emit('edit-action-click', row)"
        />
      </base-group-selection-toolbar-action-item>
    </div>
  </div>
</template>

<script>
const BaseGroupSelectionToolbarActionItem = () =>
  import(
    '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionItem'
  )
const BaseGroupSelectionToolbarActionButton = () =>
  import(
    '@/app/util-modules/ui/group-selection-toolbar/BaseGroupSelectionToolbarActionButton'
  )

export default {
  name: 'AbsenceCellActions',
  components: {
    BaseGroupSelectionToolbarActionItem,
    BaseGroupSelectionToolbarActionButton,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canReset() {
      return this.row.confirmed !== null && this.row.permissions.reset
        ? true
        : false
    },
  },
}
</script>
