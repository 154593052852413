<template>
  <base-tabs-header
    :tabs="tabs"
    :active-tab="activeTab"
    icon="absences/absences-24"
    @change="changeTab($event)"
  />
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { MODULE_NAME as ABSENCE_MODULE_NAME } from '../../absence.module'
import BaseTabsHeader from '@/app/util-modules/ui/tabs/BaseTabsHeader.vue'

import { ABSENCE_MUTATION_SET_CURRENT_TAB } from '../../store/mutations/absence.mutations.names'
import { ABSENCE_STATUS } from '../../constants'

export default {
  name: 'AbsenceTabsHeader',
  components: {
    BaseTabsHeader,
  },
  computed: {
    ...mapState(ABSENCE_MODULE_NAME, {
      meta: (state) => state.meta,
      activeTab: (state) => state.currentTab,
    }),
    tabs() {
      return [
        {
          id: ABSENCE_STATUS.ALL,
          label: this.$t('absence.tabs.all'),
          labelTrigger: this.$t('absence.tabs.all_absences'),
          dataIdAttr: 'absence.tabs.all',
          count: `${this.meta.total_count}`,
        },
        {
          id: ABSENCE_STATUS.PENDING,
          label: this.$t('absence.tabs.unconfirmed'),
          labelTrigger: this.$t('absence.tabs.pending_absences'),
          dataIdAttr: 'absence.tabs.pending',
          count: `${this.meta.pending}`,
          badgeType: 'is-warning',
        },
        {
          id: ABSENCE_STATUS.CONFIRMED,
          label: this.$t('absence.tabs.confirmed'),
          labelTrigger: this.$t('absence.tabs.confirmed_absences'),
          dataIdAttr: 'absence.tabs.confirmed',
          count: `${this.meta.confirmed}`,
          badgeType: 'is-success',
        },
        {
          id: ABSENCE_STATUS.REJECTED,
          label: this.$t('absence.tabs.rejected'),
          labelTrigger: this.$t('absence.tabs.rejected_absences'),
          dataIdAttr: 'absence.tabs.rejected',
          count: `${this.meta.rejected}`,
          badgeType: 'is-danger',
        },
      ]
    },
  },
  methods: {
    ...mapMutations({
      switchTab: ABSENCE_MUTATION_SET_CURRENT_TAB,
    }),
    changeTab(tab) {
      this.switchTab(tab)
    },
  },
}
</script>
