import {
  setRecords,
  appendRecords,
  replaceRecord,
  clearRecords,
  addIncluded,
  deleteRecords,
} from '@/utils/jsonapi/records.mutations'
import { attachRelationships } from '@/utils/jsonapi/relationships.mutations'
import { shortname } from '@/utils/store'
import { AbsenceState, AbsenceMeta, Filter } from '../absence.state'
import * as mutationNamesObj from './absence.mutations.names'
import { BaseRequestSorting } from '@/utils/api/base-request'

import { BaseResponseMeta } from '@/utils/api/base-response'
import {
  VacationSummary,
  ABSENCE_NOTE_TYPE,
  Note,
  AbsenceCalculation,
} from '../../models/absence.model'

import { ABSENCE_STATUS } from '@/app/modules/absence/constants'

const mutationNames = shortname(mutationNamesObj)

export default {
  [mutationNames.ABSENCE_MUTATION_SET_RECORDS]: setRecords,

  [mutationNames.ABSENCE_MUTATION_APPEND_RECORDS]: appendRecords,

  [mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS]: replaceRecord,

  [mutationNames.ABSENCE_MUTATION_CLEAR_RECORDS]: clearRecords,

  [mutationNames.ABSENCE_MUTATION_ADD_INCLUDED]: addIncluded,

  [mutationNames.ABSENCE_MUTATION_DELETE_RECORDS]: deleteRecords,

  [mutationNames.ABSENCE_MUTATION_ATTACH_RELATIONSHIPS]: attachRelationships,

  [mutationNames.ABSENCE_MUTATION_SET_META]: (
    state: AbsenceState,
    meta: AbsenceMeta
  ) => {
    switch (state.currentTab) {
      case ABSENCE_STATUS.PENDING:
        state.meta = { ...state.meta, pending: meta.pending }
        break
      case ABSENCE_STATUS.CONFIRMED:
        state.meta = { ...state.meta, confirmed: meta.confirmed }
        break
      case ABSENCE_STATUS.REJECTED:
        state.meta = { ...state.meta, rejected: meta.rejected }
        break
      default:
        state.meta = { ...meta }
        break
    }

    state.currentMeta = { ...meta }
  },
  [mutationNames.ABSENCE_MUTATION_SET_META_ACTION_STATUS]: (
    state: AbsenceState,
    meta: AbsenceMeta
  ) => {
    state.meta = { ...meta }
  },

  [mutationNames.ABSENCE_MUTATION_INCREMENT_PAGE]: (state: AbsenceState) => {
    state.pagination.page++
  },

  [mutationNames.ABSENCE_MUTATION_RESET_PAGE]: (state: AbsenceState) => {
    state.pagination.page = 0
  },

  [mutationNames.ABSENCE_MUTATION_SET_SORTING_FIELD]: (
    state: AbsenceState,
    sorting: BaseRequestSorting
  ) => {
    state.sorting = { ...state.sorting, ...sorting }
  },

  [mutationNames.ABSENCE_MUTATION_SET_ABSENCE_TYPES]: (
    state: AbsenceState,
    absenceTypes: []
  ) => {
    state.absenceTypes = absenceTypes
  },
  [mutationNames.ABSENCE_MUTATION_SET_VACATION_SUMMARY]: (
    state: AbsenceState,
    vacationSummary: VacationSummary
  ) => {
    state.vacationSummary = vacationSummary
  },
  [mutationNames.ABSENCE_MUTATION_SET_CURRENT_TAB]: (
    state: AbsenceState,
    currentTab: string
  ) => {
    state.currentTab = currentTab
  },
  [mutationNames.ABSENCE_MUTATION_SINGLE_NOTE_RECORD]: (
    state: AbsenceState,
    note: Note
  ) => {
    const notes = state.records[ABSENCE_NOTE_TYPE]
    const foundNote = notes.find((n) => n.id === note.id)
    if (foundNote) {
      foundNote.content = note.content
      foundNote.isEdited = note.isEdited
      foundNote.isDeleted = note.isDeleted

      if (note.updatedAttachments && note.updatedAttachments.length) {
        foundNote.updatedAttachments = [...note.updatedAttachments]
      }
    }
    state.records[ABSENCE_NOTE_TYPE] = [...notes]
  },
  [mutationNames.ABSENCE_MUTATION_SET_NOTE_META]: (
    state: AbsenceState,
    meta: BaseResponseMeta
  ) => {
    state.noteCurrentMeta = meta
  },
  [mutationNames.ABSENCE_NOTE_MUTATION_INCREMENT_PAGE_NOTE]: (
    state: AbsenceState
  ) => {
    state.notePagination.page++
  },

  [mutationNames.ABSENCE_MUTATION_SET_CALCULATION]: (
    state: AbsenceState,
    calculation: AbsenceCalculation
  ) => {
    state.calculation = calculation
  },

  [mutationNames.ABSENCE_MUTATION_SET_DATE_RANGE]: (
    state: AbsenceState,
    dateRange: Date[]
  ) => {
    state.absenceFilterDateRange = dateRange
  },

  [mutationNames.ABSENCE_MUTATION_SET_DATE_RANGE_CALENDAR]: (
    state: AbsenceState,
    dateRange: Date[]
  ) => {
    state.absenceCalendarFilterDateRange = dateRange
  },

  [mutationNames.ABSENCE_MUTATION_SET_ABSENCE_CREATION_USERS]: (
    state: AbsenceState,
    users: []
  ) => {
    state.creationUsers = users
  },
  [mutationNames.ABSENCE_MUTATION_SET_ABSENCE_OVERLAP]: (
    state: AbsenceState,
    overlapAbsences: []
  ) => {
    state.overlapAbsences = overlapAbsences
  },
  [mutationNames.ABSENCE_MUTATION_ADD_FILTERBAR_FILTER]: (
    state: AbsenceState,
    filter: Filter
  ) => {
    state.filters = {
      ...state.filters,
      [filter.field]: { ...filter },
    }
  },
  [mutationNames.ABSENCE_MUTATION_REMOVE_FILTERBAR_FILTER]: (
    state: AbsenceState,
    filterKey: string
  ) => {
    const { [filterKey]: removed, ...rest }: any = state.filters
    state.filters = rest
  },
}
