import { MODULE_NAME } from '../../absence.module'

export const ABSENCE_MUTATION_SET_RECORDS: string = `${MODULE_NAME}/MUTATION_SET_RECORDS`
export const ABSENCE_MUTATION_APPEND_RECORDS: string = `${MODULE_NAME}/MUTATION_APPEND_RECORDS`
export const ABSENCE_MUTATION_REPLACE_RECORDS: string = `${MODULE_NAME}/MUTATION_REPLACE_RECORDS`
export const ABSENCE_MUTATION_CLEAR_RECORDS: string = `${MODULE_NAME}/MUTATION_CLEAR_RECORDS`
export const ABSENCE_MUTATION_ADD_INCLUDED: string = `${MODULE_NAME}/MUTATION_ADD_INCLUDED`
export const ABSENCE_MUTATION_SET_META: string = `${MODULE_NAME}/MUTATION_SET_META`
export const ABSENCE_MUTATION_INCREMENT_PAGE: string = `${MODULE_NAME}/MUTATION_INCREMENT_PAGE`
export const ABSENCE_MUTATION_RESET_PAGE: string = `${MODULE_NAME}/MUTATION_RESET_PAGE`
export const ABSENCE_MUTATION_SET_SORTING_FIELD: string = `${MODULE_NAME}/MUTATION_SET_SORTING_FIELD`
export const ABSENCE_MUTATION_SET_ABSENCE_TYPES: string = `${MODULE_NAME}/MUTATION_SET_ABSENCE_TYPES`
export const ABSENCE_MUTATION_SET_VACATION_SUMMARY: string = `${MODULE_NAME}/MUTATION_SET_VACATION_SUMMARY`
export const ABSENCE_MUTATION_SET_CURRENT_TAB = `${MODULE_NAME}/MUTATION_SET_CURRENT_TAB`
export const ABSENCE_MUTATION_SET_META_ACTION_STATUS = `${MODULE_NAME}/MUTATION_SET_META_ACTION_STATUS`
export const ABSENCE_MUTATION_DELETE_RECORDS = `${MODULE_NAME}/MUTATION_DELETE_RECORDS`
export const ABSENCE_MUTATION_SINGLE_NOTE_RECORD = `${MODULE_NAME}/MUTATION_SINGLE_NOTE_RECORD`
export const ABSENCE_MUTATION_SET_NOTE_META = `${MODULE_NAME}/MUTATION_SET_NOTE_META`
export const ABSENCE_NOTE_MUTATION_INCREMENT_PAGE_NOTE = `${MODULE_NAME}/MUTATION_INCREMENT_PAGE_NOTE`
export const ABSENCE_MUTATION_SET_CALCULATION = `${MODULE_NAME}/MUTATION_SET_CALCULATION`
export const ABSENCE_MUTATION_SET_DATE_RANGE = `${MODULE_NAME}/MUTATION_SET_DATE_RANGE`
export const ABSENCE_MUTATION_SET_DATE_RANGE_CALENDAR = `${MODULE_NAME}/MUTATION_SET_DATE_RANGE_CALENDAR`
export const ABSENCE_MUTATION_ATTACH_RELATIONSHIPS = `${MODULE_NAME}/MUTATION_ATTACH_RELATIONSHIPS`
export const ABSENCE_MUTATION_SET_ABSENCE_CREATION_USERS = `${MODULE_NAME}/MUTATION_SET_ABSENCE_CREATION_USERS`
export const ABSENCE_MUTATION_SET_ABSENCE_OVERLAP = `${MODULE_NAME}/MUTATION_SET_ABSENCE_OVERLAP`
export const ABSENCE_MUTATION_ADD_FILTERBAR_FILTER = `${MODULE_NAME}/MUTATION_ADD_FILTERBAR_FILTER`
export const ABSENCE_MUTATION_REMOVE_FILTERBAR_FILTER = `${MODULE_NAME}/MUTATION_REMOVE_FILTERBAR_FILTER`
