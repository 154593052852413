<template>
  <div>
    <absence-date-overlap-item
      v-for="absence in absences"
      :key="absence.id"
      :absence="absence.attributes"
      :show-time-col="showTimeCol"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import AbsenceDateOverlapItem from './AbsenceDateOverlapItem.vue'

export default defineComponent({
  name: 'AbsenceDateOverlapList',
  components: {
    AbsenceDateOverlapItem,
  },
  props: {
    absences: {
      type: Array,
      required: true,
    },
    showTimeCol: {
      type: Boolean,
      required: true,
    },
  },
})
</script>
