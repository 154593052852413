

















import { defineComponent, PropType, computed } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import i18n from '@/utils/vendors/i18n/index'

import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import AbsenceRowBottomSheetActions from './AbsenceRowBottomSheetActions.vue'

import { Absence } from '@/app/modules/absence/models/absence.model'

export default defineComponent({
  name: 'AbsenceRowBottomSheet',
  components: {
    BottomSheet,
    AbsenceRowBottomSheetActions,
  },
  props: {
    absence: {
      type: Object as PropType<Absence>,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    approveActionStatus: {
      type: Object,
      default: () => ({}),
    },
    rejectActionStatus: {
      type: Object,
      default: () => ({}),
    },
    resetActionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const closeButton: Record<string, TranslateResult> = {
      title: i18n.t('employee.index.bottom_sheet.close'),
      class: 'text-coal-550',
      event: 'cancel-click',
    }

    const absenceRowActionStatuses = computed(() => {
      const absenceId = props.absence.id

      const statuses = {
        approveAbsence: {
          isLoading: false,
        },
        rejectAbsence: {
          isLoading: false,
        },
        resetAbsence: {
          isLoading: false,
        },
      }

      if (!absenceId) {
        return statuses
      }

      if (
        props.approveActionStatus.instances &&
        props.approveActionStatus.instances[absenceId]
      ) {
        statuses.approveAbsence = props.approveActionStatus.instances[absenceId]
      }

      if (
        props.rejectActionStatus.instances &&
        props.rejectActionStatus.instances[absenceId]
      ) {
        statuses.rejectAbsence = props.rejectActionStatus.instances[absenceId]
      }

      if (
        props.resetActionStatus.instances &&
        props.resetActionStatus.instances[absenceId]
      ) {
        statuses.resetAbsence = props.resetActionStatus.instances[absenceId]
      }

      return statuses
    })

    return {
      closeButton,
      absenceRowActionStatuses,
    }
  },
})
