import BaseRequest from '@/utils/api/base-request'
import {
  ABSENCE_ENDPOINT_ADD_NOTE_TO_ABSENCE,
  ABSENCE_ENDPOINT_UPDATE_ABSENCE_NOTES,
  ABSENCE_ENDPOINT_DELETE_NOTE,
} from '../network/absence.endpoints'
import { addAttachments } from '@/utils/note-attachments'
import { Note } from '@/app/modules/absence/models/absence.model'
export const ABSENCE_NOTE_TYPE = 'note'

export interface NoteDeleteRequestParams {
  workspaceId: RecordId
  absenceId: RecordId
  noteId: RecordId
}

export class AbsenceAddNoteRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
    currentUserId,
    note,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    currentUserId: RecordId
    note: Note
  }) {
    super()
    super.method = 'post'
    super.url = ABSENCE_ENDPOINT_ADD_NOTE_TO_ABSENCE(workspaceId, absenceId)
    super.type = ABSENCE_NOTE_TYPE

    const data = new FormData()
    if (note.content) {
      data.append(`note[content]`, note.content)
    }
    data.append(`note[created_by]`, currentUserId)
    if (note?.attachments?.length) {
      addAttachments(data, note.attachments)
    }

    super.data = data
  }
}

export class AbsenceUpdateNoteRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
    currentUserId,
    note,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    currentUserId: RecordId
    note: Note
  }) {
    const noteId = note.id
    super()
    super.method = 'patch'
    super.url = ABSENCE_ENDPOINT_UPDATE_ABSENCE_NOTES(
      workspaceId,
      absenceId,
      noteId
    )
    super.attributes = {
      note,
    }

    const data = new FormData()
    if (note.content) {
      data.append(`note[content]`, note.content)
    }
    data.append(`note[created_by]`, currentUserId)
    addAttachments(data, note.updatedAttachments)

    super.data = data
  }
}

export class AbsenceDeleteNoteRequest extends BaseRequest {
  constructor({ workspaceId, absenceId, noteId }: NoteDeleteRequestParams) {
    super()
    super.url = ABSENCE_ENDPOINT_DELETE_NOTE(workspaceId, absenceId, noteId)
    super.method = 'delete'
    super.type = ABSENCE_NOTE_TYPE
  }
}
