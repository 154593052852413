<template>
  <div>
    <base-system-notes-toggler
      class="flex capitalize my-5 font-semibold"
      :data-id-prefix="`${dataIdPrefix}.absence_edit_form_notes`"
      :value="passedValue.showSystemNote"
      @toggle="passedValue.showSystemNote = $event"
    ></base-system-notes-toggler>
    <absence-edit-form-notes-list
      v-model="passedValue"
      :notes="notes"
      :are-notes-focused="areNotesFocused"
      :data-id-prefix="`${dataIdPrefix}.absence_edit_form_notes`"
      @is-editing-note="$emit('is-editing-note', $event)"
      @delete-note="$emit('delete-note')"
      @save-note="$emit('save-note')"
      @scroll-to-notes="$emit('scroll-to-notes', $event)"
      @edit-absence-more-attachments="
        $emit('edit-absence-more-attachments', $event)
      "
    />
  </div>
</template>

<script>
import BaseSystemNotesToggler from '@/app/util-modules/ui/system-notes-toggler/BaseSystemNotesToggler'
import AbsenceEditFormNotesList from './AbsenceEditFormNotesList'

export default {
  name: 'AbsenceEditFormNotes',
  components: {
    BaseSystemNotesToggler,
    AbsenceEditFormNotesList,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    notes: {
      type: Array,
      default: () => [],
    },
    areNotesFocused: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style scoped>
::v-deep
  .base-system-notes-toggler-container
  > .base-system-notes-toggler-label {
  @apply mr-1.5;
}
</style>
