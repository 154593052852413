<template>
  <router-link
    :to="{
      name: routerLinkToName,
      params: { userId: user.id },
    }"
    class="truncate font-medium md:text-base text-lg md:mr-4 md:flex-1.5 flex-1"
  >
    {{ user.username }}
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'
import { MODULE_NAME as EMPLOYEE_MODULE_NAME } from '@/app/modules/employee/employee.module'
import { MODULE_NAME as PROFILE_MODULE_NAME } from '@/app/modules/profile/profile.module'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

export default {
  name: 'AbsenceCellEmployee',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    routerLinkToName() {
      if (this.isAdmin) {
        return `${EMPLOYEE_MODULE_NAME}.details`
      }
      return `${PROFILE_MODULE_NAME}.details`
    },
  },
}
</script>
