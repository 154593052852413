export const ABSENCE_ENDPOINT_LIST_ABSENCES = (workspaceId) =>
  `/workspaces/${workspaceId}/absences`

export const ABSENCE_ENDPOINT_CREATE_ABSENCE = (workspaceId) =>
  `/workspaces/${workspaceId}/absences`

export const ABSENCE_ENDPOINT_BATCH_CREATE_ABSENCE = (workspaceId) =>
  `/workspaces/${workspaceId}/absences/batch_actions`

export const ABSENCE_ENDPOINT_VACATION_SUMMARY = (workspaceId, userId) =>
  `/workspaces/${workspaceId}/users/${userId}/vacations/summary`

export const ABSENCE_ENDPOINT_APPROVE_ABSENCE = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}/absences_confirmations`

export const ABSENCE_ENDPOINT_REJECT_ABSENCE = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}/absences_rejections`

export const ABSENCE_ENDPOINT_RESET_ABSENCE = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}/absences_resettings`

export const ABSENCE_ENDPOINT_DELETE_ABSENCE = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}`

export const ABSENCE_ENDPOINT_UPDATE_ABSENCE = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}`

export const ABSENCE_ENDPOINT_UPDATE_ABSENCE_FOLLOWERS = (
  workspaceId,
  absenceId
) => `/workspaces/${workspaceId}/absences/${absenceId}/followers`

export const ABSENCE_ENDPOINT_DELETE_ABSENCE_FOLLOWER = (
  workspaceId,
  absenceId,
  followerId
) => `/workspaces/${workspaceId}/absences/${absenceId}/followers/${followerId}`

export const ABSENCE_ENDPOINT_UPDATE_ABSENCE_NOTES = (
  workspaceId,
  absenceId,
  noteId
) => `/workspaces/${workspaceId}/absences/${absenceId}/notes/${noteId}`

export const ABSENCE_ENDPOINT_LIST_NOTES = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}/notes`

export const ABSENCE_ENDPOINT_SHOW_ABSENCE = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}`

export const ABSENCE_ENDPOINT_ADD_NOTE_TO_ABSENCE = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}/notes`

export const ABSENCE_ENDPOINT_DELETE_NOTE = (workspaceId, absenceId, noteId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}/notes/${noteId}`

export const ABSENCE_ENDPOINT_LIST_ATTACHMENTS = (workspaceId, absenceId) =>
  `/workspaces/${workspaceId}/absences/${absenceId}/attachments`

export const ABSENCE_ENDPOINT_CALCULATOR = (workspaceId, calculatorParams) =>
  `/workspaces/${workspaceId}/absences_calculations?starts_at=${calculatorParams.starts_at}&ends_at=${calculatorParams.ends_at}&full_day=${calculatorParams.full_day}&user_id=${calculatorParams.user_id}&absence_type_id=${calculatorParams.absence_type_id}&time_zone=${calculatorParams.time_zone}`

export const ABSENCE_ENDPOINT_LIST_USERS_ABSENCE_TYPES = (workspaceId) =>
  `/workspaces/${workspaceId}/absence_types`

export const ABSENCE_ENDPOINT_LIST_CREATIONS_USER = (workspaceId, page) =>
  `/workspaces/${workspaceId}/absences/creation_users?sort=+username&page=${page}`

export const ABSENCE_ENDPOINT_SEARCH_CREATIONS_USER = (
  workspaceId,
  page,
  query
) =>
  `/workspaces/${workspaceId}/absences/creation_users?sort=+username&page=${page}&filter[username]=ct:${query}`

export const ABSENCE_CALENDAR_ENDPOINT_LIST_ABSENCES = (
  workspaceId,
  dateRange
) =>
  `/workspaces/${workspaceId}/absences?calendar=true&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}`

export const ABSENCE_ENDPOINT_CHECK_ABSENCE_OVERLAP = (workspaceId) =>
  `/workspaces/${workspaceId}/absences/check_absence_overlap`
