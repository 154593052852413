




































































import {
  defineComponent,
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
} from '@vue/composition-api'
import store from '@/store'

import {
  ABSENCE_ACTION_LIST_ABSENCES,
  ABSENCE_ACTION_LIST_APPEND_ABSENCES,
  ABSENCE_ACTION_ATTACHMENT_LIST,
  ABSENCE_ACTION_APPROVE_ABSENCE,
  ABSENCE_ACTION_REJECT_ABSENCE,
  ABSENCE_ACTION_RESET_ABSENCE,
  ABSENCE_NOTE_ACTION_LIST_NOTE,
  ABSENCE_ACTION_CLEAR_ABSENCES,
} from '../store/actions/absence.actions.names'
import { USER_PUBLIC_ACTION_GET_CURRENT_WORKSPACE } from '@/app/core/user/store/actions/user.actions.names'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

import { MODULE_NAME } from '../absence.module'
import { Absence, ABSENCE_TYPE } from '../models/absence.model'
import { ABSENCE_ACTIONS } from '../constants'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import BaseTable from '@/app/util-modules/ui/table/BaseTable.vue'
import AbsenceHeader from '../components/absence-header/AbsenceHeader.vue'
import AbsenceAdd from '../components/absence-add/AbsenceAdd.vue'
import AbsenceEdit from '../components/absence-edit/AbsenceEdit.vue'
import AbsenceRowBottomSheet from '../components/absence-bottom-sheet/AbsenceRowBottomSheet.vue'
import AbsenceActionBottomSheet from '../components/absence-bottom-sheet/AbsenceActionBottomSheet.vue'
import useTableColumns from './composables/use-table-columns'
import { USER_PUBLIC_ACTION_LIST_PREFERENCES } from '@/app/core/user/store/actions/user.actions.names'
import useTableScroll from '@/utils/composables/use-table-scroll'
import { ABSENCE_GETTER_ACTIVE_FILTERS } from '../store/getters/absence.getters.names'
import { ABSENCE_MUTATION_SET_DATE_RANGE } from '@/app/modules/absence/store/mutations/absence.mutations.names'
import { Attachment } from '@/utils/note-attachments'
import emitter from '@/app/util-modules/emitter'
import useDeviceSize from '@/utils/composables/use-ui-size'
import {
  getDateRangeNext30Days,
  getStartAndEndOfCurrentYear,
} from '@/utils/date-time.utils'

const AbsenceAttachments = () =>
  import('../components/absence-attachments/AbsenceAttachments.vue')

export default defineComponent({
  name: 'AbsencePage',
  components: {
    BaseTable,
    AbsenceHeader,
    AbsenceAdd,
    AbsenceEdit,
    AbsenceAttachments,
    AbsenceRowBottomSheet,
    AbsenceActionBottomSheet,
  },
  setup(_props, { refs }) {
    const ROW_HEIGHT: number = 24
    const ROW_NUMB: number = 45
    store.dispatch(USER_PUBLIC_ACTION_GET_CURRENT_WORKSPACE)

    const $actions = useActionTracker({
      listAbsences: ABSENCE_ACTION_LIST_ABSENCES,
      appendAbsences: ABSENCE_ACTION_LIST_APPEND_ABSENCES,
      fetchAttachments: ABSENCE_ACTION_ATTACHMENT_LIST,
      approveAbsence: {
        action: ABSENCE_ACTION_APPROVE_ABSENCE,
        trackByKey: 'absenceId',
      },
      rejectAbsence: {
        action: ABSENCE_ACTION_REJECT_ABSENCE,
        trackByKey: 'absenceId',
      },
      resetAbsence: {
        action: ABSENCE_ACTION_RESET_ABSENCE,
        trackByKey: 'absenceId',
      },
      userCurrentWorkspace: USER_PUBLIC_ACTION_GET_CURRENT_WORKSPACE,
    })
    const { $isSmallDevice } = useDeviceSize()
    const currentAbsenceAttachments = ref<Attachment[]>([])
    const loadingRowsIds = computed(() => {
      const approvingIds =
        Object.keys($actions.approveAbsence?.instances || {}).filter(
          (id) =>
            $actions.approveAbsence.instances[id] &&
            $actions.approveAbsence.instances[id].isLoading
        ) || []

      const rejectingIds =
        Object.keys($actions.rejectAbsence?.instances || {}).filter(
          (id) =>
            $actions.rejectAbsence.instances[id] &&
            $actions.rejectAbsence.instances[id].isLoading
        ) || []

      const resettingIds =
        Object.keys($actions.resetAbsence?.instances || {}).filter(
          (id) =>
            $actions.resetAbsence.instances[id] &&
            $actions.resetAbsence.instances[id].isLoading
        ) || []

      return [...approvingIds, ...rejectingIds, ...resettingIds]
    })

    const isCurrentUserAdmin = computed(
      () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )

    const absences = computed(
      () => store.state[MODULE_NAME].records[ABSENCE_TYPE]
    )

    const setDefaultAccountDateFilter = () => {
      if (!absences.value.length) {
        store.commit(
          ABSENCE_MUTATION_SET_DATE_RANGE,
          isCurrentUserAdmin.value
            ? getDateRangeNext30Days()
            : getStartAndEndOfCurrentYear()
        )
      }
    }

    setDefaultAccountDateFilter()

    const fetchAbsencesAction = () => {
      return store.dispatch(ABSENCE_ACTION_LIST_ABSENCES)
    }

    const refetchAbsences = () => {
      store.dispatch(ABSENCE_ACTION_CLEAR_ABSENCES)
      prevScrollOffset.value = 0
      window.scrollTo(0, 0)
      return fetchAbsencesAction()
    }

    const fetchMoreAbsencesAction = () =>
      store.dispatch(ABSENCE_ACTION_LIST_APPEND_ABSENCES)

    const fetchMoreAbsences = () => {
      if ($actions.listAbsences.isLoading || $actions.appendAbsences.isLoading)
        return
      fetchMoreAbsencesAction()
    }

    /* eslint-disable-next-line no-undef */
    const fetchAttachments = (absenceId: RecordId) =>
      store.dispatch(ABSENCE_ACTION_ATTACHMENT_LIST, { absenceId })

    const fetchNotes = ({
      absenceId,
      showSystemNote,
    }: {
      /* eslint-disable-next-line no-undef */
      absenceId: RecordId
      showSystemNote: boolean
    }) =>
      store.dispatch(ABSENCE_NOTE_ACTION_LIST_NOTE, {
        absenceId,
        showSystemNote,
      })

    const { columns, onAction } = useTableColumns(isCurrentUserAdmin.value)

    const fetchUserPreferences = () =>
      store.dispatch(USER_PUBLIC_ACTION_LIST_PREFERENCES)

    fetchUserPreferences()

    const { handleScroll, prevScrollOffset } = useTableScroll(
      refs,
      ROW_HEIGHT,
      ROW_NUMB,
      fetchMoreAbsences
    )

    const handleMoreAttachments = (attachments: Attachment[]) => {
      currentAbsenceAttachments.value = attachments
    }

    const closeMobileRowDetails = () => {
      if ($isSmallDevice) {
        absenceInfo.value = null
      }
    }

    /* eslint-disable-next-line no-undef */
    const handleApprove = async (absenceId: RecordId) => {
      await store.dispatch(ABSENCE_ACTION_APPROVE_ABSENCE, { absenceId })
      closeMobileRowDetails()
    }

    /* eslint-disable-next-line no-undef */
    const handleReject = async (absenceId: RecordId) => {
      await store.dispatch(ABSENCE_ACTION_REJECT_ABSENCE, { absenceId })
      closeMobileRowDetails()
    }

    /* eslint-disable-next-line no-undef */
    const handleReset = async (absenceId: RecordId) => {
      await store.dispatch(ABSENCE_ACTION_RESET_ABSENCE, { absenceId })
      closeMobileRowDetails()
    }

    /* eslint-disable-next-line no-undef */
    const handleEdit = (absenceId: RecordId) => {
      editAbsenceId.value = absenceId
      closeMobileRowDetails()
    }

    const handleAttachmentsHover = (absence: Absence) => {
      if (!absence.attachments?.length) {
        if (absence.id) {
          fetchAttachments(absence.id)
        }
      }
    }

    const handleAttachmentsClick = (absence: Absence) => {
      currentAbsenceAttachments.value = absence.attachments || []
    }

    const handleNotesHover = (absence: Absence) => {
      if (!absence.notes?.length && absence.id) {
        fetchNotes({
          absenceId: absence.id,
          showSystemNote: false,
        })
      }
    }

    const handleNotesClick = (absence: Absence) => {
      if (absence.id) {
        areNotesFocused.value = true
        editAbsenceId.value = absence.id
      }
    }

    const rowActionHandler = (action: string, params: any) => {
      switch (action) {
        case ABSENCE_ACTIONS.APPROVE:
          handleApprove(params.id)
          break
        case ABSENCE_ACTIONS.REJECT:
          handleReject(params.id)
          break
        case ABSENCE_ACTIONS.RESET:
          handleReset(params.id)
          break
        case ABSENCE_ACTIONS.EDIT:
          handleEdit(params.id)
          break
        case 'attachments-hover':
          handleAttachmentsHover(params.row)
          break
        case 'attachments-action-click':
          handleAttachmentsClick(params.row)
          break
        case 'notes-hover':
          handleNotesHover(params.row)
          break
        case 'notes-action-click':
          handleNotesClick(params.row)
          break
      }
    }

    onAction(rowActionHandler)

    const closeEditAbsence = () => {
      areNotesFocused.value = false
      editAbsenceId.value = ''
    }

    const isAddAbsenceModalOpen = ref(false)
    const areNotesFocused = ref(false)
    const isOpenBottomSheetAction = ref(false)

    const editAbsenceId = ref('')
    const absenceInfo = ref<Absence | null>(null)

    const onModuleNavigation = (moduleName: string) => {
      if (moduleName === MODULE_NAME) {
        refetchAbsences()
      }
    }

    const rowClick = (row: Absence) => {
      if ($isSmallDevice.value) {
        absenceInfo.value = row
      }
    }

    watch(() => store.getters[ABSENCE_GETTER_ACTIVE_FILTERS], refetchAbsences, {
      immediate: true,
    })

    onMounted(() => {
      fetchAbsencesAction()
      emitter.on('app-sidebar.module-navigation', onModuleNavigation)
    })

    onUnmounted(() => {
      emitter.off('app-sidebar.module-navigation', onModuleNavigation)
    })

    return {
      ROW_HEIGHT,
      $actions,
      columns,
      absences,
      isAddAbsenceModalOpen,
      loadingRowsIds,
      editAbsenceId,
      areNotesFocused,
      isCurrentUserAdmin,
      currentAbsenceAttachments,
      absenceInfo,
      isOpenBottomSheetAction,
      refetchAbsences,
      fetchAbsencesAction,
      closeEditAbsence,
      handleScroll,
      handleMoreAttachments,
      rowClick,
      rowActionHandler,
      fetchMoreAbsencesAction,
      fetchMoreAbsences,
    }
  },
})
