











































import { defineComponent, computed } from '@vue/composition-api'
import { MODULE_NAME } from '@/app/modules/absence/absence.module'
import store from '@/store'
import AbsenceDateNew from './AbsenceDateNew/AbsenceDateNew.vue'
import AbsenceDateOverlapList from './AbsenceDateOverlap/AbsenceDateOverlapList.vue'
import { isAbsenceSpecifyHoursAndSameDay } from './absenceOverlap.utils'

export default defineComponent({
  name: 'AbsenceOverlapDialogue',
  components: {
    AbsenceDateNew,
    AbsenceDateOverlapList,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isSpecifyHoursAndSameDay = isAbsenceSpecifyHoursAndSameDay(
      props.value.fullDay,
      props.value.dateRange[0],
      props.value.dateRange[1]
    )

    const overlapAbsences = computed(
      () => store.state[MODULE_NAME].overlapAbsences
    )

    return {
      overlapAbsences,
      isSpecifyHoursAndSameDay,
    }
  },
})
