<template>
  <div class="flex items-center p-3 rounded-md" :class="backgroundColor">
    <base-icon
      class="mr-2 text-info-300"
      icon="help-information/help-information-24"
      size="24px"
    />
    <div class="font-bold">
      {{ `${$t('absence.add_modal.multiple_user_max_hour')}` }}
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'AbsenceInfoDisplayMultipleEmployees',
  components: {
    BaseIcon,
  },
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
  },
}
</script>
