























import { defineComponent } from '@vue/composition-api'
import BottomSheet from '@/app/util-modules/ui/bottom-sheet/BottomSheet.vue'
import BottomSheetListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem.vue'
import i18n from '@/utils/vendors/i18n/index'
import { TranslateResult } from 'vue-i18n'
import useOptions from './composables/use-options'
import { runOrReturn } from '@/utils/helpers'

export default defineComponent({
  name: 'AbsenceActionBottomSheet',
  components: {
    BottomSheet,
    BottomSheetListItem,
  },
  setup(_props, { emit }) {
    const closeButton: Record<string, TranslateResult> = {
      title: i18n.t('absence.index.bottom_sheet.cancel'),
      class: 'text-coal-550',
      event: 'cancel-click',
    }

    const { actionOptions } = useOptions(emit)

    const close = (cb: () => void) => {
      emit('close')
      cb()
    }

    return {
      actionOptions,
      closeButton,
      runOrReturn,
      close,
    }
  },
})
