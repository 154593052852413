import store from '@/store'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'
import { MODULE_NAME } from '../absence.module'
import AbsenceTopbarTabs from '../components/AbsenceTopbarTabs'

const getHiddenElements = (isAdmin) => {
  const hiddenElements = ['tags']
  if (!isAdmin) {
    hiddenElements.push('areas')
  }

  return hiddenElements
}

const isAdmin = store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]

const DefaultLayout = () =>
  import(
    /* webpackChunkName: "DefaultLayout" */
    '@/app/util-modules/ui/layouts/DefaultLayout'
  )

export default [
  {
    path: '/workspaces/:workspaceId/absences',
    name: `${MODULE_NAME}.index`,
    meta: {
      feature: MODULE_NAME,
      layout: DefaultLayout,
      hiddenElements: getHiddenElements(isAdmin),
    },
    components: {
      'topbar-left': AbsenceTopbarTabs,
      default: () => import('../pages/AbsencePage'),
    },
  },
]
