<template>
  <div class="flex items-center p-2 rounded-md">
    <base-icon
      :icon="dateTime.icon"
      size="24px"
      :class="['mr-2 relative bottom-px', dateTime.iconClass]"
    />
    <div>
      <label class="label mb-0">
        <span class="text-coal-550">
          {{ `${dateTime.label}:` }}
        </span>
      </label>
      <p class="label">
        {{ dateTime.value }}
      </p>
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/app/util-modules/ui/icon/BaseIcon'

export default {
  name: 'DateTimeCard',
  components: {
    BaseIcon,
  },
  props: {
    dateTime: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
>>> .label {
  @apply mb-0;
}
</style>
