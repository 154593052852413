



















import { defineComponent, computed } from '@vue/composition-api'
import store from '@/store'
import { MODULE_NAME } from '../../absence.module'
import BaseFilterbar from '@/app/util-modules/ui/filterbar/BaseFilterbar.vue'
import { ABSENCE_ACTION_LIST_ABSENCE_TYPES } from '@/app/modules/absence/store/actions/absence.actions.names'
import {
  ABSENCE_MUTATION_ADD_FILTERBAR_FILTER,
  ABSENCE_MUTATION_REMOVE_FILTERBAR_FILTER,
} from '../../store/mutations/absence.mutations.names'
import i18n from '@/utils/vendors/i18n/index'
import { Filter } from '../../store/absence.state'
import { AbsenceType } from '../../models/absence.model'
import { objectHasOwnProperty } from '@/app/modules/employee/components/employee.utils'

export default defineComponent({
  name: 'AbsenceFilterbar',
  components: {
    BaseFilterbar,
  },
  setup(_props, { refs }) {
    const absenceTypes = computed<AbsenceType[]>(
      () => store.state[MODULE_NAME].records.absence_type
    )
    const selectedFilters = computed(() => store.state[MODULE_NAME].filters)

    const selectedFilterNames = computed(() =>
      Object.keys(store.state[MODULE_NAME].filters)
    )
    const filters = computed(() => [
      {
        field: 'user_username',
        label: () => i18n.t('filters.employee'),
        type: 'string',
        params: {
          valueInputProps: {
            placeholder: () =>
              i18n.t('absence.filterbar.user_username.placeholder'),
            label: () => i18n.t('absence.filterbar.user_username.label'),
          },
        },
      },
      {
        field: 'absence_type_id',
        label: () => i18n.t('absence.filterbar.absence_type.label'),
        type: 'select',
        params: {
          options: absenceTypes.value.map((r) => ({
            label: r.title,
            value: r.id,
          })),
        },
      },
    ])

    const listAbsenceTypes = () => {
      store.dispatch(ABSENCE_ACTION_LIST_ABSENCE_TYPES)
    }

    const addFilter = (filter: Filter) =>
      store.commit(ABSENCE_MUTATION_ADD_FILTERBAR_FILTER, filter)
    const removeFilter = (filter: Filter) => {
      // TODO: Find out why dropdown is open after removing a filter
      if (objectHasOwnProperty(refs.filterbar, 'isOpen'))
        refs.filterbar.isOpen = false

      store.commit(ABSENCE_MUTATION_REMOVE_FILTERBAR_FILTER, filter)
    }

    listAbsenceTypes()

    return {
      absenceTypes,
      selectedFilters,
      selectedFilterNames,
      filters,

      addFilter,
      removeFilter,
    }
  },
})
