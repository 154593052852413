import { render, staticRenderFns } from "./AbsenceFormInputNote.vue?vue&type=template&id=156cb67d&scoped=true&"
import script from "./AbsenceFormInputNote.vue?vue&type=script&lang=js&"
export * from "./AbsenceFormInputNote.vue?vue&type=script&lang=js&"
import style0 from "./AbsenceFormInputNote.vue?vue&type=style&index=0&id=156cb67d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.1_lh6vo7dceoovl5fyh3epe3vcle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "156cb67d",
  null
  
)

export default component.exports