<template>
  <div class="flex justify-end md:justify-start">
    <base-badge
      :type="statusType"
      size="is-medium"
      :title="runOrReturn(status.label)"
    ></base-badge>
  </div>
</template>

<script>
import { ABSENCE_STATUS } from '../../constants/index'
import { runOrReturn } from '@/utils/helpers'
import BaseBadge from '@/app/util-modules/ui/badge/BaseBadge'

export default {
  name: 'AbsenceCellConfirmation',
  components: {
    BaseBadge,
  },
  props: {
    confirmed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    status() {
      if (this.confirmed === true) {
        return {
          label: () => this.$t('absence.index.status_confirmed'),
          type: ABSENCE_STATUS.CONFIRMED,
        }
      }

      if (this.confirmed === false) {
        return {
          label: () => this.$t('absence.index.status_rejected'),
          type: ABSENCE_STATUS.REJECTED,
        }
      }

      return {
        label: () => this.$t('absence.index.status_unconfirmed'),
        type: ABSENCE_STATUS.PENDING,
      }
    },
    statusType() {
      switch (this.status.type) {
        case ABSENCE_STATUS.PENDING:
          return 'is-warning'
        case ABSENCE_STATUS.CONFIRMED:
          return 'is-success'
        case ABSENCE_STATUS.REJECTED:
          return 'is-danger'
        default:
          return ''
      }
    },
  },
  methods: {
    runOrReturn,
  },
}
</script>
