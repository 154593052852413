<template>
  <absence-form-input-wrapper :label="label">
    <base-time-picker
      v-model="time"
      class="absence-base-time-picker"
      size="is-small"
      data-id-prefix="absence_form_input_hour"
      :disabled="disabled"
    />
  </absence-form-input-wrapper>
</template>

<script>
import debounce from 'lodash/debounce'
import { setHours, setMinutes } from 'date-fns'
import BaseTimePicker from '@/app/util-modules/ui/time-picker/BaseTimePicker.vue'

import { DEFAULT_SEARCH_DEBOUNCE_TIME } from '@/constants'
import AbsenceFormInputWrapper from './AbsenceFormInputWrapper.vue'

export default {
  name: 'AbsenceFormInputHour',
  components: {
    AbsenceFormInputWrapper,
    BaseTimePicker,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Date,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    time: {
      get() {
        return {
          hours: this.value.getHours(),
          minutes: this.value.getMinutes(),
        }
      },
      set({ hours, minutes }) {
        this.handleDateTimeInput(
          setHours(setMinutes(new Date(this.value), minutes), hours)
        )
      },
    },
  },
  methods: {
    handleDateTimeInput: debounce(function (dateTime) {
      this.$emit('input', dateTime)
    }, DEFAULT_SEARCH_DEBOUNCE_TIME),
  },
}
</script>

<style scoped>
::v-deep .base-time-picker-container .input {
  @apply text-center;
}
</style>
