<template>
  <portal to="modal">
    <base-modal has-modal-card>
      <base-card-modal-form
        ref="modal-form"
        :title="modalTitle"
        :scroll-top="scrollTop"
        :action-status="actionStatus"
        hide-scrollbar
        @close="$emit('close')"
      >
        <base-loading v-if="isLoading" :active="true" />
        <template v-else>
          <component
            :is="activeFormComponent"
            v-model="passedValue"
            :can-edit-absence="canEditAbsence"
            :notes="notes"
            :are-notes-focused="areNotesFocused"
            data-id-prefix="absence_edit_form_modal"
            @note-validation="$emit('note-validation', $event)"
            @is-editing-note="$emit('is-editing-note', $event)"
            @delete-note="$emit('delete-note')"
            @save-note="$emit('save-note')"
            @scroll-to-notes="scrollToNotesSection($event)"
            @edit-absence-more-attachments="
              $emit('edit-absence-more-attachments', $event)
            "
          />
        </template>
        <template
          v-slot:footer="{
            footerActionValidate,
            handleSubmit,
            submitDisabled,
            isValid,
          }"
        >
          <absence-form-modal-footer
            v-model="passedValue"
            :input-disabled="false"
            :submit-disabled="submitDisabled"
            :more-options="moreOptions"
            :button-submit-text="$t('absence.add_modal.button_submit')"
            :hide-button-back="true"
            :show-button-edit="showButtonEdit"
            :loading="
              $actions.absenceActionUpdateAbsence.isLoading ||
              $actions.absenceActionCheckOverlap.isLoading
            "
            @done="
              handleFormStep(footerActionValidate, {
                handleSubmit,
                isValid,
              })
            "
            @edit="setActiveModalForm(mainAbsenceForm)"
            @selected="$emit('selected', $event)"
          />
        </template>
      </base-card-modal-form>
    </base-modal>
    <absence-vacation-error-dialog
      v-if="isErrorDialogOpen"
      :dialog-message="$t('absence.add_modal.base_dialog.error_message.user')"
      @close="isErrorDialogOpen = false"
      @done="isErrorDialogOpen = false"
    />
  </portal>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import BaseModal from '@/app/util-modules/ui/modal/BaseModal'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading.vue'
import BaseCardModalForm from '@/app/util-modules/ui/card-modal/BaseCardModalForm'
import AbsenceEditForm from './AbsenceEditForm.vue'
import AbsenceOverlapDialogue from '../absence-overlap-dialogue/AbsenceOverlapDialogue.vue'
import AbsenceFormModalFooter from '../absence-form-modal-footer/AbsenceFormModalFooter'
import AbsenceVacationErrorDialog from '../absence-vacation-error-dialog/AbsenceVacationErrorDialog'
import {
  calculateAbsence,
  MAIN_ABSENCE_FORM,
  OVERLAP_ABSENCE_FORM,
} from '@/app/modules/absence/components/absence-form/absenceForm.utils'
import { MODULE_NAME } from '@/app/modules/absence/absence.module'
import {
  ABSENCE_ACTION_UPDATE_ABSENCE,
  ABSENCE_ACTION_CHECK_ABSENCE_OVERLAP,
} from '@/app/modules/absence/store/actions/absence.actions.names'
import { ABSENCE_MUTATION_SET_ABSENCE_OVERLAP } from '@/app/modules/absence/store/mutations/absence.mutations.names'

export default {
  name: 'AbsenceEditFormModal',
  components: {
    BaseModal,
    BaseCardModalForm,
    BaseLoading,
    AbsenceEditForm,
    AbsenceFormModalFooter,
    AbsenceOverlapDialogue,
    AbsenceVacationErrorDialog,
  },
  props: {
    submitButtonText: {
      type: String,
      required: true,
    },
    actionStatus: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      required: true,
    },
    notes: {
      type: Array,
      default: () => [],
    },
    areNotesFocused: {
      type: Boolean,
      default: false,
    },
    canEditAbsence: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  trackedActions: {
    absenceActionUpdateAbsence: ABSENCE_ACTION_UPDATE_ABSENCE,
    absenceActionCheckOverlap: ABSENCE_ACTION_CHECK_ABSENCE_OVERLAP,
  },
  data() {
    return {
      scrollTop: 0,
      mainAbsenceForm: MAIN_ABSENCE_FORM,
      activeForm: MAIN_ABSENCE_FORM,
      isErrorDialogOpen: false,
    }
  },
  computed: {
    ...mapState({
      overlapAbsences: (state) => state[MODULE_NAME].overlapAbsences,
    }),
    passedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    moreOptions() {
      if (
        this.passedValue.confirmed === null &&
        this.passedValue.permissions?.destroy
      ) {
        return [
          {
            id: 'delete',
            label: this.$t('absence.edit_modal.option_label_delete'),
            icon: 'delete/delete-16',
            classes: 'text-error-400',
          },
        ]
      }

      return []
    },
    activeFormComponent() {
      return this.activeForm === OVERLAP_ABSENCE_FORM
        ? 'absence-overlap-dialogue'
        : 'absence-edit-form'
    },
    modalTitle() {
      return this.activeForm === OVERLAP_ABSENCE_FORM
        ? this.$t('absence.overlap_absence.title')
        : this.$t('absence.edit_modal.title')
    },
    showButtonEdit() {
      return this.activeForm === OVERLAP_ABSENCE_FORM
    },
  },
  watch: {
    overlapAbsences: {
      handler(value) {
        if (value.length) {
          this.setActiveModalForm(OVERLAP_ABSENCE_FORM)
        }
      },
      immediate: true,
    },
  },
  created() {
    this.setActiveModalForm(MAIN_ABSENCE_FORM)
    store.commit(ABSENCE_MUTATION_SET_ABSENCE_OVERLAP, [])
  },
  methods: {
    async handleFormStep(footerActionValidate, { handleSubmit, isValid }) {
      if (this.passedValue.dateRange.length === 1) {
        this.passedValue.dateRange.push(this.passedValue.dateRange[0])
        await calculateAbsence(this.passedValue)
      }

      this.$emit('custom-validate', (isCustomValidateIsValid) =>
        this.formStep(footerActionValidate, {
          handleSubmit,
          isValid,
          isCustomValidateIsValid,
        })
      )
    },
    async formStep(
      footerActionValidate,
      { handleSubmit, isValid, isCustomValidateIsValid }
    ) {
      this.isErrorDialogOpen = !isCustomValidateIsValid
      await footerActionValidate(handleSubmit)

      if (!(isCustomValidateIsValid && isValid)) {
        return
      }
      this.$emit('submit')
    },
    scrollToNotesSection(offsetTop) {
      this.scrollTop = offsetTop
    },
    setActiveModalForm(newActiveForm) {
      this.activeForm = newActiveForm
    },
  },
}
</script>
