<template>
  <portal to="modal">
    <base-dialog
      :title="$t('absence.edit_modal.delete_confirm_dialog.title')"
      :button-submit-text="
        $t('absence.edit_modal.delete_confirm_dialog.submit_button')
      "
      button-type="is-filled-red"
      :loading="$actions.deleteAbsence.isLoading"
      @close="$emit('close')"
      @done="$emit('done')"
    >
      <p>{{ $t('absence.edit_modal.delete_confirm_dialog.content') }}</p>
    </base-dialog>
  </portal>
</template>

<script>
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog'
import { ABSENCE_ACTION_DELETE_ABSENCE } from '../../store/actions/absence.actions.names'

export default {
  name: 'AbsenceDeleteConfirm',
  components: {
    BaseDialog,
  },
  trackedActions: {
    deleteAbsence: ABSENCE_ACTION_DELETE_ABSENCE,
  },
}
</script>
