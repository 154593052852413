import { BaseResponseMeta } from '@/utils/api/base-response'
import { shortname } from '@/utils/store'
import { AxiosResponse } from 'axios'
import { type ActionContext } from 'vuex'
import { AbsenceRaw, ABSENCE_ITEMS_PER_PAGE } from '../../models/absence.model'

import { ABSENCE_STATUS, ABSENCE_ACTIONS } from '../../constants'
import { AbsenceState } from '../absence.state'
import * as actionNamesObj from './absence.actions.names'
import * as mutationNamesObj from '../mutations/absence.mutations.names'
import * as getterNamesObj from '../getters/absence.getters.names'
import api from '../../network/absence.api'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)
const getterNames = shortname(getterNamesObj)

type ListResponse = AxiosResponse<{
  data: AbsenceRaw[]
  meta: BaseResponseMeta
  included: []
}>

export default {
  [actionNames.ABSENCE_ACTION_UPDATE_META]: async (
    { rootState, commit, getters }: ActionContext<AbsenceState, any>,
    { meta }: { meta?: BaseResponseMeta } = {}
  ): Promise<void> => {
    if (!meta) {
      const { data: response }: ListResponse = await api.listAbsences({
        workspaceId: rootState.workspace.workspace.id,
        filter: getters[getterNames.ABSENCE_GETTER_ACTIVE_FILTERS],
        sorting: {},
        pagination: { items: 1, page: 1 },
      })
      meta = response.meta
    }

    meta.page_items = ABSENCE_ITEMS_PER_PAGE
    meta.total_pages = Math.ceil(meta.total_count / ABSENCE_ITEMS_PER_PAGE)

    commit(mutationNames.ABSENCE_MUTATION_SET_META, meta)
  },

  [actionNames.ABSENCE_ACTION_UPDATE_META_ACTION_STATUS]: (
    { commit, state }: ActionContext<AbsenceState, any>,
    {
      statusAction,
      confirmationStatus,
    }: { statusAction: string; confirmationStatus: string }
  ): void => {
    if (
      typeof state.meta.pending === 'undefined' ||
      typeof state.meta.confirmed === 'undefined' ||
      typeof state.meta.rejected === 'undefined'
    ) {
      return
    }
    if (
      state.currentTab === ABSENCE_STATUS.ALL ||
      state.currentTab === ABSENCE_STATUS.PENDING
    ) {
      switch (statusAction) {
        case ABSENCE_ACTIONS.REJECT:
          commit(mutationNames.ABSENCE_MUTATION_SET_META_ACTION_STATUS, {
            ...state.meta,
            pending: state.meta.pending - 1,
            rejected: state.meta.rejected + 1,
          })
          break
        case ABSENCE_ACTIONS.APPROVE:
          commit(mutationNames.ABSENCE_MUTATION_SET_META_ACTION_STATUS, {
            ...state.meta,
            pending: state.meta.pending - 1,
            confirmed: state.meta.confirmed + 1,
          })
          break
      }
    }

    if (statusAction === ABSENCE_ACTIONS.RESET) {
      if (
        confirmationStatus === ABSENCE_STATUS.REJECTED ||
        state.currentTab === ABSENCE_STATUS.REJECTED
      ) {
        commit(mutationNames.ABSENCE_MUTATION_SET_META_ACTION_STATUS, {
          ...state.meta,
          rejected: state.meta.rejected - 1,
          pending: state.meta.pending + 1,
        })
      } else if (
        confirmationStatus === ABSENCE_STATUS.CONFIRMED ||
        state.currentTab === ABSENCE_STATUS.CONFIRMED
      ) {
        commit(mutationNames.ABSENCE_MUTATION_SET_META_ACTION_STATUS, {
          ...state.meta,
          confirmed: state.meta.confirmed - 1,
          pending: state.meta.pending + 1,
        })
      }
    }
  },
}
