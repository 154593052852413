var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal"}},[_c('base-modal',{attrs:{"has-modal-card":""}},[_c('base-card-modal-form',{ref:"modal-form",attrs:{"title":_vm.modalTitle,"scroll-top":_vm.scrollTop,"action-status":_vm.actionStatus,"hide-scrollbar":""},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
        var footerActionValidate = ref.footerActionValidate;
        var handleSubmit = ref.handleSubmit;
        var submitDisabled = ref.submitDisabled;
        var isValid = ref.isValid;
return [_c('absence-form-modal-footer',{attrs:{"input-disabled":false,"submit-disabled":submitDisabled,"more-options":_vm.moreOptions,"button-submit-text":_vm.$t('absence.add_modal.button_submit'),"hide-button-back":true,"show-button-edit":_vm.showButtonEdit,"loading":_vm.$actions.absenceActionUpdateAbsence.isLoading ||
            _vm.$actions.absenceActionCheckOverlap.isLoading},on:{"done":function($event){return _vm.handleFormStep(footerActionValidate, {
              handleSubmit: handleSubmit,
              isValid: isValid,
            })},"edit":function($event){return _vm.setActiveModalForm(_vm.mainAbsenceForm)},"selected":function($event){return _vm.$emit('selected', $event)}},model:{value:(_vm.passedValue),callback:function ($$v) {_vm.passedValue=$$v},expression:"passedValue"}})]}}])},[(_vm.isLoading)?_c('base-loading',{attrs:{"active":true}}):[_c(_vm.activeFormComponent,{tag:"component",attrs:{"can-edit-absence":_vm.canEditAbsence,"notes":_vm.notes,"are-notes-focused":_vm.areNotesFocused,"data-id-prefix":"absence_edit_form_modal"},on:{"note-validation":function($event){return _vm.$emit('note-validation', $event)},"is-editing-note":function($event){return _vm.$emit('is-editing-note', $event)},"delete-note":function($event){return _vm.$emit('delete-note')},"save-note":function($event){return _vm.$emit('save-note')},"scroll-to-notes":function($event){return _vm.scrollToNotesSection($event)},"edit-absence-more-attachments":function($event){return _vm.$emit('edit-absence-more-attachments', $event)}},model:{value:(_vm.passedValue),callback:function ($$v) {_vm.passedValue=$$v},expression:"passedValue"}})]],2)],1),(_vm.isErrorDialogOpen)?_c('absence-vacation-error-dialog',{attrs:{"dialog-message":_vm.$t('absence.add_modal.base_dialog.error_message.user')},on:{"close":function($event){_vm.isErrorDialogOpen = false},"done":function($event){_vm.isErrorDialogOpen = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }