



















import { defineComponent, computed } from '@vue/composition-api'
import BaseInputNumber from '@/app/util-modules/ui/input-number/BaseInputNumber.vue'
import { preventNonNumericChar } from '@/app/modules/employee/components/employee.utils'

export default defineComponent({
  name: 'AbsenceFormManualNumberInput',
  components: {
    BaseInputNumber,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    calculatedValue: {
      type: [String, Number],
      required: true,
    },
    toggle: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },

  setup(_props, { emit }) {
    const passedValue = computed({
      get() {
        return _props.value
      },
      set(passedValue) {
        emit('input', passedValue)
      },
    })
    return {
      passedValue,
      preventNonNumericChar,
    }
  },
})
