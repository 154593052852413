












































import { defineComponent, computed } from '@vue/composition-api'
import AbsenceCellStatus from '../../absence-table-cell/AbsenceCellConfirmation.vue'
import AbsenceCellDate from '../../absence-table-cell/AbsenceCellDate.vue'
import AbsenceCellTime from '../../absence-table-cell/AbsenceCellTime.vue'
import { getFormattedDate, isTimeValid } from '../absenceOverlap.utils'
import { formatTimeCell } from '@/utils/date-time.utils'

export default defineComponent({
  name: 'AbsenceDateOverlapItem',
  components: {
    AbsenceCellStatus,
    AbsenceCellDate,
    AbsenceCellTime,
  },
  props: {
    absence: {
      type: Object,
      required: true,
    },
    showTimeCol: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const columnClass = computed(() => (props.showTimeCol ? 'w-1/4' : 'w-1/3'))

    return {
      getFormattedDate,
      formatTimeCell,
      isTimeValid,
      columnClass,
    }
  },
})
