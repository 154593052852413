import {
  ABSENCE_TYPE,
  ABSENCE_NOTE_TYPE,
  NoteFilter,
  Note,
} from '../../models/absence.model'
import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'
import { AbsenceState } from '../absence.state'
import api from '../../network/absence.api'

import * as mutationNamesObj from '../mutations/absence.mutations.names'
const mutationNames = shortname(mutationNamesObj)

import * as actionNamesObj from './absence.actions.names'
import { BaseRequestPagination } from '@/utils/api/base-request'

import { ListResponse } from './absence.actions'

const actionNames = shortname(actionNamesObj)

export default {
  [actionNames.ABSENCE_ACTION_ADD_ABSENCE_NOTE]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    {
      absenceId,
      currentUserId,
      note,
    }: {
      workspaceId: RecordId
      absenceId: RecordId
      currentUserId: RecordId
      note: Note
    }
  ) => {
    const { data: response } = await api.addNoteToAbsence({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
      currentUserId,
      note,
    })
    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
    return response
  },

  [actionNames.ABSENCE_ACTION_UPDATE_ABSENCE_NOTE]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    {
      absenceId,
      currentUserId,
      note,
    }: {
      absenceId: RecordId
      currentUserId: RecordId
      note: Note
    }
  ) => {
    const { data: response } = await api.updateAbsenceNote({
      workspaceId: rootState.workspace.workspace.id,
      note,
      absenceId,
      currentUserId,
    })
    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
    return response
  },

  [actionNames.ABSENCE_ACTION_DELETE_ABSENCE_NOTE]: async (
    { rootState, commit }: ActionContext<AbsenceState, any>,
    {
      absenceId,
      noteId,
    }: {
      absenceId: RecordId
      noteId: RecordId
    }
  ) => {
    const { data: response } = await api.deleteAbsenceNote({
      workspaceId: rootState.workspace.workspace.id,
      noteId,
      absenceId,
    })
    commit(mutationNames.ABSENCE_MUTATION_REPLACE_RECORDS, {
      type: ABSENCE_TYPE,
      match: {
        id: response.data.id,
      },
      replacement: response.data,
    })
    return response
  },

  [actionNames.ABSENCE_NOTE_ACTION_LIST_NOTE]: async (
    { commit, rootState, state }: ActionContext<AbsenceState, any>,
    {
      absenceId,
      showSystemNote,
    }: {
      absenceId: RecordId
      showSystemNote: boolean
    }
  ) => {
    commit(mutationNames.ABSENCE_MUTATION_CLEAR_RECORDS, {
      type: ABSENCE_NOTE_TYPE,
    })
    const pagination: BaseRequestPagination = {
      ...state.notePagination,
      page: state.notePagination.page + 1,
    }

    const filter: NoteFilter = {}
    if (!showSystemNote) {
      filter.type = 'eq:UserNote'
    }

    const { data: response } = await api.listAbsenceNotes({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
      filter: filter,
      pagination,
    })

    if (response.data.length) {
      commit(mutationNames.ABSENCE_MUTATION_ADD_INCLUDED, {
        records: response.included,
      })

      commit(mutationNames.ABSENCE_MUTATION_APPEND_RECORDS, {
        type: ABSENCE_NOTE_TYPE,
        records: response.data,
      })
      commit(mutationNames.ABSENCE_MUTATION_SET_NOTE_META, response.meta)
      commit(mutationNames.ABSENCE_NOTE_MUTATION_INCREMENT_PAGE_NOTE)
    }
  },

  [actionNames.ABSENCE_NOTE_ACTION_LIST_APPEND_NOTE]: async (
    { commit, rootState, state }: ActionContext<AbsenceState, any>,
    {
      absenceId,
      showSystemNote,
    }: {
      absenceId: RecordId
      type: string
      showSystemNote: boolean
    }
  ) => {
    const pagination: BaseRequestPagination = {
      ...state.notePagination,
      page: state.notePagination.page + 1,
    }

    if (
      state.noteCurrentMeta.total_pages > 0 &&
      pagination.page > state.noteCurrentMeta.total_pages
    ) {
      return
    }

    const filter: NoteFilter = {}
    if (!showSystemNote) {
      filter.type = 'eq:UserNote'
    }

    const { data: response }: ListResponse = await api.listAbsenceNotes({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
      filter: filter,
      pagination,
    })

    if (response.data.length) {
      commit(mutationNames.ABSENCE_MUTATION_ADD_INCLUDED, {
        records: response.included,
      })

      commit(mutationNames.ABSENCE_MUTATION_APPEND_RECORDS, {
        type: ABSENCE_NOTE_TYPE,
        records: response.data,
      })
      commit(mutationNames.ABSENCE_MUTATION_SET_NOTE_META, response.meta)
      commit(mutationNames.ABSENCE_NOTE_MUTATION_INCREMENT_PAGE_NOTE)
    }
    return response
  },
}
