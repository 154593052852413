<template>
  <div>
    <div v-if="confirmedBy !== ''" class="flex items-center md:justify-end">
      <span
        class="font-medium text-lg md:text-base truncate"
        :title="confirmedBy"
      >
        {{ confirmedBy }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbsenceCellConfirmedBy',
  props: {
    confirmedBy: {
      type: String,
      default: '',
    },
  },
  computed: {},
}
</script>
