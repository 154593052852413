<template>
  <div
    class="grid grid-cols-2 gap-6 mb-10"
    :class="{ 'grid-cols-3': !canEditAbsence }"
  >
    <absence-form-field-item-display
      :value="passedValue.user.username || ''"
      :label="$t('absence.add_form.field_label_employee')"
    />
    <template v-if="!canEditAbsence">
      <div class="flex"></div>
      <absence-form-field-item-display
        :value="absenceType"
        :label="$t('absence.add_form.field_label_absence_type')"
      />
    </template>
    <absence-form-input-type v-else v-model="passedValue" />
  </div>
</template>

<script>
import AbsenceFormInputType from './absence-form-input-type/AbsenceFormInputType.vue'
import AbsenceFormFieldItemDisplay from '../absence-form-field-item-display/AbsenceFormFieldItemDisplay.vue'

export default {
  name: 'AbsenceFormTopSectionEditAbsence',
  components: {
    AbsenceFormInputType,
    AbsenceFormFieldItemDisplay,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    canEditAbsence: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    absenceType() {
      return this.value.type?.attributes?.title || ''
    },
  },
}
</script>
