import { State, RecordsIndexes } from '@/utils/jsonapi/types'
import {
  BaseRequestPagination,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import { BaseResponseMeta } from '@/utils/api/base-response'
import {
  ABSENCE_TYPE,
  Absence,
  AbsenceType,
  User,
  ABSENCE_NOTE_TYPE,
  Note,
  AbsenceCalculation,
  type VacationSummary,
} from '../models/absence.model'
import { TABLE_COLUMNS_IDS, ABSENCE_STATUS } from '../constants/index'
import { Attachment } from '@/utils/note-attachments'
import { getDateRangeNext30Days } from '@/utils/date-time.utils'

export interface Filter {
  field: string
  filter: Record<string, unknown>
}

export interface AbsenceMeta extends BaseResponseMeta {
  confirmed?: number
  current_page: number
  page_items: number
  pending?: number
  rejected?: number
  total_count: number
  total_pages: number
  total_days: number
  total_hours: number
}

export interface AbsenceState extends State {
  records: {
    [ABSENCE_TYPE]: Absence[]
    absence_type: AbsenceType[]
    user_overview: User[]
    [ABSENCE_NOTE_TYPE]: Note[]
    attachment: Attachment[]
  }
  recordsIndexes: RecordsIndexes
  pagination: BaseRequestPagination
  notePagination: BaseRequestPagination
  sorting: BaseRequestSorting
  filters: Record<string, Filter>
  meta: AbsenceMeta
  currentMeta: AbsenceMeta
  displayedColumns: string[]
  absenceTypes: Record<string, unknown>[]
  vacationSummary: Partial<VacationSummary>
  currentTab: string
  noteCurrentMeta: BaseResponseMeta
  calculation: AbsenceCalculation
  absenceFilterDateRange: Date[]
  absenceCalendarFilterDateRange: Date[]
  creationUsers: []
  overlapAbsences: []
}

/* istanbul ignore next */
export const makeEmptyState = (): AbsenceState => ({
  records: {
    [ABSENCE_TYPE]: [],
    absence_type: [],
    user_overview: [],
    [ABSENCE_NOTE_TYPE]: [],
    attachment: [],
  },
  recordsIndexes: {},
  pagination: { items: 30, page: 0 },
  sorting: {},
  filters: {},
  meta: {
    confirmed: 0,
    current_page: 0,
    page_items: 0,
    pending: 0,
    rejected: 0,
    total_count: 0,
    total_pages: 1,
    total_days: 0,
    total_hours: 0,
  },
  currentMeta: {
    confirmed: 0,
    current_page: 0,
    page_items: 0,
    pending: 0,
    rejected: 0,
    total_count: 0,
    total_pages: 0,
    total_days: 0,
    total_hours: 0,
  },
  displayedColumns: TABLE_COLUMNS_IDS,
  absenceTypes: [],
  vacationSummary: {},
  currentTab: ABSENCE_STATUS.ALL,
  notePagination: { items: 30, page: 0 },
  noteCurrentMeta: {
    current_page: 0,
    page_items: 0,
    total_count: 0,
    total_pages: 0,
  },
  calculation: {
    days: 0,
    hours: 0,
  },
  absenceFilterDateRange: getDateRangeNext30Days(),
  absenceCalendarFilterDateRange: [],
  creationUsers: [],
  overlapAbsences: [],
})

export default makeEmptyState()
