import { shortname } from '@/utils/store'
import { type ActionContext } from 'vuex'
import * as actionNamesObj from './absence.actions.names'
import * as mutationNamesObj from '../mutations/absence.mutations.names'
import api from '../../network/absence.api'
import { NOTE_ATTACHMENT_TYPE } from '../../models/absence-attachment.model'
import { AbsenceState } from '../absence.state'

const actionNames = shortname(actionNamesObj)
const mutationNames = shortname(mutationNamesObj)

export default {
  [actionNames.ABSENCE_ACTION_ATTACHMENT_LIST]: async (
    { commit, rootState }: ActionContext<AbsenceState, any>,
    { absenceId }: { absenceId: RecordId }
  ): Promise<void> => {
    const { data: responseData } = await api.listAbsenceAttachments({
      workspaceId: rootState.workspace.workspace.id,
      absenceId,
    })

    if (responseData.data.length) {
      commit(mutationNames.ABSENCE_MUTATION_APPEND_RECORDS, {
        type: NOTE_ATTACHMENT_TYPE,
        records: responseData.data,
      })
    }
  },
}
