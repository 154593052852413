<template>
  <div class="sticky z-8 top-16 py-2 border-coal-100 border-b bg-white">
    <mobile-action-bar :spacing="4">
      <base-button type="is-filled" @click="$emit('open-bottom-sheet-action')">
        {{ $t('absence.index.header_mobile.actions_title') }}
      </base-button>

      <absence-index-date-range-picker />
      <absence-tabs-header />

      <base-button
        v-if="isAdmin"
        type="is-outlined-grey"
        @click="isMobileFilterShown = true"
      >
        {{ $t('absence.filterbar.filter_button') }}
      </base-button>
    </mobile-action-bar>

    <absence-filterbar
      v-if="isFilterbarShown"
      @close="isMobileFilterShown = false"
    />
  </div>
</template>

<script>
import { ref, defineComponent, computed } from '@vue/composition-api'
import store from '@/store'
import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import MobileActionBar from '@/app/util-modules/ui/mobile-action-bar/MobileActionBar.vue'
import AbsenceTabsHeader from '../absence-tabs-header/AbsenceTabsHeader.vue'
import AbsenceIndexDateRangePicker from '../absence-index-date-range-picker/AbsenceIndexDateRangePicker.vue'
import AbsenceFilterbar from '../absence-filterbar/AbsenceFilterbar.vue'
import { MODULE_NAME } from '../../absence.module'

export default defineComponent({
  name: 'AbsenceHeaderMobile',
  components: {
    AbsenceFilterbar,
    AbsenceTabsHeader,
    BaseButton,
    MobileActionBar,
    AbsenceIndexDateRangePicker,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isMobileFilterShown = ref(false)
    const filters = computed(() =>
      Object.keys(store.state[MODULE_NAME].filters)
    )
    const isFilterbarShown = computed(
      () => isMobileFilterShown.value || filters.value.length
    )

    return { isMobileFilterShown, isFilterbarShown }
  },
})
</script>
