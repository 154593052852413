import { RecordRelationship } from '@/utils/jsonapi/types'
import BaseRequest, {
  BaseRequestPagination,
  BaseRequestSorting,
} from '@/utils/api/base-request'
import {
  ABSENCE_ENDPOINT_LIST_ABSENCES,
  ABSENCE_ENDPOINT_CREATE_ABSENCE,
  ABSENCE_ENDPOINT_BATCH_CREATE_ABSENCE,
  ABSENCE_ENDPOINT_LIST_USERS_ABSENCE_TYPES,
  ABSENCE_ENDPOINT_VACATION_SUMMARY,
  ABSENCE_ENDPOINT_APPROVE_ABSENCE,
  ABSENCE_ENDPOINT_REJECT_ABSENCE,
  ABSENCE_ENDPOINT_RESET_ABSENCE,
  ABSENCE_ENDPOINT_DELETE_ABSENCE,
  ABSENCE_ENDPOINT_UPDATE_ABSENCE,
  ABSENCE_ENDPOINT_UPDATE_ABSENCE_FOLLOWERS,
  ABSENCE_ENDPOINT_DELETE_ABSENCE_FOLLOWER,
  ABSENCE_ENDPOINT_LIST_NOTES,
  ABSENCE_ENDPOINT_SHOW_ABSENCE,
  ABSENCE_ENDPOINT_CALCULATOR,
  ABSENCE_CALENDAR_ENDPOINT_LIST_ABSENCES,
  ABSENCE_ENDPOINT_CHECK_ABSENCE_OVERLAP,
} from '../network/absence.endpoints'
import { Attachment, addAttachments } from '@/utils/note-attachments'
import { getTimezone } from '@/utils/date-time.utils'
import { generateDateRangeQueryParam } from '@/utils/helpers'

export const ABSENCE_TYPE = 'absence'
export const FOLLOWER_TYPE = 'follower'
export const BATCH_ACTION = 'batch_action'

export const ABSENCE_NOTE_TYPE = 'note'

export const ABSENCE_ITEMS_PER_PAGE = 30

const PERMISSIONS_PARAMS = {
  update: true,
  destroy: true,
  reset: true,
  reject: true,
  confirm: true,
}

const formDataFollower = (
  formData: FormData,
  followerIds: string[],
  followerNameTemplate: string
) => {
  const arr = [...followerIds]
  for (let i = followerIds.length - 1; i >= 0; --i) {
    const follower = arr[i]
    const followerName = `${followerNameTemplate}[]`
    formData.append(`${followerName}`, `${follower}`)
  }
}

export interface AbsenceCheckOverlapParams {
  starts_at: string
  ends_at: string
  user_ids: string[]
  absence_id?: string
  full_day: boolean
}

export interface AbsenceCalendarDateRange {
  start_date: Date
  end_date: Date
}

interface Attributes {
  confirmation_status: string
  confirmed: boolean
  confirmed_at: string
  created_at: string
  created_by: number
  days: number
  ends_at: string
  full_day: boolean
  hours: number
  starts_at: string
  title: string
  updated_at: string
  use_custom_values: boolean
  confirm_message: string | null
}

export interface ListAbsencesParams {
  workspaceId: RecordId
  pagination: BaseRequestPagination
  sorting: BaseRequestSorting
  filter: Record<string, string>
}

export interface AbsenceRaw {
  id: RecordId
  attributes: Attributes
  relationships: RecordRelationship[]
}

export interface AbsenceCalculation {
  days: number
  hours: number
}

export interface AbsenceCalculatorParams {
  starts_at: string
  ends_at: string
  full_day: boolean
  user_id: RecordId
  absence_type_id: RecordId
  time_zone: string
}

export interface NoteFilter {
  type?: string
}

export interface Note {
  id?: RecordId
  type?: string
  content?: string
  author?: string
  author_role?: string
  created_at?: Date
  isEdited?: boolean
  isDeleted?: boolean
  attachments?: Attachment[]
  updatedAttachments?: Attachment[]
}

export interface AbsenceNoteRaw {
  id: RecordId
  attributes: Record<string, unknown>
  relationships: RecordRelationship[]
}

export interface Absence {
  id?: string
  // attributes
  user_id: RecordId
  starts_at: Date
  ends_at: Date
  days?: number
  hours?: number
  full_day?: boolean
  confirmed?: boolean
  absence_type_id?: RecordId
  title?: string
  confirmation_status?: string

  employee?: string
  area?: string
  absence_type?: AbsenceType
  user: User
  confirmed_by_user: User
  absence_hours?: string
  attachments_count?: number
  conflicts?: string
  tags?: string
  confirm_by?: string
  status?: string
  actions?: string
  // relationships
  notes?: Note[]
  attachments?: Attachment[]
  permissions?: {
    update: boolean
  }
}

export interface User {
  id?: RecordId
  avatar: string
  email: string
  locale: string
  salary: number
  username: string
  user?: User
  notes?: Note[]
}

export interface AbsenceType {
  id: RecordId
  kind: string
  title: string
}

export interface AbsenceCreate {
  user_ids: RecordId[]
  starts_at: string
  ends_at: string
  absence_type_id: RecordId
  noteContent?: string
  attachments?: Attachment[]
  days: number
  full_day: boolean
  hours: number
  title: string
  use_custom_values: boolean
}

export interface AbsenceCreateFormData {
  user_ids: string[]
  user_id: string
  starts_at: string
  ends_at: string
  absence_type_id: string
  noteContent: string
  attachments: Attachment[]
  days: string
  full_day: string
  hours: string
  title: string
  use_custom_values: string
  follower_ids: []
  currentUserId: string
}

export interface AbsenceNoteCreate {
  noteContent: string
  attachments: Attachment[]
}

export interface AbsenceUpdateFormData {
  user_ids: string[]
  user_id: string
  starts_at: string
  ends_at: string
  absence_type_id: string
  noteContent: string
  attachments: Attachment[]
  days: string
  full_day: string
  hours: string
  title: string
  use_custom_values: string
  notes?: Note[]
  followers: []
  currentUserId: string
}

interface VacationDays {
  planned_vacation_days_this_year?: string
  remaining_vacation_days_this_year: string
}
export interface VacationSummary {
  attributes: VacationDays
  id: string
  type: string
}

interface AddAbsencePayloadTotal {
  days: number
  hours: number
}
interface AddAbsencePayloadTotalType {
  id: string
  type: string
  attributes: {
    kind: string
    title: string
  }
}

export interface AbsenceOptionTypeEmployee {
  id: string
  type?: string
  username?: string
  vacationDays?: number
  hourHolidays?: boolean
}
export interface AddAbsencePayload {
  employees: AbsenceOptionTypeEmployee[]
  type: AddAbsencePayloadTotalType
  times: string[]
  fullDay: boolean
  startTime: Date
  endTime: Date
  total: AddAbsencePayloadTotal
  manualValue: boolean
  manualHours: number
  manualDays: number
  note: string
  attachments: Attachment[]
  followers: []
  dateRange: Date[]
}

export interface EditAbsencePayload {
  id?: string
  employees: AbsenceOptionTypeEmployee[]
  type: AddAbsencePayloadTotalType
  times: string[]
  fullDay: boolean
  startTime: Date
  endTime: Date
  total: AddAbsencePayloadTotal
  manualValue: boolean
  manualHours: number
  manualDays: number
  note: string
  attachments: Attachment[]
  followers: []
  dateRange: Date[]
  user: User
  notes?: Note[]
  showSystemNote: boolean
  permissions: {
    destroy: boolean
    update: boolean
  }
  confirmed: boolean | null
}

export interface AbsenceOptionType {
  attributes: {
    kind: string
    title: string
  }
  id: string
}

export interface AbsencePreferenceColumns {
  [key: string]: boolean
}

export class AbsenceListRequest extends BaseRequest {
  constructor({
    workspaceId,
    pagination,
    sorting,
    filter,
  }: {
    workspaceId: RecordId
    pagination: BaseRequestPagination
    sorting: BaseRequestSorting
    filter: Record<string, string>
  }) {
    super()
    const { starts_at: dateRange, ...restFilters } = filter
    const baseURL = ABSENCE_ENDPOINT_LIST_ABSENCES(workspaceId)

    if (dateRange && dateRange.length) {
      const newDateRange = [new Date(dateRange[0]), new Date(dateRange[1])]
      const dateFilter = generateDateRangeQueryParam(newDateRange)

      super.url = `${baseURL}${dateFilter}`
    } else {
      super.url = baseURL
    }

    super.pagination = pagination
    super.sorting = sorting
    super.filter = restFilters
    super.include = 'absence_type,user,confirmed_by_user'
    super.permissions = PERMISSIONS_PARAMS
  }
}
export class AbsenceCreateBatchRequest extends BaseRequest {
  constructor({
    workspaceId,
    formValues,
  }: {
    workspaceId: RecordId
    formValues: AbsenceCreateFormData
  }) {
    super()
    super.url = ABSENCE_ENDPOINT_BATCH_CREATE_ABSENCE(workspaceId)
    super.method = 'post'
    super.type = BATCH_ACTION
    const {
      user_ids,
      absence_type_id,
      days,
      ends_at,
      full_day,
      hours,
      starts_at,
      title,
      noteContent,
      attachments,
      use_custom_values,
      follower_ids,
      currentUserId,
    } = formValues
    const data = new FormData()
    data.append(`${BATCH_ACTION}[action]`, 'create')
    data.append(`${BATCH_ACTION}[absence_type_id]`, absence_type_id)
    data.append(`${BATCH_ACTION}[days]`, days)
    data.append(`${BATCH_ACTION}[ends_at]`, ends_at)
    data.append(`${BATCH_ACTION}[full_day]`, full_day)
    data.append(`${BATCH_ACTION}[hours]`, hours)
    data.append(`${BATCH_ACTION}[starts_at]`, starts_at)
    data.append(`${BATCH_ACTION}[title]`, title)
    data.append(`${BATCH_ACTION}[use_custom_values]`, `${use_custom_values}`)
    data.append(
      `${BATCH_ACTION}[force_calc_custom_hours]`,
      `${use_custom_values}`
    )
    data.append(`${BATCH_ACTION}[time_zone]`, getTimezone())
    user_ids.forEach((id, index) =>
      data.append(`${BATCH_ACTION}[user_ids][${index}]`, id)
    )

    if (noteContent) {
      data.append(`${BATCH_ACTION}[notes_attributes][0][content]`, noteContent)
      data.append(
        `${BATCH_ACTION}[notes_attributes][0][created_by]`,
        currentUserId
      )
    }
    if (noteContent && attachments.length) {
      addAttachments(
        data,
        attachments,
        [],
        `${BATCH_ACTION}[notes_attributes][0][attachments_attributes]`
      )
    }

    if (follower_ids.length) {
      formDataFollower(data, follower_ids, `${BATCH_ACTION}[follower_ids]`)
    }
    super.data = data
  }
}

export class AbsenceCreateRequest extends BaseRequest {
  constructor({
    workspaceId,
    formValues,
  }: {
    workspaceId: RecordId
    formValues: AbsenceCreateFormData
  }) {
    super()
    super.url = ABSENCE_ENDPOINT_CREATE_ABSENCE(workspaceId)
    super.method = 'post'
    super.type = ABSENCE_TYPE
    const {
      user_ids,
      absence_type_id,
      days,
      ends_at,
      full_day,
      hours,
      starts_at,
      title,
      noteContent,
      attachments,
      use_custom_values,
      follower_ids,
      currentUserId,
    } = formValues
    const data = new FormData()
    data.append(`${ABSENCE_TYPE}[absence_type_id]`, absence_type_id)
    data.append(`${ABSENCE_TYPE}[days]`, days)
    data.append(`${ABSENCE_TYPE}[ends_at]`, ends_at)
    data.append(`${ABSENCE_TYPE}[full_day]`, full_day)
    data.append(`${ABSENCE_TYPE}[hours]`, hours)
    data.append(`${ABSENCE_TYPE}[starts_at]`, starts_at)
    data.append(`${ABSENCE_TYPE}[title]`, title)
    data.append(`${ABSENCE_TYPE}[use_custom_values]`, `${use_custom_values}`)
    data.append(
      `${ABSENCE_TYPE}[force_calc_custom_hours]`,
      `${use_custom_values}`
    )
    data.append(`${ABSENCE_TYPE}[user_id]`, user_ids[0])
    data.append(`${ABSENCE_TYPE}[time_zone]`, getTimezone())
    if (noteContent) {
      data.append(`${ABSENCE_TYPE}[notes_attributes][0][content]`, noteContent)
      data.append(
        `${ABSENCE_TYPE}[notes_attributes][0][created_by]`,
        currentUserId
      )
    }
    if (noteContent && attachments.length) {
      addAttachments(
        data,
        attachments,
        [],
        `${ABSENCE_TYPE}[notes_attributes][0][attachments_attributes]`
      )
    }
    if (follower_ids.length) {
      formDataFollower(data, follower_ids, `${ABSENCE_TYPE}[follower_ids]`)
    }
    super.data = data
  }
}

export class AbsenceVacationSummary extends BaseRequest {
  constructor({
    workspaceId,
    userId,
  }: {
    workspaceId: RecordId
    userId: RecordId
  }) {
    super()
    super.url = ABSENCE_ENDPOINT_VACATION_SUMMARY(workspaceId, userId)
  }
}
export class AbsenceApproveRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }) {
    super()
    super.method = 'post'
    super.include = 'user,absence_type,confirmed_by_user'
    super.url = ABSENCE_ENDPOINT_APPROVE_ABSENCE(workspaceId, absenceId)
    super.permissions = PERMISSIONS_PARAMS
  }
}

export class AbsenceRejectRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }) {
    super()
    super.method = 'post'
    super.url = ABSENCE_ENDPOINT_REJECT_ABSENCE(workspaceId, absenceId)
    super.permissions = PERMISSIONS_PARAMS
  }
}

export class AbsenceResetRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }) {
    super()
    super.method = 'post'
    super.url = ABSENCE_ENDPOINT_RESET_ABSENCE(workspaceId, absenceId)
    super.permissions = PERMISSIONS_PARAMS
  }
}

export class AbsenceDeleteRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }) {
    super()
    super.method = 'delete'
    super.url = ABSENCE_ENDPOINT_DELETE_ABSENCE(workspaceId, absenceId)
  }
}

export class AbsenceUpdateRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
    absenceParams,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    absenceParams: AbsenceUpdateFormData
  }) {
    super()
    super.method = 'patch'
    super.url = ABSENCE_ENDPOINT_UPDATE_ABSENCE(workspaceId, absenceId)
    super.permissions = PERMISSIONS_PARAMS
    super.type = ABSENCE_TYPE
    const {
      user_ids,
      absence_type_id,
      days,
      ends_at,
      full_day,
      hours,
      starts_at,
      title,
      noteContent,
      attachments,
      use_custom_values,
      notes,
      followers,
      currentUserId,
    } = absenceParams
    const data = new FormData()
    data.append(`${ABSENCE_TYPE}[absence_type_id]`, absence_type_id)
    data.append(`${ABSENCE_TYPE}[days]`, days)
    data.append(`${ABSENCE_TYPE}[ends_at]`, ends_at)
    data.append(`${ABSENCE_TYPE}[full_day]`, `${full_day}`)
    data.append(`${ABSENCE_TYPE}[hours]`, hours)
    data.append(`${ABSENCE_TYPE}[starts_at]`, starts_at)
    data.append(`${ABSENCE_TYPE}[title]`, title)
    data.append(`${ABSENCE_TYPE}[time_zone]`, getTimezone())
    data.append(
      `${ABSENCE_TYPE}[force_calc_custom_hours]`,
      `${use_custom_values}`
    )
    data.append(`${ABSENCE_TYPE}[user_id]`, user_ids[0])
    if (noteContent) {
      data.append(`${ABSENCE_TYPE}[notes_attributes][0][content]`, noteContent)
      data.append(
        `${ABSENCE_TYPE}[notes_attributes][0][created_by]`,
        currentUserId
      )
    }
    if (noteContent && attachments.length) {
      addAttachments(
        data,
        attachments,
        [],
        `${ABSENCE_TYPE}[notes_attributes][0][attachments_attributes]`
      )
    }

    if (notes?.length) {
      const arr = [...notes]
      for (let i = notes.length - 1; i >= 0; --i) {
        const att = arr[i]
        const noteIndex = i + 1
        const editNoteName = `${ABSENCE_TYPE}[notes_attributes][${noteIndex}]`
        data.append(`${editNoteName}[id]`, `${att.id}`)
        data.append(`${editNoteName}[created_by]`, currentUserId)
        if (att.isDeleted) {
          data.append(`${editNoteName}[_destroy]`, `${att.isDeleted}`)
        }
        if (att.content) {
          data.append(`${editNoteName}[content]`, att.content)
        }
        if (att.updatedAttachments) {
          const attachmentsToUpdate = att.updatedAttachments.filter(
            (attachment) => attachment instanceof File || attachment.isDeleted
          )
          addAttachments(
            data,
            attachmentsToUpdate,
            [],
            `${ABSENCE_TYPE}[notes_attributes][${noteIndex}][attachments_attributes]`
          )
        }
      }
    }

    if (followers.length) {
      formDataFollower(data, followers, `${ABSENCE_TYPE}[follower_ids]`)
    }
    super.data = data
  }
}

export class AbsenceUpdateFollowersRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
    ids,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    ids: RecordId[]
  }) {
    super()
    super.method = 'post'
    super.type = FOLLOWER_TYPE
    super.url = ABSENCE_ENDPOINT_UPDATE_ABSENCE_FOLLOWERS(
      workspaceId,
      absenceId
    )
    super.attributes = {
      ids,
    }
    super.permissions = PERMISSIONS_PARAMS
  }
}
export class AbsenceDeleteFollowersRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
    follower_id,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    follower_id: RecordId
  }) {
    super()
    super.method = 'delete'
    super.type = FOLLOWER_TYPE
    super.url = ABSENCE_ENDPOINT_DELETE_ABSENCE_FOLLOWER(
      workspaceId,
      absenceId,
      follower_id
    )

    super.permissions = PERMISSIONS_PARAMS
  }
}
export class AbsenceListNotesRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
    filter,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
    filter: NoteFilter
    pagination: BaseRequestPagination
  }) {
    super()
    super.url = ABSENCE_ENDPOINT_LIST_NOTES(workspaceId, absenceId)
    super.include = 'attachments'
    super.type = ABSENCE_NOTE_TYPE
    super.filter = filter
    super.sorting = {
      created_at: -1,
    }
    super.permissions = PERMISSIONS_PARAMS
  }
}

export class AbsenceShowRequest extends BaseRequest {
  constructor({
    workspaceId,
    absenceId,
  }: {
    workspaceId: RecordId
    absenceId: RecordId
  }) {
    super()
    super.url = ABSENCE_ENDPOINT_SHOW_ABSENCE(workspaceId, absenceId)
    super.permissions = PERMISSIONS_PARAMS
    super.include = 'followers'
  }
}

export class AbsenceCalculatorRequest extends BaseRequest {
  constructor({
    workspaceId,
    calculatorParams,
  }: {
    workspaceId: RecordId
    calculatorParams: AbsenceCalculatorParams
  }) {
    super()
    super.url = ABSENCE_ENDPOINT_CALCULATOR(workspaceId, calculatorParams)
  }
}

export class AbsenceListUsersAbsenceTypesRequest extends BaseRequest {
  constructor({
    workspaceId,
    userIds,
    pagination,
  }: {
    workspaceId: RecordId
    userIds: RecordId[]
    pagination: BaseRequestPagination
  }) {
    super()
    super.url = ABSENCE_ENDPOINT_LIST_USERS_ABSENCE_TYPES(workspaceId)
    super.pagination = pagination
    super.filter = {
      users_id_in: userIds,
    }
  }
}

export class AbsenceCalendarListAbsenceRequest extends BaseRequest {
  constructor({
    workspaceId,
    dateRange,
  }: {
    workspaceId: RecordId
    dateRange: AbsenceCalendarDateRange
  }) {
    super()
    super.url = ABSENCE_CALENDAR_ENDPOINT_LIST_ABSENCES(workspaceId, dateRange)
    super.include = 'absence_type,user,confirmed_by_user'
    super.permissions = PERMISSIONS_PARAMS
  }
}

export class AbsenceCheckAbsenceOverlapRequest extends BaseRequest {
  constructor({
    workspaceId,
    payload,
  }: {
    workspaceId: RecordId
    payload: AbsenceCheckOverlapParams
  }) {
    super()
    super.url = ABSENCE_ENDPOINT_CHECK_ABSENCE_OVERLAP(workspaceId)
    super.method = 'post'
    super.type = ABSENCE_TYPE

    const { user_ids, ends_at, starts_at, absence_id, full_day } = payload
    const data = new FormData()
    data.append(`${ABSENCE_TYPE}[ends_at]`, ends_at)
    data.append(`${ABSENCE_TYPE}[starts_at]`, starts_at)
    data.append(`${ABSENCE_TYPE}[full_day]`, `${full_day}`)
    if (absence_id) {
      data.append(`${ABSENCE_TYPE}[absence_id]`, absence_id)
    }
    user_ids.forEach((id, index) =>
      data.append(`${ABSENCE_TYPE}[user_ids][${index}]`, id)
    )

    super.data = data
  }
}
