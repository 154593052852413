



























import { defineComponent, computed } from '@vue/composition-api'
import AbsenceCellDate from '../../absence-table-cell/AbsenceCellDate.vue'
import AbsenceCellTime from '../../absence-table-cell/AbsenceCellTime.vue'
import { getFormattedDate } from '../absenceOverlap.utils'
import { formatTimeCell } from '@/utils/date-time.utils'

export default defineComponent({
  name: 'AbsenceDateNew',
  components: {
    AbsenceCellDate,
    AbsenceCellTime,
  },
  props: {
    absenceType: {
      type: String,
      required: true,
    },
    startDate: {
      type: Date,
      required: true,
    },
    endDate: {
      type: Date,
      required: true,
    },
    startTime: {
      type: Date,
      required: true,
    },
    endTime: {
      type: Date,
      required: true,
    },
    showTimeCol: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const columnClass = computed(() => (props.showTimeCol ? 'w-1/4' : 'w-1/3'))

    return {
      getFormattedDate,
      formatTimeCell,
      columnClass,
    }
  },
})
