<template>
  <div class="grid grid-cols-3 gap-6 mb-10">
    <div class="flex-1">
      <absence-form-field-item-display
        :value="user.attributes.username"
        :label="$t('absence.add_form.field_label_employee')"
      />
    </div>
    <div class="flex-1" />
    <div class="flex-1 capitalize">
      <absence-form-field-item-display
        :value="absenceType"
        :label="$t('absence.add_form.field_label_absence_type')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { USER_GETTER_USER } from '@/app/core/user/store/getters/user.getters.names'

import AbsenceFormFieldItemDisplay from '../absence-form-field-item-display/AbsenceFormFieldItemDisplay.vue'

export default {
  name: 'AbsenceFormTopSectionEmployeeTemplate',
  components: {
    AbsenceFormFieldItemDisplay,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: USER_GETTER_USER,
    }),
    absenceType() {
      return this.value.type?.attributes?.title || ''
    },
  },
}
</script>
