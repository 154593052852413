





























































import { defineComponent, computed } from '@vue/composition-api'

import BaseButton from '@/app/util-modules/ui/button/BaseButton.vue'
import ListItem from '@/app/util-modules/ui/bottom-sheet/BottomSheetListItem.vue'
import { ABSENCE_ACTIONS } from '@/app/modules/absence/constants'

export default defineComponent({
  name: 'AbsenceRowBottomSheetActions',
  components: {
    ListItem,
    BaseButton,
  },
  props: {
    absence: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    actionStatuses: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const handleEditAbsence = () => {
      emit('row-action', ABSENCE_ACTIONS.EDIT)
    }

    const showReset = computed(() => {
      return props.absence.confirmed !== null && props.absence.permissions.reset
        ? true
        : false
    })

    return {
      ABSENCE_ACTIONS,
      handleEditAbsence,
      showReset,
    }
  },
})
