<template>
  <base-dialog
    :title="$t('absence.add_modal.base_dialog.title')"
    :button-submit-text="$t('absence.add_modal.base_dialog.button_submit_text')"
    :hide-button-cancel="true"
    @close="$emit('close')"
    @done="$emit('close')"
  >
    <p>{{ dialogMessage }}</p>
  </base-dialog>
</template>

<script>
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'

export default {
  name: 'AbsenceVacationErrorDialog',
  components: {
    BaseDialog,
  },
  props: {
    dialogMessage: {
      type: String,
      required: true,
    },
  },
}
</script>
