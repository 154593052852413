import { shortname } from '@/utils/store'
import { AbsenceState } from '../absence.state'
import * as getterNamesObj from './absence.getters.names'
import { AREA_PUBLIC_GETTER_AREA_LIST_SELECTED_EXCLUDE_WITHOUT_AREAS } from '@/app/core/area/store/getters/area.getters.names'

import { ABSENCE_STATUS, CONFIRMATION_STATUS } from '../../constants'

const getterNames = shortname(getterNamesObj)

export default {
  [getterNames.ABSENCE_GETTER_ACTIVE_FILTERS]: (
    state: AbsenceState,
    _getter: any,
    _rootState: any,
    rootGetters: any
  ) => {
    type ActiveFilters = {
      pending?: string
      confirmed?: string
      rejected?: string
      [CONFIRMATION_STATUS]: string
      starts_at: Date[]
      areas: string[]
    }
    const tab = state.currentTab
    let activeFilters = <ActiveFilters>{}
    const dateRange = state.absenceFilterDateRange

    const areas =
      rootGetters[AREA_PUBLIC_GETTER_AREA_LIST_SELECTED_EXCLUDE_WITHOUT_AREAS]

    if (Array.isArray(areas)) {
      activeFilters.areas = areas
    }

    if (Array.isArray(dateRange)) {
      activeFilters.starts_at = dateRange
    }

    if (tab === ABSENCE_STATUS.PENDING) {
      activeFilters[CONFIRMATION_STATUS] = ABSENCE_STATUS.PENDING
    } else if (tab === ABSENCE_STATUS.CONFIRMED) {
      activeFilters[CONFIRMATION_STATUS] = ABSENCE_STATUS.CONFIRMED
    } else if (tab === ABSENCE_STATUS.REJECTED) {
      activeFilters[CONFIRMATION_STATUS] = ABSENCE_STATUS.REJECTED
    }

    Object.keys(state.filters).forEach((key) => {
      activeFilters = {
        ...activeFilters,
        ...state.filters[key].filter,
      }
    })
    return activeFilters
  },

  [getterNames.ABSENCE_GETTER_ACTIVE_SORTING]: (state: AbsenceState) => {
    const starts_at =
      'starts_at' in state.sorting ? state.sorting.starts_at : -1

    return {
      ...state.sorting,
      starts_at,
    }
  },

  [getterNames.ABSENCE_GETTER_GET_BY_ID]:
    (state: AbsenceState) => (id: string) => {
      return state.records.absence.find((item) => item.id === id)
    },
}
