import {
  Absence,
  AbsenceRaw,
  AbsencePreferenceColumns,
} from '../../models/absence.model'

import { TABLE_COLUMNS_IDS } from '../../constants'

export const transformColumnObjectToArray = (
  columnObject: AbsencePreferenceColumns
) => {
  const filterAvailableColumns: AbsencePreferenceColumns =
    TABLE_COLUMNS_IDS.reduce(
      (columns, column) => ({
        ...columns,
        [column]: Boolean(columnObject[column]),
      }),
      {}
    )

  const columnsToDisplay = Object.keys(filterAvailableColumns).filter(
    (column: string) =>
      filterAvailableColumns[column as keyof AbsencePreferenceColumns]
  )

  return columnsToDisplay
}

export const getUniqueElements = (
  existingArray: Absence[],
  newArray: AbsenceRaw[]
) => {
  const existingIds = existingArray.map((obj: Absence) => obj.id)

  return newArray.filter((obj: AbsenceRaw) => !existingIds.includes(obj.id))
}
