<template>
  <div class="flex flex-wrap">
    <span>{{ time }}</span>
  </div>
</template>

<script>
export default {
  name: 'AbsenceCellTime',
  props: {
    time: {
      type: [String, Date],
      required: true,
    },
  },
}
</script>
