<template>
  <div class="flex flex-col">
    <absence-form-top-section-edit-absence
      v-if="passedValue.id"
      v-model="passedValue"
      :can-edit-absence="canEditAbsence"
    />
    <component
      :is="absenceFormTopSection"
      v-else
      v-model="passedValue"
      :error-field="validationStates"
    />

    <template v-if="isNewForm || canEditAbsence">
      <absence-date-range-picker
        v-model="passedValue"
        :calculate-absence-action-status="calculateAbsenceActionStatus"
      />
      <div class="mt-4">
        <absence-form-input-manual v-model="passedValue" />
      </div>
    </template>
    <absence-form-date-preview
      v-else
      :value="passedValue"
    ></absence-form-date-preview>

    <div class="mt-4">
      <absence-form-input-note
        :key="attachments.length"
        v-model="passedValue"
        :attachments="attachments"
        data-id="absence.add_form.absence_form_main.input_note"
        :show-action-attachment="true"
        @attachments-change="attachments = passedValue.attachments"
        @note-validation="$emit('note-validation', $event)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { USER_GETTER_IS_ASSUMED_ROLE_ADMIN } from '@/app/core/user/store/getters/user.getters.names'

import AbsenceFormInputManual from '../absence-form-input-manual/AbsenceFormInputManual.vue'
import AbsenceFormInputNote from '../absence-form-input-note/AbsenceFormInputNote.vue'
import AbsenceFormTopSectionEditAbsence from './AbsenceFormTopSectionEditAbsence'
import AbsenceDateRangePicker from '../absence-date-range-picker/AbsenceDateRangePicker.vue'
import AbsenceFormTopSectionAdmin from './AbsenceFormTopSectionAdmin'
import AbsenceFormTopSectionEmployee from './AbsenceFormTopSectionEmployee'
import AbsenceFormDatePreview from '@/app/modules/absence/components/absence-form-preview/AbsenceFormDatePreview.vue'

export default {
  name: 'AbsenceFormMain',
  components: {
    AbsenceDateRangePicker,
    AbsenceFormInputManual,
    AbsenceFormInputNote,
    AbsenceFormTopSectionEditAbsence,
    AbsenceFormTopSectionAdmin,
    AbsenceFormTopSectionEmployee,
    AbsenceFormDatePreview,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    calculateAbsenceActionStatus: {
      type: Object,
      default: () => ({}),
    },
    validationStates: {
      type: Object,
      default: () => ({}),
    },
    isNewForm: {
      type: Boolean,
      default: false,
    },
    canEditAbsence: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attachments: [],
    }
  },
  computed: {
    ...mapGetters({
      isAssumedRoleAdmin: USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
    }),
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    absenceFormTopSection() {
      if (this.isAssumedRoleAdmin) {
        return 'absence-form-top-section-admin'
      }
      return 'absence-form-top-section-employee'
    },
  },
  mounted() {
    this.attachments = this.passedValue.attachments
  },
}
</script>
