<template>
  <div class="w-11/12">
    <absence-edit-form-notes-item-user-display
      v-if="isUserNote"
      :note="note"
      :tooltip-position="tooltipPosition"
      :date-time="dateTime"
      @edit="$emit('edit')"
      @delete="$emit('delete')"
      @edit-absence-more-attachments="
        $emit('edit-absence-more-attachments', $event)
      "
    />
    <absence-edit-form-notes-item-system-display
      v-if="isSystemNote"
      :note="note"
      :date-time="dateTime"
    />
  </div>
</template>

<script>
import { NOTE_TYPES } from '@/constants'
import { formatDate, formatTime } from '@/utils/date-time.utils'
import AbsenceEditFormNotesItemUserDisplay from './AbsenceEditFormNotesItemUserDisplay'
import AbsenceEditFormNotesItemSystemDisplay from './AbsenceEditFormNotesItemSystemDisplay'
export default {
  name: 'AbsenceEditFormNotesItemDisplay',
  components: {
    AbsenceEditFormNotesItemUserDisplay,
    AbsenceEditFormNotesItemSystemDisplay,
  },
  props: {
    tooltipPosition: {
      type: String,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
  },
  computed: {
    isUserNote() {
      return this.note.type === NOTE_TYPES.USER
    },
    isSystemNote() {
      return this.note.type === NOTE_TYPES.SYSTEM
    },
    dateTime() {
      return `${formatDate(new Date(this.note.created_at))} ${formatTime(
        new Date(this.note.created_at)
      )}`
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .base-table-row-cell-icon {
  @apply py-0 mx-0.5 px-px;
}
</style>
