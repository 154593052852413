import { computed } from '@vue/composition-api'
import store from '@/store'
import useBasePaginatedSearch from '@/app/util-modules/ui/paginated-search/composables/use-base-paginated-search'
import api from '@/app/modules/absence/network/absence.api'

export default function useAbsenceEmployee() {
  const workspaceId = computed(() => store.state.workspace.workspace.id)

  const searchStrategy = {
    search: async function (query: string | null, page: number) {
      return await api.searchAbsenceCreationUsers(workspaceId.value, {
        query,
        page,
      })
    },
  }

  const { searchContext, search, searchNextPage } =
    useBasePaginatedSearch(searchStrategy)

  return {
    searchContext,
    search,
    searchNextPage,
    searchStrategy,
  }
}
