<template>
  <div ref="noteDisplayContainer">
    <div class="flex justify-between">
      <div class="flex items-baseline">
        <div class="mr-3.5 pr-px font-bold text-coal">{{ note.author }}</div>
        <div class="flex text-coal-650 text-sm 12px font-medium">
          <span>{{ dateTime }}</span>
          <span v-if="note.edited" class="ml-1">
            {{ `(${$t('absence.edit_modal.edit_modal_note.edited')})` }}
          </span>
          <template v-if="attachmentsToDisplay.length">
            <base-attachment-item-tooltip
              v-for="(attachment, attachmentKey) in attachmentsToDisplay"
              :key="attachmentKey"
              :attachments="[attachment]"
              data-id-prefix="absence_edit_form_notes_item"
              icon-size="0.75rem"
              :tooltip-position="tooltipPosition"
              @more-attachments-click="
                $emit('edit-absence-more-attachments', attachmentsToDisplay)
              "
            />
          </template>
        </div>
      </div>
      <base-note-options
        class="flex-none"
        :data-id-prefix="`absence_edit_form_note_item.${note.id}`"
        :permissions="note.permissions"
        :icon="baseNoteOptionsIcon"
        @edit="$emit('edit')"
        @delete="$emit('delete')"
      />
    </div>
    <div
      :class="{
        truncate: isTextTruncated,
        'hover:text-green': hasMoreText,
      }"
      @click="showMoreText()"
    >
      <span ref="contentPreview" class="text-lg font-semibold break-words">
        {{ note.content }}
      </span>
    </div>
  </div>
</template>

<script>
import BaseAttachmentItemTooltip from '@/app/util-modules/ui/attachment-item-tooltip/BaseAttachmentItemTooltip.vue'
import BaseNoteOptions from '@/app/util-modules/ui/note-options/BaseNoteOptions'
export default {
  name: 'AbsenceEditFormNotesItemUserDisplay',
  components: {
    BaseAttachmentItemTooltip,
    BaseNoteOptions,
  },
  props: {
    tooltipPosition: {
      type: String,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    },
    dateTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseNoteOptionsIcon: {
        icon: 'ellipsis/ellipsis-24',
        size: '1.5rem',
      },
      isTextTruncated: true,
      hasMoreText: false,
    }
  },
  computed: {
    attachmentsToDisplay() {
      let attachments = [...this.note.attachments]
      attachments = attachments.filter((attachment) => !attachment.isDeleted)
      return attachments
    },
  },
  mounted() {
    if (
      this.$refs.contentPreview &&
      this.$refs.noteDisplayContainer &&
      this.$refs.contentPreview.offsetWidth >
        this.$refs.noteDisplayContainer.offsetWidth
    ) {
      this.hasMoreText = true
    }
  },
  methods: {
    showMoreText() {
      if (this.hasMoreText) {
        this.isTextTruncated = false
        this.hasMoreText = false
      }
    },
  },
}
</script>
