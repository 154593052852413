var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"to":"modal"}},[_c('base-modal',{attrs:{"has-modal-card":""}},[_c('base-card-modal-form',{attrs:{"title":_vm.modalTitle,"action-status":_vm.actionStatus,"hide-scrollbar":""},on:{"close":function($event){return _vm.handleCloseModal()}},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
        var footerActionValidate = ref.footerActionValidate;
        var handleSubmit = ref.handleSubmit;
        var submitDisabled = ref.submitDisabled;
        var isValid = ref.isValid;
return [_c('absence-form-modal-footer',{attrs:{"value":_vm.passedPayload,"input-disabled":false,"submit-disabled":_vm.noteSubmitDisabled || submitDisabled,"button-submit-text":_vm.$t('absence.add_modal.button_submit'),"hide-button-back":true,"show-button-edit":_vm.showButtonEdit,"loading":_vm.actionStatus.isLoading},on:{"input":function($event){return _vm.$emit('input', $event)},"done":function($event){return _vm.handleFormStep(footerActionValidate, {
              handleSubmit: handleSubmit,
              isValid: isValid,
            })},"back":function($event){return _vm.handleBackStep()},"edit":function($event){return _vm.handleEditButton()}}})]}}])},[_c(_vm.activeFormComponent,{tag:"component",attrs:{"calculate-absence-action-status":_vm.calculateAbsenceActionStatus,"value":_vm.passedPayload,"error-field":_vm.validationStates,"is-new-form":_vm.isNewForm},on:{"input":function($event){return _vm.$emit('input', $event)},"note-validation":function($event){return _vm.$emit('note-validation', $event)}}})],1)],1),(_vm.isErrorDialogOpen)?_c('absence-vacation-error-dialog',{attrs:{"dialog-message":_vm.dialogMessage},on:{"close":function($event){_vm.isErrorDialogOpen = false},"done":function($event){_vm.isErrorDialogOpen = false}}}):_vm._e(),(_vm.isDiscardChangeDialogOpen)?_c('base-dialog',{attrs:{"title":_vm.$t('absence.add_modal.unsaved_changes_dialog.title'),"button-submit-text":_vm.$t(
        'absence.add_modal.unsaved_changes_dialog.button_submit_discard_changes'
      ),"button-type":"is-filled-red"},on:{"close":function($event){_vm.isDiscardChangeDialogOpen = false},"done":function($event){return _vm.handleDiscardChanges()}}},[_c('p',[_vm._v(_vm._s(_vm.$t('absence.add_modal.unsaved_changes_dialog.first_paragraph')))]),_c('p',[_vm._v(_vm._s(_vm.$t('absence.add_modal.unsaved_changes_dialog.second_paragraph')))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }