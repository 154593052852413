<template>
  <div class="grid grid-cols-3 gap-6 mb-10">
    <date-time-card
      v-for="dateTime in dateTimes"
      :key="dateTime.id"
      :date-time="dateTime"
      :class="dateTime.background"
    />
    <absence-info-display-multiple-employees
      v-if="isEmployeesMultiple"
      :background-color="absenceTotal.background"
    />
    <date-time-card
      v-else
      :key="absenceTotal.id"
      :date-time="absenceTotal"
      :class="absenceTotal.background"
    />
  </div>
</template>

<script>
import {
  formatDateTime,
  formatDate,
  dateWithSetHours,
  getHoursAndMinutesFromDate,
  formatTime,
} from '@/utils/date-time.utils'
import DateTimeCard from '@/app/modules/absence/components/absence-form-preview/DateTimeCard.vue'
import AbsenceInfoDisplayMultipleEmployees from '@/app/modules/absence/components/Absence-info-display/AbsenceInfoDisplayMultipleEmployees.vue'
import { isSameDay } from 'date-fns'

export default {
  name: 'AbsenceFormDatePreview',
  components: {
    DateTimeCard,
    AbsenceInfoDisplayMultipleEmployees,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    dateTimes() {
      const [dateRangeStart, dateRangeEnd] = this.passedValue.dateRange
      const isSameStartEndDate = isSameDay(dateRangeStart, dateRangeEnd)

      return [
        {
          id: 1,
          icon: 'calendar/calendar-24',
          background: 'bg-coal-40',
          label: this.$t('absence.preview_form.field_label_start'),
          value:
            this.passedValue.fullDay && isSameStartEndDate
              ? `${formatDateTime(
                  this.combineDateTime(
                    this.passedValue.dateRange[0],
                    this.passedValue.startTime
                  )
                )} | ${formatTime(this.passedValue.startTime)}`
              : `${formatDate(
                  this.passedValue.dateRange[0],
                  'EEEEEE, dd.MM.yy',
                  true
                )}`,
        },
        {
          id: 2,
          icon: 'calendar/calendar-24',
          background: 'bg-coal-40',
          label: this.$t('absence.preview_form.field_label_end'),
          value:
            this.passedValue.fullDay && isSameStartEndDate
              ? `${formatDateTime(
                  this.combineDateTime(
                    this.passedValue.dateRange[1],
                    this.passedValue.endTime
                  )
                )} | ${formatTime(this.passedValue.endTime)}`
              : `${formatDate(
                  this.passedValue.dateRange[1],
                  'EEEEEE, dd.MM.yy',
                  true
                )}`,
        },
      ]
    },
    absenceTotal() {
      return {
        id: 3,
        icon: 'time-tracking/time-tracking-24',
        background: 'bg-green-100',
        label: this.$t('absence.preview_form.field_label_total'),
        iconClass: 'text-green-300',
        value: `${this.passedValue.total.days || 0}${this.$t(
          'absence.absence_label_total_day'
        )} (${this.passedValue.total.hours || 0}${this.$t(
          'absence.absence_label_total_hours'
        )})`,
      }
    },
    isEmployeesMultiple() {
      return this.passedValue.employees.length > 1
    },
  },
  methods: {
    combineDateTime(dateRangeItem, timeObj) {
      return dateWithSetHours(
        dateRangeItem,
        getHoursAndMinutesFromDate(timeObj)
      )
    },
  },
}
</script>
