import store from '@/store'
import { combineDateTime, getTimezone } from '@/utils/date-time.utils'
import { ABSENCE_ACTION_CALCULATE_ABSENCE } from '@/app/modules/absence/store/actions/absence.actions.names'
import { AddAbsencePayload } from '@/app/modules/absence/models/absence.model'
import { formatDateFilter } from '@/utils/date-time.utils'
import { isSameDay } from 'date-fns'

export const MAIN_ABSENCE_FORM = 'mainAbsenceForm'
export const CONFIRM_ABSENCE_FORM = 'confirmAbsenceForm'
export const OVERLAP_ABSENCE_FORM = 'overlapAbsenceForm'

export const calculateAbsence = async (payload: AddAbsencePayload) => {
  const { dateRange, startTime, endTime, fullDay, employees, type } = payload
  let finalStartDateTime = null
  let finalEndDateTime = null

  const [dateRangeStart, dateRangeEnd] = dateRange
  if (!fullDay && isSameDay(dateRangeStart, dateRangeEnd)) {
    finalStartDateTime = combineDateTime(dateRangeStart, startTime)
    finalEndDateTime = combineDateTime(dateRangeEnd, endTime)
  } else {
    finalStartDateTime = formatDateFilter(dateRangeStart)
    finalEndDateTime = formatDateFilter(dateRangeEnd)
  }
  await store.dispatch(ABSENCE_ACTION_CALCULATE_ABSENCE, {
    starts_at: `${finalStartDateTime}`,
    ends_at: `${finalEndDateTime}`,
    full_day: fullDay,
    user_id: employees[0].id,
    absence_type_id: type.id,
    time_zone: getTimezone(),
  })
}
