





















































import capitalize from 'lodash/capitalize'
import {
  getDefaultStartDateTime,
  getDefaultEndDateTime,
  combineDateTime,
  defaultDateRange,
  formatDateFilter,
} from '@/utils/date-time.utils'
import { NOTE_TYPES } from '@/constants'
import store from '@/store'
import {
  ABSENCE_ACTION_GET_ABSENCE,
  ABSENCE_NOTE_ACTION_LIST_NOTE,
  ABSENCE_ACTION_UPDATE_ABSENCE,
  ABSENCE_ACTION_UPDATE_ABSENCE_FOLLOWERS,
  ABSENCE_ACTION_DELETE_ABSENCE_FOLLOWER,
  ABSENCE_ACTION_ADD_ABSENCE_NOTE,
  ABSENCE_ACTION_UPDATE_ABSENCE_NOTE,
  ABSENCE_ACTION_DELETE_ABSENCE_NOTE,
  ABSENCE_ACTION_DELETE_ABSENCE,
  ABSENCE_ACTION_LIST_VACATION_SUMMARY,
  ABSENCE_ACTION_CHECK_ABSENCE_OVERLAP,
} from '@/app/modules/absence/store/actions/absence.actions.names'
import {
  ABSENCE_NOTE_TYPE,
  Note,
  EditAbsencePayload,
} from '@/app/modules/absence/models/absence.model'
import { MODULE_NAME } from '@/app/modules/absence/absence.module'
import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import i18n from '@/utils/vendors/i18n/index'
import { SnackbarProgrammatic as Snackbar } from '@/app/util-modules/ui/snackbar/BaseSnackbarPlugin'
import BaseDialog from '@/app/util-modules/ui/dialog/BaseDialog.vue'
import AbsenceEditFormModal from '../absence-edit-form/AbsenceEditFormModal.vue'
import AbsenceDeleteConfirm from '../absence-delete-confirm/AbsenceDeleteConfirm.vue'
import {
  USER_GETTER_IS_ASSUMED_ROLE_ADMIN,
  USER_GETTER_USER,
} from '@/app/core/user/store/getters/user.getters.names'
import { ABSENCE_KIND } from '@/app/modules/absence/constants'
import useVacationValidator from '../absence-add/composables/use-vacation-validator'
import useCalculateTotal from '@/app/modules/absence/components/absence-add/composables/use-calculate-total'
import isEqual from 'lodash/isEqual'
import { useActionTracker } from '@/app/util-modules/action-status/action-tracker'
import { isSameDay } from 'date-fns'

type Follower = {
  id: string
}

export default defineComponent({
  name: 'AbsenceEdit',
  components: {
    AbsenceEditFormModal,
    AbsenceDeleteConfirm,
    BaseDialog,
  },
  props: {
    absenceId: {
      type: String,
      required: true,
    },
    areNotesFocused: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isEditingNote = ref(false)
    const isDiscardChangeDialogOpen = ref(false)
    const isDiscardChangeActionCloseModal = ref(false)
    const isMainFormLoading = ref(false)
    const formInputNoteValidity = ref(false)

    const $actions = useActionTracker({
      getAbsence: ABSENCE_ACTION_GET_ABSENCE,
      updateAbsence: ABSENCE_ACTION_UPDATE_ABSENCE,
    })

    const overlapAbsences = computed(
      () => store.state[MODULE_NAME].overlapAbsences
    )

    const notes = computed(
      () => store.state[MODULE_NAME].records[ABSENCE_NOTE_TYPE]
    )

    const notesToDisplay = computed(() => {
      return notes.value.filter((note: Note) => !note.isDeleted)
    })

    const vacationSummary = computed(
      () => store.state[MODULE_NAME].vacationSummary
    )

    const isAdmin = computed(
      () => store.getters[USER_GETTER_IS_ASSUMED_ROLE_ADMIN]
    )

    const currentUserId = computed(() => store.getters[USER_GETTER_USER].id)

    const isConfirmedOrRejected = computed(() => {
      return typeof payload.value.confirmed === 'boolean' ? true : false
    })

    const canEditAbsence = computed(() => {
      if (isAdmin.value) {
        return !isConfirmedOrRejected.value
      }
      return !(
        isConfirmedOrRejected.value ||
        currentUserId.value !== payload.value.user.id
      )
    })

    const listVacationSummary = async (userId: string) => {
      await store.dispatch(ABSENCE_ACTION_LIST_VACATION_SUMMARY, userId)
      return (
        vacationSummary.value.attributes.remaining_vacation_days_this_year || 0
      )
    }

    const { areEmployeesVacationValid } =
      useVacationValidator(listVacationSummary)

    const customValidator = async (callback: (isValid: boolean) => void) => {
      if (payload.value.manualValue) {
        callback(true)
      } else if (payload.value.type.attributes.kind === ABSENCE_KIND.VACATION) {
        const result = await areEmployeesVacationValid({
          employees: payload.value.employees,
          totalDay: payload.value.total.days,
        })
        callback(result)
      } else {
        callback(true)
      }
    }

    const payload = ref<EditAbsencePayload>({
      id: props.absenceId,
      employees: [],
      type: {
        id: '',
        type: '',
        attributes: {
          kind: '',
          title: '',
        },
      },
      times: [],
      fullDay: false,
      startTime: getDefaultStartDateTime(),
      endTime: getDefaultEndDateTime(),
      total: {
        days: 0,
        hours: 0,
      },
      manualValue: false,
      manualHours: 0,
      manualDays: 0,
      note: '',
      attachments: [],
      followers: [],
      dateRange: defaultDateRange(),
      showSystemNote: true,
      user: {
        avatar: '',
        email: '',
        locale: '',
        salary: 0,
        username: '',
      },
      permissions: {
        destroy: false,
        update: false,
      },
      confirmed: null,
    })
    /* eslint-disable-next-line no-undef */
    const getAbsenceDetails = async (absenceId: RecordId) => {
      return store.dispatch(ABSENCE_ACTION_GET_ABSENCE, {
        absenceId,
      })
    }
    const getAbsenceNotes = async (
      /* eslint-disable-next-line no-undef */
      absenceId: RecordId,
      showSystemNote: boolean
    ) => {
      store.dispatch(ABSENCE_NOTE_ACTION_LIST_NOTE, {
        absenceId,
        showSystemNote,
      })
    }

    const getInitialAbsenceData = computed(() =>
      store.state[MODULE_NAME].records.absence.find(
        (item: any) => item.id === props.absenceId
      )
    )

    const setInitialAbsenceData = async () => {
      const {
        user,
        absence_type,
        starts_at,
        ends_at,
        hours,
        days,
        use_custom_values,
        full_day,
        permissions,
        confirmed,
        followers,
      } = getInitialAbsenceData.value

      const employees = [
        {
          id: user.id,
          type: user._type,
          username: user.username,
          hourHolidays: false,
        },
      ]
      const absenceType = absence_type
        ? {
            attributes: {
              kind: absence_type.kind,
              title: capitalize(absence_type.title),
            },
            id: absence_type.id,
            type: absence_type._type,
          }
        : payload.value.type

      const userAbsencePermission = permissions || {
        destroy: false,
        update: false,
      }

      payload.value = {
        ...payload.value,
        id: props.absenceId,
        user: user,
        employees: employees,
        type: absenceType,
        times: [],
        fullDay: full_day,
        startTime: new Date(starts_at),
        endTime: new Date(ends_at),
        total: {
          days,
          hours,
        },
        manualValue: use_custom_values,
        manualHours: +hours,
        manualDays: +days,
        note: '',
        attachments: [],
        followers,
        dateRange: [new Date(starts_at), new Date(ends_at)],
        showSystemNote: true,
        permissions: userAbsencePermission,
        confirmed,
      }

      await listVacationSummary(user.id)
      payload.value.employees[0].hourHolidays =
        vacationSummary.value.attributes.hour_holidays
    }
    /* eslint-disable-next-line no-undef */
    const setupEditAbsence = async (absenceId: RecordId) => {
      isMainFormLoading.value = true
      await getAbsenceDetails(absenceId)
      await getAbsenceNotes(absenceId, true)

      setInitialAbsenceData()
    }

    const filteredUpdatedUserNote = computed(() =>
      notes.value.filter(
        (n: Note) => n.type === NOTE_TYPES.USER && (n.isEdited || n.isDeleted)
      )
    )

    const generatePayload = () => {
      const {
        employees,
        dateRange,
        startTime,
        endTime,
        fullDay,
        manualValue,
        manualHours,
        manualDays,
        type,
        note,
        attachments,
        followers,
      } = payload.value

      let finalStartDateTime = null
      let finalEndDateTime = null

      const [dateRangeStart, dateRangeEnd] = dateRange

      const user_ids = employees.map((employee: Follower) => employee.id)

      const followers_ids = followers.map((follower: Follower) => follower.id)

      if (!fullDay && isSameDay(dateRangeStart, dateRangeEnd)) {
        finalStartDateTime = combineDateTime(
          dateRangeStart,
          startTime
        ).toISOString()
        finalEndDateTime = combineDateTime(dateRangeEnd, endTime).toISOString()
      } else {
        finalStartDateTime = formatDateFilter(dateRangeStart)
        finalEndDateTime = formatDateFilter(dateRangeEnd)
      }

      return {
        finalEndDateTime,
        finalStartDateTime,
        fullDay,
        manualHours,
        manualDays,
        type,
        manualValue,
        note,
        attachments,
        filteredUpdatedUserNote,
        followers_ids,
        currentUserId,
        user_ids,
      }
    }
    const submitEditForm = async () => {
      const {
        finalEndDateTime,
        finalStartDateTime,
        fullDay,
        manualHours,
        manualDays,
        type,
        manualValue,
        note,
        attachments,
        filteredUpdatedUserNote,
        followers_ids,
        currentUserId,
        user_ids,
      }: any = generatePayload()

      await store.dispatch(ABSENCE_ACTION_UPDATE_ABSENCE, {
        absenceId: payload.value.id,
        absenceParams: {
          user_ids,
          ends_at: `${finalEndDateTime}`,
          starts_at: `${finalStartDateTime}`,
          full_day: fullDay,
          hours: +manualHours,
          days: +manualDays,
          absence_type_id: type.id,
          title: type.attributes.title,
          use_custom_values: manualValue,
          noteContent: note,
          attachments: attachments,
          notes: filteredUpdatedUserNote.value,
          followers: [...followers_ids],
          currentUserId: currentUserId.value,
        },
      })
    }

    const findDeletedFollowers = (
      previousFollowers: Follower[],
      newFollowers: Follower[]
    ) => {
      const newFollowerIds: Array<string> = newFollowers.map(
        (follower: Follower) => follower.id
      )

      const deletedFollowers = previousFollowers.filter(
        (follower: Follower) => !newFollowerIds.includes(follower.id)
      )
      return deletedFollowers
    }

    const updateFollowers = async () => {
      const { followers } = payload.value

      const isFollowersUpdated = isEqual(
        payload.value.followers,
        getInitialAbsenceData.value.followers
      )

      const followers_ids = followers.map((follower: Follower) => follower.id)
      if (followers_ids.length && !isFollowersUpdated) {
        await store.dispatch(ABSENCE_ACTION_UPDATE_ABSENCE_FOLLOWERS, {
          ids: followers_ids,
          absenceId: props.absenceId,
        })
      }

      const followersToDelete = findDeletedFollowers(
        getInitialAbsenceData.value.followers,
        followers
      )

      if (followersToDelete.length) {
        await Promise.all(
          followersToDelete.map((follower: Follower) =>
            store.dispatch(ABSENCE_ACTION_DELETE_ABSENCE_FOLLOWER, {
              follower_id: follower.id,
              absenceId: props.absenceId,
            })
          )
        )
      }
    }

    const updateAbsenceNote = async (note: Note) => {
      if (note.isDeleted) {
        await store.dispatch(ABSENCE_ACTION_DELETE_ABSENCE_NOTE, {
          absenceId: props.absenceId,
          noteId: note.id,
        })
      } else {
        await store.dispatch(ABSENCE_ACTION_UPDATE_ABSENCE_NOTE, {
          absenceId: props.absenceId,
          currentUserId: currentUserId.value,
          note,
        })
      }
    }
    const createAbsenceNote = async (note: Note) => {
      await store.dispatch(ABSENCE_ACTION_ADD_ABSENCE_NOTE, {
        absenceId: props.absenceId,
        currentUserId: currentUserId.value,
        note,
      })
    }

    const handleNoteValidation = (validity: boolean) => {
      formInputNoteValidity.value = validity
    }

    const isNoteAttachmentValid = computed(() => {
      const { attachments, note } = payload.value
      if (
        (note.length && !formInputNoteValidity.value) ||
        (attachments.length && !note)
      ) {
        return false
      }
      return true
    })

    const handleEdit = async () => {
      if (!isNoteAttachmentValid.value) {
        return isNoteAttachmentValid.value
      }

      if (isEditingNote.value) {
        isDiscardChangeDialogOpen.value = true
        return
      }

      if (getInitialAbsenceData.value.confirmed === null) {
        const { finalStartDateTime, finalEndDateTime, user_ids, fullDay } =
          generatePayload()
        await store.dispatch(ABSENCE_ACTION_CHECK_ABSENCE_OVERLAP, {
          starts_at: finalStartDateTime,
          ends_at: finalEndDateTime,
          user_ids: user_ids,
          absence_id: props.absenceId,
          full_day: fullDay,
        })

        if (overlapAbsences.value.length) {
          return false
        }
        await submitEditForm()
      }

      if (getInitialAbsenceData.value.confirmed !== null) {
        await updateFollowers()
      }

      if (
        getInitialAbsenceData.value.confirmed !== null &&
        payload.value.note
      ) {
        const noteParams = {
          content: payload.value.note,
          attachments: payload.value.attachments,
        }
        await createAbsenceNote(noteParams)
      }

      Snackbar.open(i18n.t('absence.edit_modal.edit_success_message'))
      emit('success')
      emit('close')
    }

    const handleDiscardChanges = () => {
      isEditingNote.value = false
      if (isDiscardChangeActionCloseModal.value) {
        emit('close')
        return
      }
      handleEdit()
    }

    const handleFormNoteIsEditing = (value: boolean) => {
      isEditingNote.value = value
    }

    const handleFormNoteAction = async () => {
      isMainFormLoading.value = false
      if (filteredUpdatedUserNote.value.length) {
        await updateAbsenceNote(filteredUpdatedUserNote.value[0])
      }

      await getAbsenceNotes(props.absenceId, payload.value.showSystemNote)
      await getAbsenceDetails(props.absenceId)
    }

    setupEditAbsence(props.absenceId)

    watch(
      () => payload.value.showSystemNote,
      () => {
        getAbsenceNotes(props.absenceId, payload.value.showSystemNote)
      }
    )

    const isDeleteAbsenceConfirmOpen = ref(false)

    const handleSelection = async (option: { id: string }) => {
      if (option.id === 'delete') {
        isDeleteAbsenceConfirmOpen.value = true
      }
    }

    const handleDelete = async () => {
      await store.dispatch(ABSENCE_ACTION_DELETE_ABSENCE, props.absenceId)

      Snackbar.open(
        i18n.tc('absence.edit_modal.delete_confirm_dialog.success_message')
      )

      emit('close')
    }

    const handleCloseModal = () => {
      if (isEditingNote.value) {
        isDiscardChangeActionCloseModal.value = true
        isDiscardChangeDialogOpen.value = true
        return
      }
      emit('close')
    }

    useCalculateTotal(payload)

    return {
      handleEdit,
      handleFormNoteAction,
      handleFormNoteIsEditing,
      handleSelection,
      handleDelete,
      handleDiscardChanges,
      handleCloseModal,
      customValidator,
      submitEditForm,
      findDeletedFollowers,
      updateFollowers,
      updateAbsenceNote,
      createAbsenceNote,
      handleNoteValidation,

      isEditingNote,
      filteredUpdatedUserNote,
      isNoteAttachmentValid,
      notes,
      isDeleteAbsenceConfirmOpen,
      isDiscardChangeDialogOpen,
      payload,
      notesToDisplay,
      canEditAbsence,
      $actions,
      isMainFormLoading,
      formInputNoteValidity,
    }
  },
})
