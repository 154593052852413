import { AbsenceOptionTypeEmployee } from '@/app/modules/absence/models/absence.model'

const calculateMinEmployeeVacationDays = (
  employeeWithVacationDaysArray: AbsenceOptionTypeEmployee[]
) =>
  employeeWithVacationDaysArray &&
  employeeWithVacationDaysArray.reduce(
    (acc: AbsenceOptionTypeEmployee, val: AbsenceOptionTypeEmployee) => {
      if (
        val &&
        typeof val.vacationDays === 'number' &&
        acc &&
        typeof acc.vacationDays === 'number'
      ) {
        return val.vacationDays < acc.vacationDays ? val : acc
      } else {
        return acc
      }
    },
    employeeWithVacationDaysArray[0]
  )

export default function useVacationValidator(
  listVacationSummary: (employeeId: string) => Promise<number>
) {
  const areEmployeesVacationValid = async ({
    employees,
    totalDay,
  }: {
    employees: AbsenceOptionTypeEmployee[]
    totalDay: number
  }) => {
    const employeeWithVacationDays = [...employees]

    await Promise.all(
      employeeWithVacationDays.map((employee) =>
        listVacationSummary(employee.id).then((summaryVacationDays: number) => {
          employee.vacationDays = summaryVacationDays
        })
      )
    )

    const minEmployeeWithVacationDays = calculateMinEmployeeVacationDays(
      employeeWithVacationDays
    )

    return (
      minEmployeeWithVacationDays &&
      typeof minEmployeeWithVacationDays.vacationDays === 'number' &&
      minEmployeeWithVacationDays.vacationDays >= totalDay
    )
  }

  return {
    areEmployeesVacationValid,
  }
}
