<template>
  <div class="flex flex-col">
    <div class="label">
      <span class="text-coal-550">{{ label }}</span>
    </div>
    <div class="flex items-center mt-2.5">
      <span class="font-semibold line-clamp-2">{{ value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbsenceFormFieldItemDisplay',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
}
</script>
