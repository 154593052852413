<template>
  <div class="grid grid-cols-2 gap-6 mb-10">
    <div>
      <absence-form-field-item-display
        :value="user.attributes.username"
        :label="$t('absence.add_form.field_label_employee')"
      />
    </div>
    <div>
      <absence-form-input-type
        v-model="passedValue"
        data-id="absence.add_form.absence_type.employee"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { USER_GETTER_USER } from '@/app/core/user/store/getters/user.getters.names'

import AbsenceFormInputType from './absence-form-input-type/AbsenceFormInputType.vue'
import AbsenceFormFieldItemDisplay from '../absence-form-field-item-display/AbsenceFormFieldItemDisplay.vue'

export default {
  name: 'AbsenceFormTopSectionEmployee',
  components: {
    AbsenceFormInputType,
    AbsenceFormFieldItemDisplay,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: USER_GETTER_USER,
    }),
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
  },
  mounted() {
    this.passedValue.employees = [
      {
        id: this.user.id,
        username: this.user.attributes.username,
        hourHolidays: this.user.attributes.hour_holidays,
        type: 'user',
      },
    ]
  },
}
</script>
