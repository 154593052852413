<template>
  <div class="grid grid-cols-3 gap-6 mb-10">
    <div class="flex flex-col">
      <div class="label">
        <span class="text-coal-550">
          {{ $t('absence.add_form.field_label_employees') }}
        </span>
      </div>
      <div v-if="value.employees.length" class="flex mr-1.5">
        <follower-avatar
          v-for="employee in value.employees"
          :key="employee.id"
          :follower="employee"
          :data-id-prefix="`absence_form_top_section_admin_template.${employee.id}.employees`"
          :disabled="true"
        />
      </div>
    </div>
    <div class="flex-1"></div>
    <div class="flex-1">
      <absence-form-field-item-display
        :value="absenceType"
        :label="$t('absence.add_form.field_label_absence_type')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { USER_GETTER_USER } from '@/app/core/user/store/getters/user.getters.names'

import FollowerAvatar from '@/app/util-modules/ui/followers/FollowerAvatar.vue'
import AbsenceFormFieldItemDisplay from '../absence-form-field-item-display/AbsenceFormFieldItemDisplay.vue'

export default {
  name: 'AbsenceFormTopSectionAdminTemplate',
  components: {
    AbsenceFormFieldItemDisplay,
    FollowerAvatar,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      user: USER_GETTER_USER,
    }),
    absenceType() {
      return this.value.type?.attributes?.title || ''
    },
  },
}
</script>

<style scoped>
.label {
  @apply mb-3;
}
</style>
