<template>
  <div
    class="absence-date-range-picker rounded border shadow-md border-coal-100"
  >
    <base-date-range-picker-menu
      v-model="dateRange"
      :shortcuts="shortcuts"
      :shortcut-selected="shortcutSelectedName"
      :is-selecting-custom-range="isSelectingCustomRange"
      :custom-focused-date="customFocusedDate"
      :has-selected-custom-range="hasSelectedCustomRange"
      :text-selecting-date-range="textSelectingDateRange"
      :hide-action-apply="true"
      data-id-prefix="absence.add_form"
      @apply-shortcut="applyShortcut($event)"
      @apply="apply()"
      @range-start="rangeStart($event)"
      @range-end="rangeEnd($event)"
      @reset="reset()"
    >
      <template v-slot:footer-left>
        <div class="mx-4 mt-8 w-72">
          <div class="mt-0.5 mr-4">
            <div class="flex items-end">
              <base-checkbox
                v-model="passedValue.fullDay"
                data-id="absence.add_form.full_day"
                size="is-small"
                :disabled="enableSpecifyHours"
              >
                <div>
                  {{ $t('absence.add_form.field_label_absence_specify_hour') }}
                </div>
              </base-checkbox>
            </div>
            <div
              :class="{
                'opacity-50': enableSpecifyHours,
              }"
              class="mt-3"
            >
              <div
                v-if="!passedValue.fullDay && !enableSpecifyHours"
                class="mt-4 mb-7"
              >
                <base-field
                  v-slot="{ validate }"
                  name="absence.add_form.full_day"
                  :validation-rules="specifyHoursValidationRule"
                >
                  <div class="flex items-center">
                    <absence-form-input-hour
                      v-model="passedValue.startTime"
                      data-id="absence.add_form.input_hour_start_time"
                      :label="$t('absence.add_form.field_label_start_time')"
                      :disabled="enableSpecifyHours"
                      @input="validate($event)"
                    />
                    <div class="border border-coal-550 w-3 mx-1.5 mt-5" />
                    <absence-form-input-hour
                      v-model="passedValue.endTime"
                      data-id="absence.add_form.input_hour_end_time"
                      :label="$t('absence.add_form.field_label_end_time')"
                      :disabled="enableSpecifyHours"
                      @input="validate($event)"
                    />
                  </div>
                </base-field>
              </div>
            </div>
          </div>
        </div>
      </template>
    </base-date-range-picker-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseField from '@/app/util-modules/ui/field/BaseField'
import BaseDateRangePickerMenu from '@/app/util-modules/ui/date-range-picker-menu/BaseDateRangePickerMenu.vue'
import BaseCheckbox from '@/app/util-modules/ui/checkbox/BaseCheckbox'
import AbsenceFormInputHour from '../absence-form/AbsenceFormInputHour'
import {
  getDateRangeToday,
  getDateRangeTomorrow,
  getDateRangeUntilFriday,
  getDateRangeNextWeek,
} from '@/utils/date-time.utils'

import { MODULE_NAME as ABSENCE_MODULE_NAME } from '@/app/modules/absence/absence.module'
import shortcutsMixin from '@/app/util-modules/ui/date-range-picker/shortcuts.mixin'
import i18n from '@/utils/vendors/i18n/index'
import { extend } from 'vee-validate'
import { isSameDay, isBefore } from 'date-fns'

extend('specify_hours_validation_rule', {
  params: ['areFieldsValid'],
  validate: (_, { areFieldsValid }) =>
    areFieldsValid ||
    i18n.t('absence.add_form.field_label_specify_hours_error_message'),
})
export default {
  name: 'AbsenceDateRangePicker',
  components: {
    BaseField,
    BaseDateRangePickerMenu,
    BaseCheckbox,
    AbsenceFormInputHour,
  },
  mixins: [shortcutsMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
    calculateAbsenceActionStatus: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      shortcutSelected: '',
      customFocusedDate: new Date(),
      appliedDateRange: null,
      dateRangeStart: new Date(),
      isSelectingCustomRange: false,
      hasSelectedCustomRange: true,
      textSelectingDateRange: '',
    }
  },
  computed: {
    ...mapState(ABSENCE_MODULE_NAME, {
      calculation: (state) => state.calculation,
    }),
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    dateRange: {
      get() {
        return this.value.dateRange
      },
      set(value) {
        this.appliedDateRange = value
      },
    },
    isEmployeesMultiple() {
      return this.passedValue.employees.length > 1
    },
    specifyHoursValidationRule() {
      const [startDate, endDate] = this.passedValue.dateRange
      const { startTime, endTime } = this.passedValue
      return {
        specify_hours_validation_rule: {
          areFieldsValid: isSameDay(startDate, endDate)
            ? isBefore(startTime, endTime)
            : true,
        },
      }
    },
    enableSpecifyHours() {
      const [startDate, endDate] = this.passedValue.dateRange
      return !isSameDay(startDate, endDate)
    },
  },
  watch: {
    appliedDateRange: {
      handler(range) {
        this.passedValue.fullDay = true
        if (range) {
          this.customFocusedDate = range[0]
          const [startDate, endDate] = range
          this.passedValue.dateRange = [startDate, endDate]
        }
      },
      immediate: true,
    },
    hasSelectedCustomRange: {
      handler() {
        this.shortcutSelected = this.findMatchingShortcut(
          this.shortcuts,
          this.dateRange
        )

        if (this.appliedDateRange && this.appliedDateRange.length === 2) {
          this.shortcuts = this.generateShortcutsWithLabels(
            this.getShortcuts(
              this.customCurrentDate,
              this.appliedDateRange[0],
              this.appliedDateRange[1]
            )
          )
        }
      },
      immediate: true,
    },
    dateRange: {
      handler(value) {
        if (value && value.length) {
          this.customFocusedDate = value[0]
          this.shortcutSelected = this.findMatchingShortcut(
            this.shortcuts,
            value
          )
          const [startDate, endDate] = value
          this.shortcuts = this.generateShortcutsWithLabels(
            this.getShortcuts(this.customCurrentDate, startDate, endDate)
          )
        }
      },
      immediate: true,
    },
  },
  methods: {
    rangeStart(date) {
      this.dateRange = null
      this.customFocusedDate = date
      this.dateRangeStart = date
      this.isSelectingCustomRange = true
      this.hasSelectedCustomRange = false
      this.passedValue.dateRange = [date]
      this.shortcuts = this.generateShortcutsWithLabels(
        this.getShortcuts(this.customCurrentDate, date, null)
      )
    },
    rangeEnd(date) {
      this.customFocusedDate = date
      this.dateRangeStart = null
      this.isSelectingCustomRange = false
      this.hasSelectedCustomRange = true
      this.shortcuts = this.generateShortcutsWithLabels(
        this.getShortcuts(
          this.customCurrentDate,
          this.passedValue.dateRange[0],
          date
        )
      )
    },
    applyShortcut(shortcut) {
      this.appliedDateRange = shortcut.range
      this.dateRange = this.appliedDateRange
      this.shortcutSelected = shortcut.id
      this.hasSelectedCustomRange = false
      this.shortcuts = this.generateShortcutsWithLabels(
        this.getShortcuts(this.customCurrentDate)
      )
    },
    apply() {
      this.appliedDateRange = this.dateRange
      this.shortcutSelected = this.findMatchingShortcut(
        this.shortcuts,
        this.dateRange
      )
      this.hasSelectedCustomRange = false
    },
    reset() {
      this.isSelectingCustomRange = false
      this.dateRangeStart = null
    },
    getShortcuts(date, customStartDate = null, customEndDate = null) {
      let customDateRange
      const isCustomSelected = this.shortcutSelected === 'custom'

      if (isCustomSelected && customStartDate && customEndDate) {
        customDateRange = [customStartDate, customEndDate]
      } else if (customStartDate) {
        customDateRange = [customStartDate, customStartDate]
      } else {
        customDateRange = ''
      }
      if (!isCustomSelected && customStartDate && customEndDate) {
        customDateRange = ''
      }

      return [
        {
          id: 'today',
          name: () => this.$t('daterangepicker.shortcuts.today'),
          range: getDateRangeToday(date),
        },
        {
          id: 'tomorrow',
          name: () => this.$t('daterangepicker.shortcuts.tomorrow'),
          range: getDateRangeTomorrow(date),
        },
        {
          id: 'until_friday',
          name: () => this.$t('daterangepicker.shortcuts.until_friday'),
          range: getDateRangeUntilFriday(date),
        },
        {
          id: 'next_week',
          name: () => this.$t('daterangepicker.shortcuts.next_week'),
          range: getDateRangeNextWeek(date),
        },
        {
          id: 'custom',
          name: () => this.$t('daterangepicker.shortcuts.custom'),
          range: customDateRange,
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu {
  @apply w-full;
}

::v-deep .datepicker-col {
  @apply w-full;
}
::v-deep .datepicker-col .dropdown-content {
  @apply px-0 border-none shadow-none;
}

::v-deep .header-col {
  @apply px-3;
}

::v-deep .shortcuts-col {
  .custom {
    .shortcut-item {
      @apply cursor-auto;
    }
  }
}

::v-deep .absence-date-range-picker:not(.is-mobile) {
  .shortcuts-col {
    max-width: 300px;
  }
}

::v-deep .active-shortcut {
  @apply bg-green-100 border-t border-b border-green-300;
}

::v-deep .datepicker-body .datepicker-cell {
  &.is-selectable:not(.is-within-selected):not(.is-first-selected):not(.is-last-selected):not(.is-within-hovered-range) {
    &:hover,
    &.is-first-hovered {
      @apply bg-coal-80 text-coal #{!important};
    }
  }

  &.is-last-hovered,
  &.is-first-hovered {
    @apply text-white-400  #{!important};
  }

  &.is-last-selected,
  &.is-last-hovered {
    &::after {
      @apply hidden #{!important};
    }
  }

  &.is-first-selected,
  &.is-first-hovered.is-within-hovered-range {
    &:first-child::before {
      @apply hidden #{!important};
    }
  }

  &.is-first-selected,
  &.is-last-selected {
    @apply text-white-400 bg-green-300  #{!important};
  }

  &.is-first-selected:not(.is-last-selected),
  &.is-first-hovered.is-within-hovered-range:not(.is-last-hovered) {
    @apply rounded-tl-md rounded-bl-md rounded-tr-none rounded-br-none #{!important};
  }

  &.is-last-selected:not(.is-first-selected),
  &.is-last-hovered:not(.is-first-hovered) {
    @apply rounded-tl-none rounded-bl-none rounded-tr-md rounded-br-md #{!important};
  }

  &.is-within-selected:not(.is-first-selected):not(.is-last-selected),
  &.is-first-selected:not(.is-last-selected),
  &.is-last-selected:not(.is-first-selected) {
    &::after,
    &:first-child::before {
      @apply bg-green-100;
    }
  }

  &.is-within-hovered:not(.is-first-hovered):not(.is-last-hovered),
  &.is-first-hovered.is-within-hovered-range:not(.is-last-hovered),
  &.is-last-hovered:not(.is-first-hovered) {
    &::after,
    &:first-child::before {
      @apply bg-coal-80;
    }
  }

  &.is-within-selected:not(.is-first-selected):not(.is-last-selected),
  &.is-first-selected:not(.is-last-selected),
  &.is-last-selected:not(.is-first-selected),
  &.is-within-hovered:not(.is-first-hovered):not(.is-last-hovered),
  &.is-first-hovered.is-within-hovered-range:not(.is-last-hovered),
  &.is-last-hovered:not(.is-first-hovered) {
    @apply relative;

    &::after,
    &:first-child::before {
      content: '';
      @apply block absolute top-0 bottom-0 w-2;
    }

    &::after {
      @apply left-full;
    }

    &:first-child::before {
      @apply right-full rounded-tl-md rounded-bl-md;
    }

    &:last-child::after {
      @apply rounded-tr-md rounded-br-md;
    }
  }

  &.is-within-selected:not(.is-first-selected):not(.is-last-selected) {
    @apply bg-green-100 text-coal  #{!important};
  }

  &.is-within-hovered:not(.is-first-hovered):not(.is-last-hovered) {
    @apply bg-coal-80  #{!important};
  }
}
</style>
