import { isSameDay, isSameHour, isSameMinute } from 'date-fns'
import { formatDateFilter } from '@/utils/date-time.utils'
import { DATE_FORMAT } from '@/constants'

export const getFormattedDate = (date: string | Date) =>
  formatDateFilter(new Date(date), DATE_FORMAT)

export const isTimeValid = (startTime: Date, endTime: Date): boolean => {
  const startTimeObject = new Date(startTime)
  const endTimeObject = new Date(endTime)
  return !(
    isSameHour(startTimeObject, endTimeObject) &&
    isSameMinute(startTimeObject, endTimeObject)
  )
}

export const isAbsenceSpecifyHoursAndSameDay = (
  fullDay: boolean,
  dateRangeStart: Date,
  dateRangeEnd: Date
) => !fullDay && isSameDay(dateRangeStart, dateRangeEnd)
