<template>
  <div ref="note-container" class="h-56 relative">
    <base-loading v-if="$actions.fetchNotes.isLoading" :active="true" />

    <base-virtual-list
      ref="noteList"
      :data-sources="notes"
      :data-component="noteCardComponent"
      :item-scoped-slots="{ t: 12 }"
      :estimate-size="86"
      :extra-props="{
        dataIdPrefix: 'absence_edit_form_notes_list',
        value: passedValue,
      }"
      :keeps="notesPerPage"
      class="relative h-full overflow-y-auto hidden-scrollbar"
      item-class="mb-2.5 last:mb-20"
      @is-editing-note="$emit('is-editing-note', $event)"
      @delete-note="$emit('delete-note')"
      @save-note="$emit('save-note')"
      @card-click="currentNote = $event"
      @tobottom="fetchAppendNotes()"
      @edit-absence-more-attachments="
        $emit('edit-absence-more-attachments', $event)
      "
    />
  </div>
</template>

<script>
import BaseVirtualList from '@/app/util-modules/ui/virtual-list/BaseVirtualList'
import AbsenceEditFormNotesItem from './AbsenceEditFormNotesItem'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import BaseLoading from '@/app/util-modules/ui/loading/BaseLoading'
import {
  ABSENCE_NOTE_ACTION_LIST_APPEND_NOTE,
  ABSENCE_NOTE_ACTION_LIST_NOTE,
} from '@/app/modules/absence/store/actions/absence.actions.names'
import store from '@/store'

export default defineComponent({
  name: 'AbsenceEditFormNotesList',
  components: {
    BaseVirtualList,
    BaseLoading,
  },
  trackedActions: {
    fetchNotes: ABSENCE_NOTE_ACTION_LIST_NOTE,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    notes: {
      type: Array,
      default: () => [],
    },
    areNotesFocused: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { refs, emit }) {
    const displayNote = (note) => (note && note.isDeleted ? false : true)
    const notesPerPage = 30
    const currentNote = ref(null)

    const fetchAppendNotes = () => {
      const { id, user, showSystemNote } = props.value
      store.dispatch(ABSENCE_NOTE_ACTION_LIST_APPEND_NOTE, {
        absenceId: id,
        userId: user.id,
        showSystemNote,
      })
    }

    const scrollToNotesContainer = () => {
      emit('scroll-to-notes', refs['note-container'].offsetTop)
    }

    onMounted(() => {
      if (props.areNotesFocused) {
        scrollToNotesContainer()
      }
    })

    return {
      notesPerPage,
      currentNote,

      displayNote,
      fetchAppendNotes,

      noteCardComponent: AbsenceEditFormNotesItem,
    }
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
})
</script>
