<template>
  <div class="grid grid-cols-2 gap-6 mb-10">
    <div>
      <absence-form-input-employee
        v-model="passedValue"
        data-id="absence.admin.add_form.employee_input"
      />
    </div>
    <div>
      <absence-form-input-type
        v-model="passedValue"
        :show-tooltip="true"
        :disabled="!passedValue.employees.length"
        data-id="absence.add_form.absence_type.admin"
      >
      </absence-form-input-type>
    </div>
  </div>
</template>

<script>
import AbsenceFormInputType from './absence-form-input-type/AbsenceFormInputType.vue'
import AbsenceFormInputEmployee from './absence-form-input-employees/AbsenceFormInputEmployee.vue'
import i18n from '@/utils/vendors/i18n/index'
import { extend } from 'vee-validate'

extend('employee_validation_rule', {
  validate: (_, { isFieldValid }) =>
    isFieldValid || i18n.t('absence.add_form.field_label_employee_error'),
  params: ['isFieldValid'],
})

export default {
  name: 'AbsenceFormTopSectionAdmin',
  components: {
    AbsenceFormInputType,
    AbsenceFormInputEmployee,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    validationStates: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedEmployee: '',
      employeeSearchFilter: {},
    }
  },
  computed: {
    passedValue: {
      get() {
        return this.value
      },
      set(passedValue) {
        this.$emit('input', passedValue)
      },
    },
    selectedAbsenceType() {
      return this.passedValue.type
    },
    selectedEmployees() {
      return this.passedValue.employees
    },
  },
  watch: {
    selectedEmployees: {
      handler(value) {
        if (value) {
          this.passedValue.type = {
            id: '',
            type: '',
            attributes: {
              kind: '',
              title: '',
            },
          }
        }
      },
      deep: true,
    },
    selectedAbsenceType: {
      handler(value) {
        if (value.id) {
          this.employeeSearchFilter = {
            absence_types_id_in: [`${value.id}`],
          }
        }
      },
    },
  },
  mounted() {
    this.selectedEmployee = this.passedValue.employees[0]
  },
  methods: {
    removeEmployee(employeeToDelete) {
      const employees = this.passedValue.employees.filter(
        (emp) => emp.id !== employeeToDelete.id
      )
      this.passedValue.employees = employees
    },
  },
}
</script>
