import state, { makeEmptyState } from './absence.state'
import getters from './getters/absence.getters'
import absenceActions from './actions/absence.actions'
import absenceNoteActions from './actions/absence-note.actions'
import absenceMetaActions from './actions/absence-meta.actions'
import absenceAttachmentActions from './actions/absence-attachment.actions'
import mutations from './mutations/absence.mutations'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: {
    ...absenceActions,
    ...absenceNoteActions,
    ...absenceMetaActions,
    ...absenceAttachmentActions,
  },
}

export { makeEmptyState }
