import BaseRequest from '@/utils/api/base-request'
import { ABSENCE_ENDPOINT_LIST_ATTACHMENTS } from '../network/absence.endpoints'

export const NOTE_ATTACHMENT_TYPE = 'attachment'

export interface AttachmentListRequestParams {
  workspaceId: RecordId
  absenceId: RecordId
}
export class AbsenceAttachmentListRequest extends BaseRequest {
  constructor({ workspaceId, absenceId }: AttachmentListRequestParams) {
    super()
    super.url = ABSENCE_ENDPOINT_LIST_ATTACHMENTS(workspaceId, absenceId)
    super.type = NOTE_ATTACHMENT_TYPE
  }
}
