<template>
  <div class="flex justify-end">
    <base-button
      v-if="showButtonEdit"
      class="ml-2"
      :loading="loading"
      :disabled="loading"
      type="is-filled"
      native-type="button"
      @click="$emit('edit')"
    >
      {{ $t('absence.overlap_absence.edit_button') }}
    </base-button>
    <base-button
      v-if="!hideButtonBack"
      class="mr-0.5"
      :disabled="loading"
      type="is-filled-grey"
      native-type="button"
      @click="$emit('back')"
    >
      {{ $t('absence.add_modal.button_back') }}
    </base-button>

    <div v-if="!showButtonEdit" class="flex items-center justify-center">
      <base-more-options
        v-if="moreOptions.length"
        :data-id-prefix="dataIdPrefix"
        position="is-top-left"
        :options="moreOptions"
        @selected="$emit('selected', $event)"
      />

      <base-button
        class="ml-2"
        :loading="loading"
        :disabled="loading"
        type="is-filled"
        native-type="button"
        @click="$emit('done')"
      >
        {{ buttonSubmitText }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/app/util-modules/ui/button/BaseButton'
import BaseMoreOptions from '@/app/util-modules/ui/more-options/BaseMoreOptions'

export default {
  name: 'AbsenceFormFooterContent',
  components: {
    BaseButton,
    BaseMoreOptions,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    buttonSubmitText: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideButtonBack: {
      type: Boolean,
      default: false,
    },
    moreOptions: {
      type: Array,
      default: () => [],
    },
    dataIdPrefix: {
      type: String,
      required: true,
    },
    showButtonEdit: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
